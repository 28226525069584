import { Subject } from 'rxjs';
import { ICustomPrompt, IErrorPrompt, ISuccessPrompt } from './custom-prompt.interface';

export class ErrorPrompt {

  private static errorSubject = new Subject<IErrorPrompt>();
  private static afterModalClosedCallback: (() => void) | null = null;
  static error$ = ErrorPrompt.errorSubject.asObservable();

  static fire(errorData: IErrorPrompt, afterClosedCallback?: () => void) {
    this.errorSubject.next(errorData);
    this.afterModalClosedCallback = afterClosedCallback || null;
  }

  static notifyModalClosed() {
    if (this.afterModalClosedCallback) {
      this.afterModalClosedCallback();
      this.afterModalClosedCallback = null; // Reset the callback after executing
    }
  }

}

export class SuccessPrompt {

  private static successSubject = new Subject<ISuccessPrompt>();
  private static afterModalClosedCallback: (() => void) | null = null;
  static success$ = SuccessPrompt.successSubject.asObservable();

  static fire(successData: ISuccessPrompt, afterClosedCallback?: () => void) {
    this.successSubject.next(successData);
    this.afterModalClosedCallback = afterClosedCallback || null;
  }

  static notifyModalClosed() {
    if (this.afterModalClosedCallback) {
      this.afterModalClosedCallback();
      this.afterModalClosedCallback = null; // Reset the callback after executing
    }
  }

}

export class CustomPrompt {
  private static customSubject = new Subject<ICustomPrompt>();
  private static afterModalClosedCallback: ((data: any) => void) | null = null;
  static custom$ = CustomPrompt.customSubject.asObservable();

  static fire(customData: ICustomPrompt, afterClosedCallback?: (data: any) => void) {
    this.customSubject.next(customData);
    this.afterModalClosedCallback = afterClosedCallback || null;
  }

  static notifyModalClosed(data: any) {
    if (this.afterModalClosedCallback) {
      this.afterModalClosedCallback(data);
      this.afterModalClosedCallback = null;
    }
  }
}

