import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AuthServiceJWT } from '../../shared/services/auth.service';
import { environment } from 'src/environments/environment';
const AllowedOrgNameRegEx = new RegExp(/[^a-zA-Z0-9_\- ]/gm);
import { SegmentService } from "ngx-segment-analytics";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  orgName: string = '';
  environment=environment;

  constructor(public afAuth: AuthService, public authService: AuthServiceJWT, private route: ActivatedRoute, private router: Router,
    private segment: SegmentService) {
    this.route.queryParams.subscribe(
      params => {
        console.log('query params', params);
        this.orgName = params['orgname'];
        this.segment.track("signup-init", params).catch((err)=>{});
        if (this.orgName) {
          this.orgName = this.orgName.replace(AllowedOrgNameRegEx, "");
        }
      }
    )
    if (!this.orgName) {
      this.router.navigateByUrl('/login');
    } else {
      this.afAuth.isAuthenticated$.subscribe(isAuthenticated => {
        if (!isAuthenticated) {
          this.authService.loginWithRedirect('join', this.orgName);
        } else {
          this.router.navigateByUrl('/login');
        }
      });
    }
  }

  ngOnInit(): void {
  }

}
