import { orgType, CUSTODY_TYPE } from '../entities/members'
import { OrgFeatureName, OrgFeatureType } from "../entities/OrganizationFeatures"

/**
 * Checks if the organization is a custody organization.
 * @param orgDetails - The organization details.
 * @returns True if the organization is a custody organization, false otherwise.
 */
export function isCustodyOrg(orgDetails: any): boolean {
    if (orgDetails) {
        return orgType.sub === orgDetails.orgType && CUSTODY_TYPE.CUSTODY === orgDetails.custodyType
    }
    return false
}



export function isNonCustodyOrg(orgDetails: any): boolean {
    if (orgDetails) {
      return orgType.standard === orgDetails.orgType || (orgType.sub === orgDetails.orgType && CUSTODY_TYPE.NONCUSTODY === orgDetails.custodyType);
    }
    return false
}


/**
 * Checks if the given orgDetails object represents a sub organization.
 * @param orgDetails - The orgDetails object to check.
 * @returns True if the orgDetails object represents a sub organization, false otherwise.
 */
export function isSubOrg(orgDetails: any): boolean {
    if (orgDetails) {
        return orgType.sub === orgDetails.orgType
    }
    return false

}


/**
 * Checks if multi-signature hybrid is enabled for the organization.
 * @param orgDetails - The organization details.
 * @returns A boolean indicating whether multi-signature hybrid is enabled.
 */
export function isMultiSigHybridEnabled(orgDetails: any): boolean {
    let orgFeatures = orgDetails.featureDetails;
    let isMultiSigHybrid = orgFeatures.some(feature => (OrgFeatureName.MULTISIG === feature.name?.toLowerCase() && OrgFeatureType.MULTISIG_HYBRID === feature.type));
    return orgDetails.orgType === orgType.sub && isMultiSigHybrid
}


/**
 * Checks if an organization has full custody multi-signature feature enabled.
 * 
 * @param orgDetails - The organization details.
 * @returns A boolean indicating whether the organization has full custody multi-signature feature enabled.
 */
function isMultiSigFullCustody(orgDetails: any): boolean {
    let orgFeatures = orgDetails.featureDetails;
    let isMultiSigFull = orgFeatures.some(feature => (OrgFeatureName.MULTISIG === feature.name?.toLowerCase() && OrgFeatureType.MULTISIG_FULL === feature.type));
    return orgDetails.orgType === orgType.sub && isMultiSigFull
}



/**
 * Checks if the organization has multi-signature custody enabled.
 * 
 * @param orgDetails - The organization details.
 * @returns A boolean indicating whether multi-signature custody is enabled.
 */
export function isMultiSigCustody(orgDetails: any): boolean {
    let orgFeatures = orgDetails.featureDetails;
    let isMultiSigCustody = orgFeatures?.some(feature => (OrgFeatureName.MULTISIG === feature.name?.toLowerCase() &&
        (OrgFeatureType.MULTISIG_HYBRID === feature.type || OrgFeatureType.MULTISIG_FULL === feature.type)));

    return orgDetails.orgType === orgType.sub && isMultiSigCustody
}


/**
 * Determines the multisig custody type based on the organization details.
 * @param orgDetails - The organization details.
 * @returns The multisig custody type.
 */
export function multisigCustodyType(orgDetails: any): OrgFeatureType {
    if (isMultiSigHybridEnabled(orgDetails)) return OrgFeatureType.MULTISIG_HYBRID;
    else if (isMultiSigFullCustody(orgDetails)) return OrgFeatureType.MULTISIG_FULL;
    return OrgFeatureType.MULTISIG_DISABLE;

}

/**
 * Checks if this is a suborg and has multisig enabled
 * @returns 
 */
export function showLinkDevice(orgDetails: any): boolean {
    if (!isSubOrg(orgDetails)) return true;
    return isMultiSigHybridEnabled(orgDetails)
}

/**
 * Checks if the SDK is enabled for a custody organization.
 * 
 * @param orgDetails - The organization details.
 * @returns True if the SDK is enabled for the custody organization, false otherwise.
 */
export function isSdkEnabledForCustodyOrg(orgDetails: any): boolean {
    const orgFeatures = orgDetails.featureDetails;
    const isSdkEnabled = orgFeatures.some(feature => (OrgFeatureName.SDK === feature.name?.toLowerCase()));
    return isCustodyOrg(orgDetails) && isSdkEnabled
}

/**
 * Checks if the organization is a non custody organization.
 * @param orgDetails - The organization details.
 * @returns True if the organization is a custody organization, false otherwise.
 */
export function isNonCustodySubOrg(orgDetails: any): boolean {
    if (orgDetails) {
        return orgType.sub === orgDetails.orgType && CUSTODY_TYPE.NONCUSTODY === orgDetails.custodyType
    }
    return false
}
