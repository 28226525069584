declare function overloadedConfig(string): any;

export default function getConfig(key: string, original: any) {
  if (typeof overloadedConfig === 'function') {
    const oConfig = overloadedConfig(key);
    if (oConfig === undefined) return original;
    return oConfig;
  }

  return original;
}
