<div class="container-fluid">
  <main>
    <div class="container-fluid p-0">
      <input class="form-control m-t-15" type="text" placeholder="Wallet gs Name" #walletName>
      <input class="form-control m-t-15" type="text" placeholder="config" #config>
      <input class="form-control m-t-15" type="text" placeholder="type" #wallettype>
      <input class="form-control m-t-15" type="text" placeholder="chain" #chain>
      <div class="row col-10 m-t-25">
        <div class="card col-xl-3" *ngFor="let option of addedSigners">
          <div class="row col-15 m-r-10">


            <div class="col-5"> <img class="img-100 rounded-circle" aria-hidden [src]="option.photoURL"></div>
            <div class="col-7"> <label class="m-t-10">{{option.displayName}}</label>
              <small class="m-b-5">{{option.email}}</small></div>

          </div>
        </div>

      </div>
      <div class="card col-xl-5  m-t-30">
        <div class="card-header">
          <h5>Add Signers</h5>
        </div>

        <div class="card-body">
          <input class="form-control" type="text" placeholder="Pick one" aria-label="Number" matInput
            [formControl]="myControl" [matAutocomplete]="auto" #signer1>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option class="signer-list badge-light m-b-5" *ngFor="let option of filteredOptions | async"
              [value]="option.email">

              <img class="img-30 rounded-circle m-r-5" aria-hidden [src]="option.photoURL">
              <span>{{option.displayName}}</span> |
              <small>{{option.email}}</small>
            </mat-option>
          </mat-autocomplete>
          <button type="button" (click)="addSignertoWallet()" class="btn btn-primary m-t-15" placement="top"
            ngbTooltip="Add to wallet" #addtoWalletButton>Add to Wallet</button>
        </div>
      </div>

    </div>
    <button type="button" (click)="createWallet()" class="btn btn-primary m-t-15" placement="top"
    ngbTooltip="Create new wallet" #addtoWalletButton>Create new wallet</button>
  </main>
</div>