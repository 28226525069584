
import BN from "bignumber.js";

const fmt = {
    prefix: '',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: ''
  }
  
  
  // Set the global formatting options
  BN.config({ FORMAT: fmt })


  export function toPretty(number:string|number , decimalPlaces:number){
   return new BN(number).absoluteValue().toFormat(decimalPlaces)

  }



export const humanReadableValue = (value: number | string, decimals = 18): string => {
  return new BN(value).times(`1e-${decimals}`).toFixed()
}

export const fromTokenUnit = (amount: number | string, decimals: string | number): string =>
  new BN(amount).times(`1e-${decimals}`).toFixed()

export const toTokenUnit = (amount: number | string, decimals: string | number): string =>
  new BN(amount).times(`1e${decimals}`).toFixed(0, BN.ROUND_DOWN)
