import Btc from '@ledgerhq/hw-app-btc';
import { getNewWebUSBTransport as getNewTransport } from "./getTransports";
import { PubKeys } from '../../entities/Pubkey';
import { getWalletType } from "../pathUtils";
import { ledgerLinkErrorHandler } from './linkError';
import * as bip32path from 'bip32-path';
const b58 = require('bs58check');
import { getCurrentApp } from "./getLedgerApp";

export async function linkLTCLedger() {
  this.ng_ltc = false;
  this.linking_Ledger_Error = true;
  this.linking_Ledger_Message = "Device link in progress..Please wait";
  let transport = await getNewTransport();
  try {
    const btc = new Btc(transport);
    let coinPath = 2;
    var CoinName = "Litecoin";
    /*
    Litecoin  0x019da462 - Ltub 0x019d9cfe - Ltpv P2PKH or P2SH m/44'/2'
    Litecoin  0x01b26ef6 - Mtub 0x01b26792 - Mtpv P2WPKH in P2SH  m/49'/1'
    */
    let xpubVersion = 27108450; // Mainnet

    let xpubConstp2sh = "01b26ef6" // Mainnet Mpub
    let xpubsegwitp2wsh = "04b24746" // Mainnet zpub

    const Arrpath = [{
      path: "m/49'/" + coinPath + "'/0'"
    }, {
      path: "m/49'/" + coinPath + "'/1'"
    }, {
      path: "m/49'/" + coinPath + "'/2'"
    }, {
      path: "m/44'/" + coinPath + "'/0'"
    }, {
      path: "m/48'/" + coinPath + "'/0'/1'"
    }, {
      path: "m/48'/" + coinPath + "'/0'/2'"
    }, {
      path: "m/44'/" + coinPath + "'/0'"
    },
    {
      path: "m/84'/" + coinPath + "'/0'"
    }];

    for (let i = 0; i < Arrpath.length; i++) {
      try {
        let resXpub;
        resXpub = await btc.getWalletXpub({ path: Arrpath[i].path, xpubVersion: xpubVersion });
        if (resXpub) {
          let wallet: PubKeys = { xpub: "", path: "", id: "", type: "", name: "", config: "" };
          if (Arrpath[i].path.includes("m/84") || Arrpath[i].path.includes("m/49")) {
            let data = b58.decode(resXpub)
            data = data.slice(4)
            if (Arrpath[i].path.includes("m/49")) {
              data = Buffer.concat([Buffer.from(xpubConstp2sh, 'hex'), data])
            }
            else {
              data = Buffer.concat([Buffer.from(xpubsegwitp2wsh, 'hex'), data])
            }
            const segwitxpub = b58.encode(data);
            wallet.xpub = segwitxpub;
          }
          else {
            wallet.xpub = resXpub;
          }
          wallet.path = Arrpath[i].path;
          wallet.config = '1of1';
          wallet.type = getWalletType(bip32path.fromString(Arrpath[i].path).toPathArray())
          wallet.id = wallet.xpub;
          wallet.coin = "LTC";
          await this.httpService.saveKey(wallet, 'ledger').toPromise()
        }
        else {
          this.coin_name = "LTC";
          this.error_message(this.coin_name)
        }

        try {
          this.segment.track("link-device-ltc-success", this.authService.getUser)
            .then(() => console.log("Link Device LTC Success")).catch((err)=>{});
          this.linked_coin.push('ltc');
        } catch (err) {
          this.logger.error(err, err);
          console.log(err);
        }
      } catch (error) {
        console.log(error);
        this.logger.error(error, error);
        this.segment.track("link-device-ltc-failed", this.authService.getUser)
          .then(() => console.log("Link Device LTC Failed")).catch((err)=>{});
      
        if (error.name == "TransportStatusError") {
          this.linking_Ledger_Error = true;
          this.linking_Ledger_Message = "Error while exporting Pubkeys!!";
          // Swal.fire({
          //   title: 'Fail!',
          //   text: 'Error while exporting Pubkeys!!',
          // })
        }
        else {
          //   alert(error.message);
        }
      }
      //this needs to be changed for the last coin to be linked

    }

  }
  catch (err) {
    ledgerLinkErrorHandler.call(this, err, 'ltc');
  }
  let currentapp = await getCurrentApp(transport);
  if (currentapp.name != "BOLOS") {
    //Exit app
    await transport.send(0xb0, 0xa7, 0x00, 0x00);
    await this.delay(2000);
  }
  this.ledger_linking_ltc = false;
  this.linking_ltc = false;
  this.linking_Ledger_Error = false;

  //this needs to be changed for the last coin to be linked

};//end