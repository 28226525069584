import axios, { AxiosError } from "axios";

export const ErrorMessage=(err:unknown): string =>{
    const errors = err as Error | AxiosError | string;

    if (axios.isAxiosError(errors))
    {
        let axiosError=errors as AxiosError;
        
        if(axiosError.response){
            return axiosError?.response?.data?.message;
        }
    }
    else if(errors instanceof Error){
        return errors.message;
    }
    else{
        return String(errors);
    }

    return "An unexpected error occurred.";
}