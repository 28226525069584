import { getNewWebUSBTransport as getNewTransport } from "./getTransports";
import Btc from '@ledgerhq/hw-app-btc';
import { PubKeys } from '../../entities/Pubkey';
import { getWalletType } from '../pathUtils';
import * as bip32path from 'bip32-path';
import { ledgerLinkErrorHandler } from "./linkError";
import { getCurrentApp } from "./getLedgerApp";

export async function linkDOGELedger() {
  this.ng_doge = false;
  this.linking_Ledger_Error = true;
  this.linking_Ledger_Message = "Device link in progress..Please wait";
  let transport = await getNewTransport();
  try {
    const btc = new Btc(transport);
    let coinPath = 3;
    var CoinName = "Dogecoin";
    let xpubVersion = 49990397; // Mainnet

    const Arrpath =
      [
        { path: "m/49'/" + coinPath + "'/0'" },
        { path: "m/49'/" + coinPath + "'/1'" },
        { path: "m/49'/" + coinPath + "'/2'" },
        { path: "m/44'/" + coinPath + "'/0'" },
        { path: "m/48'/" + coinPath + "'/0'/1'" },
        { path: "m/48'/" + coinPath + "'/0'/2'" },
        { path: "m/44'/" + coinPath + "'/0'" },
        { path: "m/84'/" + coinPath + "'/0'" }
      ]
    for (let i = 0; i < Arrpath.length; i++) {
      try {
        let resXpub;
        resXpub = await btc.getWalletXpub({ path: Arrpath[i].path, xpubVersion: xpubVersion });
        if (resXpub) {
          let wallet: PubKeys = { xpub: "", path: "", id: "", type: "", name: "", config: "" };
          wallet.xpub = resXpub;
          wallet.path = Arrpath[i].path;
          wallet.config = '1of1';
          wallet.type = getWalletType(bip32path.fromString(Arrpath[i].path).toPathArray())
          wallet.id = wallet.xpub;
          wallet.coin = "DOGE";
          await this.httpService.saveKey(wallet, 'ledger').toPromise()
        }
        else {
          this.coin_name = "DOGE";
          this.error_message(this.coin_name)
        }
        try {
          this.segment.track("link-device-doge-success", this.authService.getUser)
            .then(() => console.log("Link Device DOGE Success")).catch((err)=>{});
          this.linked_coin.push('doge');
        } catch (err) {
          this.logger.error(err, err);
          console.log(err);
        }
      } catch (error) {
        console.log(error);
        this.logger.error(error, error);
        
        this.segment.track("link-device-doge-failed", this.authService.getUser)
          .then(() => console.log("Link Device DOGE Failed")).catch((err)=>{});
      
        if (error.name == "TransportStatusError") {
          this.linking_Ledger_Error = true;
          this.linking_Ledger_Message = "Error while exporting Pubkeys!!";
        }
        else {
        }
      }
    }

  }
  catch (err) {
    ledgerLinkErrorHandler.call(this, err, 'doge');
  }
  let currentapp = await getCurrentApp(transport);
  if (currentapp.name != "BOLOS") {
    //Exit app
    await transport.send(0xb0, 0xa7, 0x00, 0x00);
    await this.delay(2000);
  }
  this.ledger_linking_doge = false;
  this.linking_doge = false;
  this.linking_Ledger_Error = false;

  //this needs to be changed for the last coin to be linked

};//end