const oldConsoleLog = window.console.log;
const oldConsoleError = window.console.error;
function overrideConsole(logger) {
  window.console.log = function () {
    oldConsoleLog.apply(console, arguments);
    logger.info(arguments);
  }
  window.console.error = function () {
    oldConsoleError.apply(console, arguments);
    logger.error(arguments);
  }
}

export default overrideConsole;