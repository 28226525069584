import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appDisableClick]',
  providers: [NgbTooltip]
})
export class DisableClickDirective implements OnInit, OnDestroy {

  private subscription: Subscription;
  private parentButton: HTMLButtonElement;

  constructor(private elementRef: ElementRef, private data: DataService, private renderer: Renderer2, private toolTipService: NgbTooltip) {}

  // @HostListener('mouseenter')
  // onMouseEnter() {
  //   this.createTooltip();
  // }

  // @HostListener('mouseleave')
  // onMouseLeave() {
  //   this.toolTipService.close();
  // }

  ngOnInit() {
    this.subscription = this.data.isKycComplete.asObservable().subscribe((disable: boolean) => {
      this.setDisabledAttribute(!disable);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private setDisabledAttribute(disable: boolean) {
    if (disable) {
      this.parentButton = this.renderer.createElement('button');
      this.renderer.setAttribute(this.parentButton, 'disabled', 'true');
      this.renderer.setStyle(this.parentButton, 'cursor', 'not-allowed');
      this.renderer.setStyle(this.parentButton, 'border', 'unset');
      this.renderer.setStyle(this.parentButton, 'background', 'unset');
      this.renderer.setStyle(this.parentButton, 'padding', 'unset');
      this.renderer.insertBefore(this.elementRef.nativeElement.parentElement, this.parentButton, this.elementRef.nativeElement);
      this.renderer.appendChild(this.parentButton, this.elementRef.nativeElement);
      this.renderer.setStyle(this.elementRef.nativeElement, 'pointerEvents', 'none');
      this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', '0.6');
      this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'not-allowed');
    } else if (this.parentButton) {
      this.renderer.removeChild(this.parentButton.parentElement, this.parentButton);
      this.parentButton = null;
      this.renderer.setStyle(this.elementRef.nativeElement, 'pointerEvents', 'auto');
      this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', '1');
      this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'pointer');
    }
  }

  private createTooltip() {
    this.toolTipService.ngbTooltip = 'KYC Incomplete, We have sent you an email, Please locate the relevant link in your email to start the KYC process';
    this.toolTipService.open({ host: this.parentButton });
  }


}
