import TransportWebUSB from '@ledgerhq/hw-transport-webusb';
import TransportWebHID from "@ledgerhq/hw-transport-webhid";

export async function getNewWebUSBTransport() {
    //return await this.getNewTransportTRON();
    while (true) {
        try {
            //return await TransportWebHID.create();
            return await TransportWebUSB.create();
        } catch (error) {
            console.log(error);
            await this.delay(2000);
        }
    }
}

export async function getNewTransportTRON() {
    while (true) {
      try {
        return await TransportWebHID.create();
      } catch (error) {
        console.log("getNewTransportTRON", error.name)
        await this.delay(2000);
      }
    }
  }
