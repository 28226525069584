import { Output, TransactionRequest } from '../../../entities';
import * as bip32path from 'bip32-path'
import { signShieldTransaction } from "./signShieldTransaction";
import { signPsbt } from "./signPsbt";

export async function signMultisigTransaction(output: Output, txReq: TransactionRequest) {
    let txHex = '';
    // console.log("Parameters", output, txReq);
    if (!txReq || txReq['actionType'] == 'travel-rule') {
        if (this.wallet.type == 'multisig') {
            let dataApi = await this.httpService.getMultisigWalletTx(this.wallet.id, output.address, output.value + "")
                .toPromise()
            let tx = JSON.parse(dataApi);

            if (tx.error) return [{ "error": tx.error }];
            txHex = tx.txhex;
        } else if (this.wallet.type == 'multisig_shield') {
            try {
                let dataApi = await this.httpService.buildMultisigShieldWalletTx(this.wallet.id, output.address, output.value + "")
                    .toPromise()
                let tx = dataApi;

                if (tx.error) return [{ "error": tx.error }];
                txHex = tx.txHex;
                let trezorTx = tx.trezorTx;
                return await signShieldTransaction.call(this, trezorTx, txHex, output, txReq);
            } catch (e) {
                return [{ "error": e.toString() }]
            }
        }
    } else {
        if (this.wallet.type == 'multisig_shield') {
            let raw = JSON.parse(txReq.raw);
            txHex = raw.txHex;
            let trezorTx = raw.trezorTx;

            trezorTx = updateTrezorTxPath.call(this, trezorTx);
            return await signShieldTransaction.call(this, trezorTx, txHex, output, txReq);
        }
        this.logger.info(JSON.stringify(txReq))
        let raw = JSON.parse(txReq.raw).txHex;
        txHex = raw;
    }
    let signedTx = await signPsbt.call(this, txHex, output, txReq);
    return signedTx;
}

function updateTrezorTxPath(trezorTx) {
    let myKey = this.wallet.walletKeys.filter(key => key.ismine);
    console.info("myKey", myKey);
    if (myKey.length > 0) {

        for (let i = 0; i < trezorTx.inputs.length; i++) {
            if (trezorTx.inputs[i].address_n.length > 0
                && trezorTx.inputs[i].address_n[0] != bip32path.fromString(myKey[0].path).toPathArray()[0]
            ) {
                console.info("mypath", bip32path.fromString(myKey[0].path).toPathArray())
                console.info("trezor tx path", trezorTx.inputs[0].address_n)
                trezorTx.inputs[i].address_n = bip32path.fromString(myKey[0].path).toPathArray();
            }
        }
        //"Output change script_type should be set to PAYTOMULTISIG"
        for (let i = 0; i < trezorTx.outputs.length; i++) {
            if (trezorTx.outputs[i].address_n && trezorTx.outputs[i].address_n.length > 0 && trezorTx.outputs[i].address_n[0] != bip32path.fromString(myKey[0].path).toPathArray()[0]) {

                console.info("old", trezorTx.outputs[i].address_n)
                console.info("new", bip32path.fromString(myKey[0].path).toPathArray())
                trezorTx.outputs[i] = {
                    "address": this.wallet.address,
                    "amount": trezorTx.outputs[i].amount,
                    "script_type": "PAYTOADDRESS"
                }

            }
        }
    }
    console.log(trezorTx);
    return trezorTx;
}