import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthServiceJWT } from './auth.service';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

// response HttpInterceptor class
@Injectable({
  providedIn: 'root'
})
export class ResponseInterceptor implements HttpInterceptor {
  constructor(public auth: AuthServiceJWT) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if (error.status === 401) {
          if(request.url!== `${environment.apiUrl}/wallet-screening/apps/all`){
            this.auth.SignOut();
          }
          // auto logout if 401 response returned from api
        }else 
        // const err = error.error.message || error.error.code;
        return throwError(error);
      })
    );
  }
}