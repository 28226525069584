import { Component, OnInit } from "@angular/core";
import { AuthServiceJWT } from "../../shared/services/auth.service";

@Component({
  selector: "app-access-denied",
  templateUrl: "./access-denied.component.html",
  styleUrls: ["./access-denied.component.scss"],
})
export class AccessDeniedComponent implements OnInit {

  constructor(public authService: AuthServiceJWT) {}

  ngOnInit(): void {}

  logout() {
    this.authService.SignOut();
  }
}
