import { Output, SignedTransaction } from '../../../entities';
import { TransactionRequest } from '../../../entities/TransactionRequest';
import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import { PublicKey, Transaction as solanaTransaction } from '@solana/web3.js';
import Solana from "@ledgerhq/hw-app-solana";
import { binary_to_base58 } from 'base58-js'
import { LedgerService } from '../../ledger/ledger';
import { Guid } from "guid-typescript";
import { HttpErrorResponse } from '@angular/common/http';


function errorHandler(err: any, objwalletkey: any) {
    let errorMessage: string;

    switch (err.message) {
        case 'Missing a parameter. Try enabling blind signature in the app':
            errorMessage = 'Please make sure blind signing is enabled for Solana and Solana app is open in Ledger at the time of signing';
            break;
        case "Ledger device: UNKNOWN_ERROR (0x6a8d)":
            errorMessage = "Allow unverified contracts from Ledger app setting.";
            break;
        case "TransportOpenUserCancelled":
            errorMessage = "It seems you have not connected your ledger device or please recheck the connection and try again.";
            break;
        default:
            if ('response' in err && err.response.data.message) {
                if (err.response.data.message == "failed to send transaction: Transaction simulation failed: Error processing Instruction 0: custom program error: 0x1") {
                    errorMessage = "Insuffucent Fund in this address " + objwalletkey[0].xpub;
                } else {
                    errorMessage = this.walletService.setCustomErrorMsg(err.response.data.message);
                }
            }
            else if (err instanceof HttpErrorResponse) {
                errorMessage = this.walletService.setCustomErrorMsg(err.error.message);
            }
            else {
                errorMessage = this.walletService.setCustomErrorMsg(err.message);
            }
            break;
    }

    this.segment.track("send-asset-transaction-sign-failed", { user: this.authService.getUser, error: err })
        .then(() => console.log("Send asset transaction sign failed"))
        .catch((err) => { });
    
    return [{ error: errorMessage }];
}


export async function signSolTransaction(output: Output, req: TransactionRequest, type: string) {
    if (output.value < 0.001) {
        return [{ "error": "Minimum tx amount should be 0.001" }];
    }
    let transport = await TransportWebHID.create();
    let objwalletkey = this.wallet.walletKeys.filter(item => item.ismine === true);
    try {
        let txParam: any = {}
        var txJSON;
        var txid;
        let CoinName = "Solana";
        let mypath = objwalletkey[0].path;
        const solana = new Solana(transport);
        let Address = await solana.getAddress(mypath);
        let address = binary_to_base58(Address.address);
        if (objwalletkey[0].xpub != address) {
            return [{ "error": "Wrong Ledger Device PubKey" }];
        }
        if ((req && req['actionType'] == 'travel-rule') || !req) {
            var respData = await this.httpService.getPrebuildTx({
                reqobj:
                {
                    nonce: 0,
                    output: output,
                    wallet: this.wallet,
                    type: type.toLowerCase(),
                }
            })
            var raw_tx = respData.txJson.msgs[0];
            var base64string = raw_tx;
            var buf = Buffer.from(base64string, 'base64'); // Ta-da
            let transaction = solanaTransaction.from(buf);
            var serializetx = transaction.serializeMessage();;
            let currentapp = await LedgerService.getCurrentApp(transport);
            if (currentapp.name != CoinName) {
                return [{ "error": "Please make sure Solana app is open in Ledger with blind signing enabled and try again" }]
            }
            const sintx = await solana.signTransaction(mypath || "44'/501'/0'", serializetx);
            transaction.addSignature(new PublicKey(objwalletkey[0].xpub), sintx.signature);
            let endocdeTransction = transaction.serialize({
                requireAllSignatures: false,
                verifySignatures: true,
            });
            var signedbase64 = endocdeTransction.toString('base64');
            await transport.close();

            let signTransactionResponse: SignedTransaction = {
                txBASE64: signedbase64,
                proposalAddress: respData.txJson.proposalAddress,
                solanaoutput: output,
                coinSymbol: respData.txOutputs?.coinSymbol,
                comment: output.comment
            };
            // if (req && req['actionType'] == 'travel-rule') signTransactionResponse['sequenceId'] = req['sequenceId'];
            let sequenceId = Guid.create().toString();
            signTransactionResponse['sequenceId'] = sequenceId;
            if (req) {
                if ('sequenceId' in req) {
                    signTransactionResponse['sequenceId'] = req['sequenceId']
                }
            }
            var sendresult = await this.httpService.SendTransaction(this.wallet.id, signTransactionResponse);
            sendresult = (sendresult)
            if (sendresult.status !== 200) {
                return [{ "error": sendresult }]
            }
            return "success";
        } else {
            let m = Number.parseInt(this.wallet.config.split("of")[0]);
            let n = this.wallet.config.split("of")[1];
            txid = req.id;
            txParam = (req.raw);
            let data =
            {
                "sig": txParam,
                "pubkey": objwalletkey[0].xpub
            }
            var respgetAprovetx = await this.httpService.GetapproveTxSol(JSON.stringify(data), this.wallet.id, txid);
            if (respgetAprovetx.status == 200) {

                let approvedata = {
                    "sig": txParam,
                    "pubkey": objwalletkey[0].xpub,
                    "base64": ""
                }
                var exebase64 = respgetAprovetx.data.data.base64;
                // @ts-ignore
                //const API_URL = clusterApiUrl(environment.sol_config.SOL.network);
                //var connection = new Connection(API_URL);
                var buf = Buffer.from(exebase64, 'base64'); // Ta-da
                let transactionold = solanaTransaction.from(buf);
                let transaction = new solanaTransaction();

                transaction.instructions = (transactionold.instructions);
                //let blockhashObj = await connection.getRecentBlockhash();
                //transaction.recentBlockhash = await blockhashObj.blockhash;
                transaction.recentBlockhash = await transactionold.recentBlockhash;
                transaction.feePayer = new PublicKey(objwalletkey[0].xpub);
                var serializetx = transaction.serializeMessage();
                const sintx = await solana.signTransaction(mypath || "44'/501'/0'", serializetx);
                transaction.addSignature(new PublicKey(objwalletkey[0].xpub), sintx.signature);
                let endocdeTransction = transaction.serialize({
                    requireAllSignatures: false,
                    verifySignatures: true,
                });
                var signedbase64 = endocdeTransction.toString('base64');
                await transport.close();
                approvedata.sig = binary_to_base58(sintx.signature);
                approvedata.base64 = signedbase64;
                var respApprove = await this.httpService.ApproveTx(JSON.stringify(approvedata), this.wallet.id, txid);
                if (respApprove.status == 200) {
                    return "success";
                }
            }
        }
    }
    catch (err) {
        console.error("signSolTransaction", err);
        transport.close();
        return errorHandler.call(this, err, objwalletkey);
    }
}