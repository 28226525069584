import { Injectable, OnInit } from '@angular/core';
import { Wallet } from '../entities/wallet'
import { HttpService } from './http.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { LocalForageService } from 'src/app/shared/services/localforage.service';
import { first } from 'rxjs/operators';
import localforage from 'localforage';
import { BehaviorSubject } from 'rxjs';
import { MessageRequestStatusAPIData } from '../entities/messageSigningRequest';
import { getWalletType } from '../helpers/WalletUtils';
import { getName } from '../helpers/coins';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WalletserviceService {
  data = Array<string>()
  wallets: {};
  walletids: any
  // private viewWallet = new BehaviorSubject(true);
  // currentViewWallet = this.viewWallet.asObservable();
  constructor(private httpService: HttpService, private logger: LoggerService, private localForageService: LocalForageService
  ) {
    localforage.config({
      name: `liminal_${environment.env}`,
      storeName: "wallets"
    })
    this.localForageService.store.subscribe(o => {
      this.wallets = o;
      this.walletids = Object.keys(this.wallets);
    });
    this.localForageService.allData();
  }

  private messageSignObservable = new BehaviorSubject(<MessageRequestStatusAPIData>{});
  messageSignStatus = this.messageSignObservable.asObservable();

  private policyNotWhitelisted = new BehaviorSubject(<any>{});
  policyNotWhitelistedStatus = this.policyNotWhitelisted.asObservable();

  messageSignChangeStatus(status: MessageRequestStatusAPIData) {
      this.messageSignObservable.next(status);
  }

  policyNotWhitelistedChangeStatus(status: any) {
    this.policyNotWhitelisted.next(status);
}

  clearWallet() {
    localforage.clear();
  }

  setWallet(wallet: Wallet) {
    const key = wallet.id + "_" + wallet.coin?.toLowerCase();
    localforage.setItem(key, wallet);
    this.wallets[key] = wallet;
  }

  getWallet(walletid: string): Wallet {
    let data = this.wallets[walletid];
    return data;
  }

  getAllWallets(): Wallet[] {
    return Object.values(this.wallets);
  }

  getAllWalletsForAdmin() {
    let walletids = Array<string>()
    let wallets = Array<Wallet>();
    // let res = this.httpService.getAllWalletsForAdmin()
    this.httpService.getAllWalletsForAdmin().pipe(first()).subscribe(allWalletsStr => {

      if (allWalletsStr && allWalletsStr.length > 1) {
        // check if for lowercase
        walletids = JSON.parse(JSON.stringify(allWalletsStr));
      }
      for (let i = 0; i < walletids.length; i++) {
        try {
          wallets.push(this.getWallet(walletids[i]));

        } catch (error) {
          console.error(error);
          this.logger.error(error, error)
        }

      }
      localforage.setItem("wallets_data", wallets);
    })
    return wallets;
  }

  // changeViewWallet(View: any) {
  //   this.viewWallet.next(View);
  // }

  setCustomErrorMsg(msg) {
    let error = msg.toLowerCase();
    let m1 = "failed to execute 'requestdevice' on 'usb'";
    let m2 = "transportstatuserror";
    let m3 = "ledgerdevice";
    if(error.includes(m1) || error.includes(m2) || error.includes(m3)) 
      return "It seems you have not connected your ledger device or please recheck the connection and try again."
    return msg;
  }

  createPendingWalletMap(walletRequests: any[]){
    if(!walletRequests.length){
      return [];
    }

    const walletRequestsMap = walletRequests.map((item: any) => {
      let tag;
      if (item['w_scheduledBatch']) {
        tag = "refill";
      } else if (item?.w_subtype == "hot" || item?.w_subtype == "hot_pipeline" || item?.w_subtype == "deposit") {
        tag = "hot";
      } else {
        tag = "cold";
      }

      let walletMap = {
        id: item?.w_id,
        access: "",
        assets: [],
        coinfullname: getName(item?.w_chain),
        sum: 0,
        balance: 0,
        balanceUSD: 0,
        chain: item?.w_chain,
        coin: item?.w_coin || item?.w_chain,
        name: item?.w_name,
        parentchain: item?.w_parentchain,
        address: "",
        walletKeys: [],
        config: item?.w_config,
        version: 2,
        teamMembers: item?.teamMembers.map(item => item.user) || [],
        walletType: getWalletType(item?.w_type, item?.w_subtype) || item?.walletType,
        pending: 1,
        tag: tag,
        tags: [],
        type: item?.w_type,
        state: 'normal',
        pending_txs: [],
        isArchived: false,
        isWalletReq: true
      }

      walletMap['assets'].push(walletMap);
      walletMap['tags'].push(tag);

      return walletMap
    });

    return walletRequestsMap;
  }
}