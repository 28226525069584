import { getNewWebUSBTransport as getNewTransport } from "./getTransports";
import { ledgerLinkErrorHandler } from "./linkError";
import { environment } from '../../../../environments/environment';
import Xrp from "@ledgerhq/hw-app-xrp";
import { PubKeys } from '../../entities/Pubkey';
import { getCurrentApp } from "./getLedgerApp";

export async function linkXRPLedger() {
  this.ng_xrp = false;

  this.linking_Ledger_Error = true;
  this.linking_Ledger_Message = "Device link in progress..Please wait";
  let transport = await getNewTransport();
  try {
    const xrp = new Xrp(transport);
    let result = await xrp.getAddress(environment.xrp_config.XRP.path || "44'/144'/0'/0/0", false);
    let pubkey = result.publicKey;
    let address = result.address;
    if (result) {
      this.linking_xrp = false;
      this.linked_coin.push("xrp");
      let wallet: PubKeys = { xpub: "", path: "", id: "", type: "", name: "", config: "" };
      wallet.xpub = address;
      wallet.path = "m/" + environment.xrp_config.XRP.path || "m/" + "44'/144'/0'/0/0";
      wallet.id = address;
      wallet.coin = "XRP"
      wallet.type = "address"
      wallet.provider = "ledger"
      // console.log(wallet)
      await this.httpService.saveKey(wallet, 'ledger').toPromise()
    } else {
      this.coin_name = "XRP";
      this.error_message(this.coin_name);
    }

    this.segment.track("link-device-xrp-success", this.authService.getUser)
      .then(() => console.log("Link Device XRP Success")).catch((err)=>{});
  }
  catch (err) {
    ledgerLinkErrorHandler.call(this, err);
  }
  let currentapp = await getCurrentApp(transport);
  if (currentapp.name != "BOLOS") {
    //Exit app
    await transport.send(0xb0, 0xa7, 0x00, 0x00);
    await this.delay(2000);
  }
  this.ledger_linking_xrp = false;
  this.linking_xrp = false;
  this.linking_Ledger_Error = false;

  //this needs to be changed for the last coin to be linked
}