import { Component, OnInit,Input } from '@angular/core';
import { LoggerService} from 'src/app/shared/services/logger.service';
@Component({
  selector: 'app-address-card',
  inputs: ['option2'],
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss']
})
export class AddressCardComponent implements OnInit {
  @Input() option2: any;
  @Input() card_padding: string;
  constructor(private logger:LoggerService) {
   }

  ngOnInit(): void {
  }
}
