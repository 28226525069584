import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import snsWebSdk from '@sumsub/websdk';
import { HttpService } from '../../services/http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessPrompt } from '../custom-prompt/custom-prompt.service';
import { SuccessPromptPayload } from '../../constants/promptMessages';

@Component({
  selector: 'app-liveness-kyc',
  templateUrl: './liveness-kyc.component.html',
  styleUrls: ['./liveness-kyc.component.scss']
})
export class LivenessKycComponent implements OnInit {
  @Input() component_for: string;
  @Input() identifier: string;
  @Input() isBasicKycCheck: boolean = false;
  @Input() headerTitle: string = 'Liveness kyc check';

  @Output() refreshKycStatus : EventEmitter<boolean> = new EventEmitter();

  @ViewChild('livenessKycCheck') livenessModalRef: ElementRef;

  constructor(private httpService: HttpService, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.isBasicKycCheck) {
      this.showLivenessCheckModal(this.livenessModalRef)
      this.launchWebSdk();
    }
  }

  /**
 * @param accessToken - access token that you generated on the backend in Step 2
 * @param applicantEmail - applicant email (not required)
 * @param applicantPhone - applicant phone, if available (not required)
 * @param customI18nMessages - customized locale messages for current session (not required)
 */
  async launchWebSdk() {
    const accessToken = await this.getNewAccessToken();
    let snsWebSdkInstance = snsWebSdk.init(
      accessToken,
      // token update callback, must return Promise
      // Access token expired
      // get a new one and pass it to the callback to re-initiate the WebSDK
      () => this.getNewAccessToken()
    )
      .withConf({
        lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
        // email: applicantEmail,
        // phone: applicantPhone,
        // i18n: customI18nMessages, //JSON of custom SDK Translations
        // uiConf: {
        // customCss: "https://url.com/styles.css"
        // URL to css file in case you need change it dynamically from the code
        // the similar setting at Customizations tab will rewrite customCss
        // you may also use to pass string with plain styles `customCssStr:`
        // },
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      // see below what kind of messages WebSDK generates
      .on('idCheck.stepCompleted', (payload) => {
        console.log('stepCompleted', payload)
      })
      .on('idCheck.onApplicantSubmitted', () => {
        console.info('onApplicantSubmitted')
        if (this.component_for === 'verify-liveness-check') {
          this.refreshKycStatus.emit(true);
          SuccessPrompt.fire(SuccessPromptPayload.LIVENESS_COMPLETED);
        }
      })
      .build();

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch('#sumsub-websdk-container')
  }

  async getNewAccessToken(): Promise<string> {
    let response: any;
    if (this.isBasicKycCheck) {
      response = await this.httpService.verifySumsub().toPromise() as any;
    }
    else response = await this.httpService.getFaceAuthToken(this.identifier).toPromise() as any;
    return response.data.token as string;
  }

  showLivenessCheckModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true })
  }
}
