import { Component, OnInit, Input } from '@angular/core';
import { LoggerService } from 'src/app/shared/services/logger.service';

@Component({
  selector: 'app-user-information',
  inputs: ['user'],
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit {

  @Input() user: any;
  constructor(private logger: LoggerService) {
  }

  ngOnInit(): void {
  }
}