

export function allowNumberDecimal(event)
{   
  var k;  
  k = event.charCode;  //  k = event.keyCode;  (Both can be used)
  return(k == 8 || k == 32 || (k >= 48 && k <= 57) || k == 46); 
}

export function allowNumber(event)
{
  var k;  
  k = event.charCode;  //  k = event.keyCode;  (Both can be used)
  return(k >= 48 && k <= 57); 
}


