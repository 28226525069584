
<!-- Page Sidebar Start-->
<div class="logo-icon-wrapper text-center">
  <a routerLink="/">
    <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="">
  </a>
</div>
<nav class="sidebar-main">
  <div id="sidebar-menu">
    <ul class="sidebar-links custom-scrollbar">
      <div class="simplebar-content p-0" *ngIf="loading">
        <li class="sidebar-list cursor-pointer ">
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </li>
        <!-- <li class="sidebar-list cursor-pointer" routerLink="/wallets" *ngIf="active" routerLinkActive="active" (click)="checkWallet(true)">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-wallets"></i> Wallets</a>
        </li>
        <li class="sidebar-list cursor-pointer" routerLink="/wallets" *ngIf="!active" (click)="checkWallet(true)">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-wallets"></i> Wallets</a>
        </li> -->
        <li class="sidebar-list cursor-pointer">
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </li>
        <li class="sidebar-list cursor-pointer">
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </li>
       
       
        <!-- <li class="sidebar-list cursor-pointer py-3 my-3">
          <a (click)="logout()" class="d-flex flex-column align-items-center text-dark"><i class="icon-logout"></i> Logout</a>
        </li> -->
      </div>
      <div class="simplebar-content p-0" *ngIf="!loading" (click)="onClickNavItem()">
        <li class="sidebar-list cursor-pointer" routerLink="/" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <a class="d-flex flex-column align-items-center"><i class="icon-home"></i> Home</a>
        </li>
        <!-- <li class="sidebar-list cursor-pointer" routerLink="/wallets" *ngIf="active" routerLinkActive="active" (click)="checkWallet(true)">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-wallets"></i> Wallets</a>
        </li>
        <li class="sidebar-list cursor-pointer" routerLink="/wallets" *ngIf="!active" (click)="checkWallet(true)">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-wallets"></i> Wallets</a>
        </li> -->
        <li class="sidebar-list cursor-pointer" [ngClass]="{'active': isWalletActive()}" >
          <a class="d-flex flex-column align-items-center" routerLink="/wallets"><i class="icon-wallets"></i>Wallets</a>
          <a class="d-none" routerLink="/wallets/refills"><i class="icon-dev"></i></a>
        </li>
        <li noDisplay class="sidebar-list cursor-pointer" [ngClass]="{'active': isStakingActive()}" *ngIf="profile.orgType !== orgType.sub">
          <a class="d-flex flex-column align-items-center text-dark" routerLink="/wallets/staking" ><i class="icon-staking"></i> Staking</a>
          <a class="d-none" routerLink="/wallets/staking/calculator"><i class="icon-dev"></i></a>
          <a class="d-none" routerLink="/wallets/staking_legacy"><i class="icon-dev"></i></a>
        </li>
       
        <li class="sidebar-list cursor-pointer" routerLink="/tx" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <a class="d-flex flex-column align-items-center "><i class="icon-history"></i> History</a>
        </li>
        <li class="sidebar-list cursor-pointer" *ngIf="profile.type===2"  routerLink="/reports" routerLinkActive="active">
          <a class="d-flex flex-column align-items-center">
            <!-- <i class="icon-reports"></i>  -->
            <svg width="21" height="19.2" viewBox="0 0 21 27" fill="none" class="svg-icon" xmlns="http://www.w3.org/2000/svg">
              <g id="Reports">
              <path id="Vector" d="M16.5753 1.54785H4.4254C2.5336 1.54785 1 3.10656 1 5.02932V21.9705C1 23.8933 2.5336 25.452 4.4254 25.452H16.5753C18.4671 25.452 20.0007 23.8933 20.0007 21.9705V5.02932C20.0007 3.10656 18.4671 1.54785 16.5753 1.54785Z" stroke="#8d96b8" stroke-width="1.15833" stroke-miterlimit="10"/>
              <path id="Vector_2" d="M6.81641 15.0376V20.6119" stroke="#8d96b8" stroke-width="1.15833" stroke-miterlimit="10" stroke-linecap="round"/>
              <path id="Vector_3" d="M10.7188 10.673V20.6116" stroke="#8d96b8" stroke-width="1.15833" stroke-miterlimit="10" stroke-linecap="round"/>
              <path id="Vector_4" d="M14.623 13.2817V20.6117" stroke="#8d96b8" stroke-width="1.15833" stroke-miterlimit="10" stroke-linecap="round"/>
              </g>
            </svg>
            Reports</a>
        </li>
        <li class="sidebar-list cursor-pointer" routerLink="/apps" (click)="manipulateView()" routerLinkActive="active" *ngIf="!isCustodyOrganization && profile.type === 2">
          <a class="d-flex flex-column align-items-center"><i class="icon-apps"></i> Apps</a>
        </li>
        <ng-container *ngIf="showDevTab()">
          <li class="sidebar-list cursor-pointer" *ngIf="profile.type === 2" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class="d-flex flex-column align-items-center text-dark" [routerLink]="devDefaultRouting"><i class="icon-dev"></i> Dev</a>
            <!-- <a class="d-flex flex-column align-items-center text-dark" routerLink="/dev/protocols"><i class="icon-dev"></i> Dev</a> -->
            <a class="d-none" routerLink="/dev/configure"><i class="icon-dev"></i></a>
            <a class="d-none" routerLink="/dev/gas-station"><i class="icon-dev"></i></a>
          </li>
        </ng-container>
        <li class="sidebar-list cursor-pointer" routerLink="/settings" routerLinkActive="active">
          <a class="d-flex flex-column align-items-center"><i class="icon-settings"></i> Settings</a>
        </li>
        <!-- <li class="sidebar-list cursor-pointer py-3 my-3">
          <a (click)="logout()" class="d-flex flex-column align-items-center text-dark"><i class="icon-logout"></i> Logout</a>
        </li> -->
      </div>
    </ul>
  </div>
</nav>