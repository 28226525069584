import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CustomPrompt, ErrorPrompt, SuccessPrompt } from './custom-prompt.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ICustomPrompt, IErrorPrompt, ISuccessPrompt } from './custom-prompt.interface';

@Component({
  selector: 'app-custom-prompt',
  templateUrl: './custom-prompt.component.html',
  styleUrls: ['./custom-prompt.component.scss']
})
export class CustomPromptComponent implements OnInit {

  private modalRef: NgbModalRef;
  private customModalRef: NgbModalRef;
  public errorMessage: IErrorPrompt;
  public successMessage: ISuccessPrompt;
  public customMessage: ICustomPrompt
  @ViewChild("errorModal") errorPromptModel: ElementRef;
  @ViewChild("successModal") successPromptModel: ElementRef;
  @ViewChild("customPromptModal") customPromptModal: ElementRef;

  constructor(private modalService: NgbModal,) { }

  ngOnInit() {
    this.openErrorModal();
    this.openSuccessModal();
    this.openCustomModal();
  }

  openErrorModal() {
    ErrorPrompt.error$.subscribe((payload) => {
      this.closeModal()
      console.log(payload, 'custom error payload');
      this.errorMessage = payload;
      // Timer feature incase required.
      // if (payload && payload?.timer) {
      //   setTimeout(() => {
      //     this.closeModal();
      //   }, payload.timer);
      // }

      this.modalRef = this.modalService.open(this.errorPromptModel, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      });

      this.modalRef.result.then(
        (modalCloseResult: any) => {
          ErrorPrompt.notifyModalClosed();
          // Handle modal close
        },
        (ModalDismissReasons: any) => {
          // Handle modal dismissal
          ErrorPrompt.notifyModalClosed();
        }
      );
    });
  }

  openSuccessModal() {
    SuccessPrompt.success$.subscribe((payload) => {
      this.successMessage = payload;
      this.modalRef = this.modalService.open(this.successPromptModel, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      });
      this.modalRef.result.then(
        (modalCloseResult: any) => {
          SuccessPrompt.notifyModalClosed();
          // Handle modal close
        },
        (modalDismissReasons: any) => {
          // Handle modal dismissal
          SuccessPrompt.notifyModalClosed();
        }
      );
    });
  }

  openCustomModal() {
    CustomPrompt.custom$.subscribe((payload) => {
      this.customMessage = payload;
      this.customModalRef = this.modalService.open(this.customPromptModal, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      });

      this.customModalRef.result.then(
        (modalSubmit: any) => {
          //remove
          CustomPrompt.notifyModalClosed({ data: payload.callbackData, success: true, msg: 'submitted' });
        },
        (modalCancel: any) => {
          // cancel
          CustomPrompt.notifyModalClosed({ data: payload.callbackData, success: false, msg: 'cancelled' });
        }
      );
    });
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close('close');
    }
  }

  submit() {
    CustomPrompt.notifyModalClosed({ data: this.customMessage.callbackData, success: true, msg: 'submitted' , modalRef: this.customModalRef });
  }

}
