const util = require('util')

import * as bitcoin from 'bitcoinjs-lib';
import * as bip32path from 'bip32-path'
import { Psbt } from 'bitcoinjs-lib';
import { Wallet } from '../../entities/wallet';
const b58 = require('bs58check')
import { environment } from 'src/environments/environment';
import { getOutputScriptType, getScriptType } from '../pathUtils';

function reverseBuffer(buffer) {
    if (buffer.length < 1) return buffer;
    let j = buffer.length - 1;
    let tmp = 0;
    for (let i = 0; i < buffer.length / 2; i++) {
        tmp = buffer[i];
        buffer[i] = buffer[j];
        buffer[j] = tmp;
        j--;
    }
    return buffer;
}



function getSignerFullPath(walletpath, input, xpub) {

    let inputPath = bip32path.fromString(walletpath).toPathArray();
    for (let i = 0; i < input.bip32Derivation.length; i++) {
        let endPath = bip32path.fromString(input.bip32Derivation[i].path).toPathArray()
        // let data = b58.decode(xpub)
        // data = data.slice(4)
        // data = Buffer.concat([Buffer.from(environment.xpubConst, 'hex'), data])
        // xpub = b58.encode(data);
        let pubKey = bitcoin.bip32.fromBase58(xpub, environment.btcNetwork).derive(endPath[0]).derive(endPath[1]).publicKey.toString("hex")
        if (input.bip32Derivation[i].pubkey.toString("hex") == pubKey) {
            inputPath = inputPath.concat(bip32path.fromString(input.bip32Derivation[i].path).toPathArray());
            console.info("inputPath", inputPath);
            return inputPath;
        } else {
            //console.log(input.bip32Derivation[i].pubkey.toString("hex") , pubKey ,input.bip32Derivation[i].path)
        }
    }

}
function processPubKeys(walletXpub, remoteSigners, input) {

    let pubs = [];


    let walletPub = {
        node: walletXpub,
        address_n: bip32path.fromString(input.bip32Derivation[0].path).toPathArray()
    }
    pubs.push(walletPub)
    console.log("pubs.push(walletPub)", pubs, remoteSigners)
    for (let i = 0; i < remoteSigners.length; i++) {
        pubs.push({
            node: remoteSigners[i],
            address_n: bip32path.fromString(input.bip32Derivation[0].path).toPathArray()
        })
    }
    console.log("for (let i", pubs)
    return sortKeys(pubs, environment.btcNetwork);

}
function processInputs(psbtTx: Psbt, wallet, remoteSigners,scriptSig) {
    let trezorInputs = [];

    for (let i = 0; i < psbtTx.txInputs.length; i++) {
        var input = psbtTx.txInputs[i];
        let inputAmount = 0
        if (psbtTx.data.inputs[i].witnessUtxo) {
            inputAmount = psbtTx.data.inputs[i].witnessUtxo.value;
        } else if (psbtTx.data.inputs[i].nonWitnessUtxo) {
            let tx = bitcoin.Transaction.fromHex(psbtTx.data.inputs[i].nonWitnessUtxo.toString('hex'))
            inputAmount = tx.outs[input.index].value;
            console.info(inputAmount)
        }
        let data = b58.decode(wallet.xpub)
        data = data.slice(4)
        data = Buffer.concat([Buffer.from(environment.xpubConst, 'hex'), data])
        wallet.xpub = b58.encode(data);

        if (psbtTx.data.inputs[i].bip32Derivation) {
            let inputPath = getSignerFullPath(wallet.path, psbtTx.data.inputs[i], wallet.xpub);
            let sigEmptyArray = [];
            for (let j = 0; j < wallet.m; j++) {
                sigEmptyArray.push("");
            }
            let tzInput = {
                address_n: inputPath,
                prev_hash: reverseBuffer(psbtTx.txInputs[i].hash).toString("hex"),
                prev_index: psbtTx.txInputs[i].index,
                sequence: psbtTx.txInputs[i].sequence,
                script_type: "SPENDP2SHWITNESS",
                multisig: {
                    m: wallet.m,
                    pubkeys: processPubKeys(wallet.xpub, remoteSigners, psbtTx.data.inputs[i]),
                    signatures: sigEmptyArray
                },
                amount: inputAmount + ""
            }
            trezorInputs.push(tzInput);
        } else {
            let tzInput = {
                prev_hash:reverseBuffer(psbtTx.txInputs[i].hash).toString("hex"),
                prev_index: psbtTx.txInputs[i].index,
                sequence: psbtTx.txInputs[i].sequence,
                // amount: 10000,
                script_type:"EXTERNAL",
                script_sig:scriptSig,
            }

            trezorInputs.push(tzInput);
        }

    }
    
    return trezorInputs

}

function sortKeys(pubkeys, network) {


    pubkeys.sort((a, b) => {
        const xpubA = bitcoin.bip32.fromBase58(a.node, network);
        const xpubB = bitcoin.bip32.fromBase58(b.node, network);
        const pkA = xpubA.derive(a.address_n[0]).derive(a.address_n[1]).publicKey;
        const pkB = xpubB.derive(b.address_n[0]).derive(b.address_n[1]).publicKey;
        return Buffer.compare(pkA, pkB);
    });

    return pubkeys;
}

function processOutputs(psbt: Psbt, wallet, remoteSigners) {
    let outPuts = [];
    console.info(JSON.stringify(psbt.data.outputs))
    for (let i = 0; i < psbt.txOutputs.length; i++) {

        if (psbt.data.outputs[i].bip32Derivation) {
            let inputPath = getSignerFullPath(wallet.path, psbt.data.outputs[i], wallet.xpub);
            outPuts.push({
                address_n: inputPath,
                amount: psbt.txOutputs[i].value + "",
                script_type: getOutputScriptType(bip32path.fromString(wallet.path).toPathArray()),
                // address: psbt.txOutputs[i].address,
                multisig: {
                    m: wallet.m,
                    pubkeys: processPubKeys(wallet.xpub, remoteSigners, psbt.data.outputs[i])
                }
            })
        } else {
            outPuts.push({
                address: psbt.txOutputs[i].address,
                amount: psbt.txOutputs[i].value + "",
                script_type: "PAYTOADDRESS"
            })
        }

    }

    return outPuts;
}

export function getSigningPubkey(wallet: Wallet, path: number[]) {

    if (wallet.type == 'multisig') {
        for (let i = 0; i < wallet.walletKeys.length; i++) {
            if (wallet.walletKeys[i].ismine) {
                let data = b58.decode(wallet.walletKeys[i].xpub)
                data = data.slice(4)
                data = Buffer.concat([Buffer.from(environment.xpubConst, 'hex'), data])
                wallet.walletKeys[i].xpub = b58.encode(data);
                const xpubA = bitcoin.bip32.fromBase58(wallet.walletKeys[i].xpub, environment.btcNetwork);
                console.info("My pubkey", xpubA.toBase58(), xpubA.derive(path[0]).derive(path[1]).publicKey.toString('hex'));
                return xpubA.derive(path[0]).derive(path[1]).publicKey;
            }
        }
    } else if (wallet.type == 'multisig_shield') {
        for (let i = 0; i < wallet.walletKeys.length; i++) {
            if (wallet.walletKeys[i].ismine) {
                
                return Buffer.from( wallet.walletKeys[i].xpub, 'hex');
            }
        }
    }
}

export function getTrezorTx(psbT: string, wallet: Wallet,scriptSig) {
    var psbtBaseText = psbT;
    const signer1 = bitcoin.Psbt.fromHex(psbtBaseText, { network: environment.btcNetwork });

    let m = Number.parseInt(wallet.config.split("of")[0])
    let n = wallet.config.split("of")[1]

    let inputConfig = { m: m, xpub: "", path: "" };
    let remoteSigners = []
    for (let i = 0; i < wallet.walletKeys.length; i++) {
        if (wallet.walletKeys[i].ismine) {
            inputConfig.m = Number(wallet.config.slice(0, 1)),

                inputConfig.xpub = wallet.walletKeys[i].xpub;
            inputConfig.path = wallet.walletKeys[i].path;
        } else {
            let data_b = b58.decode(wallet.walletKeys[i].xpub)
            data_b = data_b.slice(4)
            data_b = Buffer.concat([Buffer.from(environment.xpubConst, 'hex'), data_b])
            // remoteSigners.push(wallet.walletKeys[i].xpub);
            remoteSigners.push(b58.encode(data_b));
        }
    }
    let coin = 'btc'
    //@ts-ignore
    if (environment.env == "dev") {
        coin = 'Testnet';
    }
    var trezorTx = {
        coin: coin,
        "version": signer1.version,
        inputs: processInputs(signer1, inputConfig, remoteSigners,scriptSig),
        outputs: processOutputs(signer1, inputConfig, remoteSigners),
        "locktime": signer1.locktime
    };
    console.info(util.inspect(remoteSigners, { showHidden: false, depth: null }))
    console.info(util.inspect(trezorTx, { showHidden: false, depth: null }))

    return trezorTx;
}


// console.log(signer1.extractTransaction)


// var trezorTx = {
//     coin: "Testnet",
//     "version": signer1.version,
//     inputs: processInputs(signer1, {
//         m: 2,
//         xpub: "tpubDDfS76c9NLz6yBT3CzXBSnvuh93oHqqFtaXF1jR3LsCpMWSVj5Y4mkbWGR32dBTH6hp5KTyWRuSr25DGM4RVTH168m1PyJc5od5o2QSVvn1",
//         path: "m/49'/1'/1'"
//     }, ["tpubDDoRkGYHkP4vRQWnG52xzdxWXCD4p7HGXxJT8GMAN7mxvw7J7G7MNaMLpYutGtgKfrqTLZwR5yF31cu7RVe6X98v56uc12L36sS3rsumVa8"]),
//     outputs: processOutputs(signer1),
//     "locktime": signer1.locktime
// };


// console.log(util.inspect(trezorTx, { showHidden: false, depth: null }))