export interface Members {
    name: string;
    phptoURL: string;
    email: string;
    user_id: number;
}

export interface New_Wallet {
    name: string;
    asset: string;
    m: number;
    group_members: string;
    shield: boolean;
    gas_station: string;
    members_list?: Members[];
}

export interface Wallet {
    wallet: {
        name: string;
        m: number;
        cosigners:string[];
        type: string;
        chain: string;
    }
}

export enum orgUserRequestStatus{
    pending = 0,
    active = 1,
    declined = 2,
    inactive = 3
}

export enum orgType{
    standard = 1,
    management = 2,
    sub = 3,
}

export enum CUSTODY_TYPE {
    NONCUSTODY = 1,
    CUSTODY = 2,
}

export enum UserRoleEnum {
    member = 1,
    admin = 2,
  }
