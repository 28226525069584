import { Component, OnInit, ViewChild } from '@angular/core';
import { WalletserviceService } from '../../shared/services/walletservice.service';
import { Wallet } from '../../shared/entities/wallet';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../shared/services/http.service';
import { first } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {
  wallets: Array<Wallet>;
  policyArray: Array<string>;
  WalletArray: Array<string>;
  policyForm: FormGroup;
  AdminWalletInformation: Wallet;
  ListLoadingflag = false;
  addPolicyStatus = false;
  submitted = false;
  Loadingpolicies = false;
  LoadingWalletInformation = false;
  Loadingusers = false;
  userArray: Array<string>;
  @ViewChild('banDialog') banDialog;
  constructor(private httpService: HttpService, public walletService: WalletserviceService, private fb: FormBuilder, private modalService: NgbModal) {
    this.getAllWalletsForAdmin()
  }
  ngOnInit(): void {
    this.policyForm = this.fb.group({
      walletid: [null, [Validators.required]],
      label: [null, [Validators.required]],
      address: [null, [Validators.required]]
    });
  }
  get f() { return this.policyForm.controls; }//form end
  submit() {
    this.submitted = true;
    if (this.policyForm.invalid) {
      return;
    }
    let walletid = this.policyForm.value.walletid
    let label = this.policyForm.value.label
    let address = this.policyForm.value.address
    this.httpService.addwalletPolicy(walletid, label, address).pipe(first())
      .subscribe(
        data => {

          let res = JSON.parse(JSON.stringify(data))
          if (res.responseCode == 1 || res.responseCode == "1") {
            this.addPolicyStatus = true;
            this.policyForm.reset();
          }
        },
        err=>{
          if(err.error.message === 'This address is blocklisted.'){
            this.modalService.open(this.banDialog, { centered: true });
          }
        }
      )
  }
  getAllWalletsForAdmin() {
    let walletids = Array<Wallet>()
    this.httpService.getAllWalletsForAdmin().pipe(first()).subscribe(allWalletsStr => {
      if (allWalletsStr && allWalletsStr.length > 1) {
        walletids = JSON.parse(JSON.stringify(allWalletsStr));
      }
      this.wallets = walletids;
      this.ListLoadingflag = true;
    })
  }//end
  async getPolicyForAdmin(walletid) {
    this.Loadingpolicies = true
    walletid = Number(walletid.split(":")[1].trim())
    let policies = await (await this.httpService.getPolicyForWallet(walletid)).pipe(first()).subscribe(
      data => {
        this.Loadingpolicies = false;
        if (data) {
          let policies = JSON.stringify(data["result"])
          this.policyArray = JSON.parse(policies)
        }
        return policies;
      }
    );
  }
  //Get wallet information
  async getWalletInformationForAdmin(walletid) {
    this.LoadingWalletInformation = true
    walletid = Number(walletid.split(":")[1].trim())
    let walletInfo = await (await this.httpService.getWalletInformationForAdmin(walletid)).pipe(first()).subscribe(
      data => {
        this.LoadingWalletInformation = false;
        if (data) {
          let walletInfo = JSON.stringify(data["result"])
          this.AdminWalletInformation = JSON.parse(walletInfo)
        }
        return walletInfo;
      }
    );
  }//end
  async getUsersForWallet(walletid) {
    this.Loadingusers = true
    walletid = Number(walletid.split(":")[1].trim())
    let users = await (await this.httpService.getUserInformationForAdmin(walletid)).pipe(first()).subscribe(
      data => {
        this.Loadingusers = false;
        if (data) {
          let users = JSON.stringify(data["result"])
          this.userArray = JSON.parse(users)
        }
        return users;
      }
    );
  }//end
  syncAllWalletsToCourier() {
    this.httpService.SyncwithCourier().pipe(first()).subscribe(data => {
    })
  }//end
}
