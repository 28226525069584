import { Output, TransactionRequest } from '../../../entities';
import { environment } from '../../../../../environments/environment';
import Swal from 'sweetalert2';
import { LedgerService } from '../../ledger/ledger';
import Transportwebusb from "@ledgerhq/hw-transport-webusb";
import Xrp from "@ledgerhq/hw-app-xrp";
import { encode } from 'ripple-binary-codec';
import { Guid } from "guid-typescript";
import { ErrorPrompt } from 'src/app/shared/components/custom-prompt/custom-prompt.service';

async function retrieveSignerData(path, transaction) {
    let Coinname = "XRP";
    let transport = await Transportwebusb.create();
    await LedgerService.openApp(Coinname, transport);
    transport = await LedgerService.getNewTransport();
    const xrp = new Xrp(transport);
    const context = await xrp.getAddress(path);
    console.log(path, context, transaction)
    return signXrpTransaction(path, context, transaction)
        .then(signature => {
            return {
                Signer: {
                    Account: context.address,
                    SigningPubKey: context.publicKey,
                    TxnSignature: signature.toUpperCase()
                }
            }
        });
}

async function signXrpTransaction(path, context, transaction) {
    let Coinname = "XRP";
    let transport = await Transportwebusb.create();
    await LedgerService.openApp(Coinname, transport);
    transport = await LedgerService.getNewTransport();
    const xrp = new Xrp(transport);
    const preparedTransaction = {
        Account: context.address,
        SigningPubKey: context.publicKey.toUpperCase(),
        ...transaction
    };

    const transactionBlob = encode(preparedTransaction);

    console.log('Sending transaction to device for approval...');
    console.log(transactionBlob, path)
    return xrp.signTransaction(path, transactionBlob);

}

export async function signXRPTransaction(output: Output, req: TransactionRequest, type: string) {
    try {
        let txParam: any = {}
        var txJSON;
        console.log(req);
        if (req && 'raw' in req) {
            txParam = JSON.parse(req.raw)
            txJSON = JSON.stringify(txParam.data);
        }
        else {
            txParam = await this.httpService.getTransactionParam({
                reqobj: {
                    nonce: 0,
                    output: output,
                    wallet: this.wallet,
                    type: type.toLowerCase(),
                }

            }).toPromise();
            txJSON = JSON.stringify(txParam.data)
        }

        let m = Number.parseInt(this.wallet.config.split("of")[0])
        let n = this.wallet.config.split("of")[1]
        let myAddress;
        let mypath = environment.xrp_config.XRP.path
        for (let i = 0; i < this.wallet.walletKeys.length; i++) {
            if (this.wallet.walletKeys[i].ismine) {
                myAddress = this.wallet.walletKeys[i].xpub;
                mypath = this.wallet.walletKeys[i].path
                console.info(this.wallet.walletKeys)
            }
        }

        var signtx = await retrieveSignerData(environment.xrp_config.XRP.path || "44'/144'/0'/0/0", JSON.parse(txJSON));

        let Signers: any = [];

        if (req && txParam.sigs) {
            let signers = JSON.parse(JSON.stringify(txParam.sigs))
            signers = JSON.parse(signers)
            console.info(signers)
            for (let i = 0; i < signers.length; i++) {
                Signers.push(signers[i])
            }
            Signers.push(signtx.Signer)
        }
        else {
            if (JSON.parse(JSON.stringify(txParam.sigs)).length < m) {
                Signers.push(signtx.Signer)
            }

        }

        txParam.sigs = JSON.stringify(Signers)
        console.info(txParam)

        if (signtx) {
            txParam.contractTransactionHash = JSON.parse(txJSON).Sequence;
            txParam.sender = signtx.Signer.Account

            txParam.signature = signtx.Signer.SigningPubKey;
            txParam.value = txParam.valueInWei;

            let sequenceId = Guid.create().toString();
            if (req) {
                if ('sequenceId' in req) {
                    sequenceId = req['sequenceId'] as string;
                }
            }

            let apiResult: any = await this.httpService.addNewMultisigTransaction(this.wallet.id, {
                raw: txParam,
                sequenceId: sequenceId,
                pubkey: signtx.Signer.Account,
                destinationAddress: output.address,
                amount: output.value,
                chain: this.wallet.chain,
                asset: this.wallet.coin,
                type: 1,
                memo: output.data,
                comment: output.comment
            }).toPromise();
            apiResult = JSON.parse(JSON.stringify(apiResult))
            if (apiResult?.error) {

                return [{ "error": apiResult?.error }]

            }
            return "success";
        }

    } catch (err) {
        if (err?.name == "TransportOpenUserCancelled") {
            ErrorPrompt.fire({
                // position: 'top-end',
                icon: 'error',
                title: 'Failed to sign transaction!',
                text: "It seems you have not connected your ledger device or please recheck the connection and try again.",
                showConfirmButton: false,
                // timer: 1500
            })
        } else {
            ErrorPrompt.fire({
                // position: 'top-end',
                icon: 'error',
                title: 'Failed to sign transaction!',
                text: this.walletService.setCustomErrorMsg(err?.message),
                showConfirmButton: false,
                // timer: 1500
            })

        }
        this.segment.track("send-asset-transaction-sign-failed", {user: this.authService.getUser, error: err})
        .then(() => console.log("Send asset transaction sign failed")).catch((err)=>{});
        return false;
    }

}