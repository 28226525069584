import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import TrezorConnect, { UI, UI_EVENT, DEVICE_EVENT, Device, RESPONSE_EVENT, TRANSPORT_EVENT, BLOCKCHAIN_EVENT } from 'trezor-connect';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {UAParser} from 'ua-parser-js';

@Component({
  selector: 'app-trezor-webusb',
  templateUrl: './trezor-webusb.component.html',
  styleUrls: ['./trezor-webusb.component.scss']
})
export class TrezorWebUSBComponent implements OnInit {
  public pin: string = "";
  stage = 'trezor_Example';
  state = 'init';
  selected_Wallet = "standard_wallet";
  public passphrase: string = "";
  public confirmPassphrase: string = "";
  show: boolean = false;
  showconf: boolean = false;
  mobile_passphrase_screen = true;
  mobile_device_instruction_screen = false;
  passError = false;
  webUSB = true;
  browser: string;
  @ViewChild("TrezorPin") TrezorPin: ElementRef;
  @ViewChild("TrezorConnect") TrezorConnect: ElementRef;
  modalReference;
  linking: boolean = false;
  constructor(private modalService: NgbModal
  ) {
    this.browser = new UAParser().getBrowser().name;
    if (this.browser == 'Firefox') {
      this.webUSB = false;
    }
    let CONNECT_URL = 'https://connect.lmnl.app/';
    const connectSrc = CONNECT_URL;
    TrezorConnect.init({
      connectSrc,
      popup: !this.webUSB,
      transportReconnect: true,
      debug: true,
      webusb: this.webUSB,
      manifest: {
        email: 'dhruvil@42answers.sg',
        appUrl: 'https://keys.lmnl.app',
      }
    }).then(() => {
      // this.messagelog = this.messagelog + "<br/>" + 'TrezorConnect is ready!';
      // logger.info(this.messagelog, this.messagelog)
      // TrezorConnect.renderWebUSBButton();
    }).catch(error => {
      console.log("Trezor", error);
      // this.messagelog = this.messagelog + "<br/>" + 'TrezorConnect init error:' + error;
      // logger.error(this.messagelog, this.messagelog)
    });

    TrezorConnect.on(DEVICE_EVENT, event => {
      console.log("Trezor Device Event", event);
      // if (event.type === 'device-connect' || event.type == 'device-connect_unacquired') {
      //   document.getElementById("btnpair").style.display = "none";
      // }
      // if (event.type === 'device-disconnect') {
      //   document.getElementById("btnpair").style.display = "block";
      // }
    })

    TrezorConnect.on(UI_EVENT, event => {
      console.log("Trezor UI Event", event);
      if (event.type === UI.SELECT_DEVICE) {
        if (event.payload.devices.length > 0) {
          TrezorConnect.uiResponse({
            type: UI.RECEIVE_DEVICE,
            payload: { device: event.payload.devices[0], remember: false }
          });
        } else {
          // no devices connected, waiting for connection
          this.pairconnect();
          this.stage = 'connect';
          // this.modalService.dismissAll();
          this.modalReference = this.modalService.open(this.TrezorConnect, { windowClass: 'modal-custom-background', centered: true });
        }
      }
      if (event.type === UI.REQUEST_PIN) {
        this.stage = 'pin';
        this.pin = '';
        // this.modalService.dismissAll();
        // this.modalReference.close();
        this.state = 'init';
        this.modalReference = this.modalService.open(this.TrezorPin, { windowClass: 'modal-custom-background', centered: true });
      }
      if (event.type === UI.REQUEST_PASSPHRASE) {
        this.stage = 'trezor_wallets';
        this.linking = false;
        // this.modalService.dismissAll();
        try {
          this.modalReference.close();
        } catch(e){}
        this.passphrase = "";
        this.confirmPassphrase = "";
        this.show = false;
        this.showconf = false;
        this.selected_Wallet = 'standard_wallet';
        this.modalReference = this.modalService.open(this.TrezorPin, { windowClass: 'modal-custom-background', centered: true });
      }
      if (event.type === 'ui-button') {
        this.stage = 'trezor_connect';
        // this.modalService.dismissAll();
        this.modalReference.close();
        this.modalReference = this.modalService.open(this.TrezorPin, { windowClass: 'modal-custom-background', centered: true });
      }
      if (event.type === 'ui-bundle_progress') {
        this.stage = 'exportingKeys';
        // this.modalService.dismissAll();
        if (!this.linking) {
          this.modalReference.close();
          this.modalReference = this.modalService.open(this.TrezorPin, { windowClass: 'modal-custom-background', centered: true });
        }
        this.linking = true;
      }
    })
  }

  ngOnInit(): void {
  }

  async pinsubmit() {
    // this.stage = 'trezor_wallets';
    this.state = 'verifyPin';
    TrezorConnect.uiResponse({ type: UI.RECEIVE_PIN, payload: this.pin });
  }

  async setappend(val) {
    if (this.pin)
      this.pin = this.pin + val;
    else
      this.pin = val;
  }

  removeNumber() {
    this.pin = this.pin.substring(0, this.pin.length - 1);
  }

  async passphrasesubmit() {
    if (this.passphrase === this.confirmPassphrase) {
      this.passError = false;
      TrezorConnect.uiResponse({
        type: UI.RECEIVE_PASSPHRASE,
        payload: { save: false, value: this.passphrase, passphraseOnDevice: false },
      });
      // this.stage = 'trezor_connect';
    } else {
      this.passError = true;
    }
  }

  showPassword(box_val) {
    if (box_val == "passphrase") {
      this.show = !this.show;
    }
    if (box_val == "confirmPassphrase") {
      this.showconf = !this.showconf;
    }
  }

  select_Wallet(selct_wallt) {
    this.selected_Wallet = selct_wallt;
  }

  detect_Wallet() {
    if (this.selected_Wallet == "standard_wallet") {
      TrezorConnect.uiResponse({
        type: UI.RECEIVE_PASSPHRASE,
        payload: { save: false, value: this.passphrase, passphraseOnDevice: false },
      });
      // this.stage = 'trezor_connect';
    }
    if (this.selected_Wallet == "hidden_wallet") {
      this.passphrase = '';
      this.confirmPassphrase = '';
    }
  }

  close_trezor_web_usb() {
    this.modalService.dismissAll();
  }

  async pairconnect() {
    // console.log("Trezor Pairing")
    TrezorConnect.renderWebUSBButton();
  }
}