<div class="d-inline">
    <label class="text-muted font-weight-500">Notes</label>
    <div class="mt-1 text-dark">
        <ul *ngIf="TeamSigningSubType.MPC === selectedSigningSubType" class="default-bullet-list">
            <li class="mb-1">Sign on the go using your iPhone or Android Device.</li>
            <li class="mb-1">Mobile wallets use our MPC technology.</li>
            <li>Best suited if you want to sign/ approve frequently on the go.</li>
        </ul>

    </div>
    <div class="mt-1 text-dark" *ngIf="showNote">
        <ul *ngIf="TeamSigningSubType.MULTISIG === selectedSigningSubType" class="default-bullet-list">
            <li class="mb-1">Sign using Trezor or Ledger hardware wallets.</li>
            <li class="mb-1">Wallets will use multi-sig technology.</li>
            <li>Best suited if transaction frequency is less but volume is high.</li>
        </ul>
    </div>
</div>