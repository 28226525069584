<div class="card">
    <div class="card-header">
        <h5>Add wallet policy</h5>
    </div>
    <div>
        <div class="form-group row">
            <div class="form-group mb-0 m-t-10">
                <button type="submit" (click)="syncAllWalletsToCourier()" class="form-control">Sync All Wallets In
                    courier</button>
            </div>
        </div>
    </div>
    <form [formGroup]="policyForm" class="form theme-form" (ngSubmit)="submit()">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <div class="form-group mb-0">
                            <p *ngIf="!ListLoadingflag"> Loading.....</p>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div id="messagediv">
                            <div *ngIf="addPolicyStatus">
                                <p class="alert alert-success outline alert-dismissible fade show">Policy added
                                    successfully.</p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ListLoadingflag">
                        <div class="form-group row">
                            <select formControlName="walletid" class="form-control"
                                (change)="getPolicyForAdmin($event.target.value);getWalletInformationForAdmin($event.target.value);getUsersForWallet($event.target.value);">
                                <option [ngValue]="null" disabled>Select Wallet</option>
                                <option *ngFor="let wallet of wallets" [ngValue]="wallet.id">{{wallet.name}}</option>
                            </select>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group row" *ngIf="Loadingpolicies">
                                    <P> Loading Policies.......</P>
                                </div>
                                <div class="form-group row" *ngIf="!Loadingpolicies">
                                    <div class="card">
                                        <div class="card-header">
                                            <p class="text-center">Policy List</p>
                                        </div>
                                        <div class="card-body">
                                            <div *ngFor="let policy of policyArray">
                                                <app-address-card [option2]=policy></app-address-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row" *ngIf="LoadingWalletInformation">
                                    <P> Loading Wallet.......</P>
                                </div>
                                <div class="form-group row" *ngIf="!LoadingWalletInformation">
                                    <div class="card">
                                        <div class="card-header">
                                            <p class="text-center">Wallet Information</p>
                                        </div>
                                        <div class="card-body">
                                            <div class="card">
                                                <div class="align-self-center">
                                                    <div *ngIf="!LoadingWalletInformation">
                                                        <span><label>Wallet ID: </label><b>{{ AdminWalletInformation.id
                                                                }}</b></span>
                                                        <br /> <span><label>Wallet Name: </label><b>{{
                                                                AdminWalletInformation.name }}</b></span>
                                                        <br /> <span><label>Wallet Balance: </label><b>{{
                                                                AdminWalletInformation.balance }}</b></span>
                                                        <br /> <span><label>Wallet Type: </label><b>{{
                                                                AdminWalletInformation.type }}</b></span>
                                                        <br /><span><label>Wallet Walletidentifier: </label><b>{{
                                                                AdminWalletInformation.walletidentifier }}</b></span>
                                                        <br /><span><label>Wallet Coin: </label><b>{{
                                                                AdminWalletInformation.coin }}</b></span>
                                                        <br /><span><label>Wallet Chain: </label><b>{{
                                                                AdminWalletInformation.chain }}</b></span>
                                                        <br /><span><label>Wallet Config: </label><b>{{
                                                                AdminWalletInformation.config }}</b></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group row" *ngIf="Loadingpolicies">
                                    <P> Loading Users.......</P>
                                </div>
                                <div class="form-group row" *ngIf="!Loadingpolicies">
                                    <div class="card">
                                        <div class="card-header">
                                            <p class="text-center">User List</p>
                                        </div>
                                        <div class="card-body">
                                            <div *ngFor="let users of userArray">
                                                <app-user-information [user]=users></app-user-information>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label>Label</label>
                            <input type="text" placeholder="label" class="form-control" formControlName="label"
                                [ngClass]="{ 'is-invalid': submitted && f.label.errors }">
                        </div>
                        <div class="form-group row">
                            <label>Address</label>
                            <input type="text" placeholder="address" class="form-control" formControlName="address"
                                [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                        </div>
                        <div class="form-group row">
                            <div class="form-group mb-0 m-t-10">
                                <button type="submit" class="form-control">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <ng-template #banDialog let-modal>

        <div class="modal-body">
            <div style="height: 250px;" class="mb-3 d-flex justify-content-center flex-column align-items-center w-100">
                <img class="w-50" src="../../../assets/images/error.svg">
            </div>
            <div class="mb-5 text-center">
                <h5>Transaction Blocked: Blacklisted Address</h5>
                <div class="px-4 my-4">
                    Transaction cannot be processed because the address is blacklisted. Please contact the wallet owner.
                    For assistance or concern, please contact our support team.
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="modal.dismiss()">I understand</button>
        </div>
    </ng-template>
</div>