<div class="modal-header px-4">
    <div class="d-flex flex-row align-items-center">
        <i *ngIf="showBackButton" class="icon-arrow-left mr-3 back" (click)="backClick()"></i>
        <span class="modal-title font-size-16 font-text-dark">
            <i class="icon-wallet mr-2"></i>{{title}}
        </span>
    </div>
    <button type="button" aria-label="Close" class="close" (click)="onModalCloseClick()">
        <i class="icon-close"></i>
    </button>
</div>