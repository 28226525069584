import { error, Output, PreBuildTx, SignedTransaction, TransactionRequest } from '../../../entities';
import { u8aToHex } from '../polkadotUtils';
import { Guid } from "guid-typescript";
import Swal from 'sweetalert2';
import { ErrorMessage } from '../../../helpers/errorHelper';
import { LedgerService } from '../../ledger/ledger';
import { ErrorPrompt } from 'src/app/shared/components/custom-prompt/custom-prompt.service';

export async function signDotTransaction(output: Output, req: TransactionRequest, type: string) {
    try {

        let transport = await LedgerService.getNewTransport();
        var txid;
        if ((req && req['actionType'] == 'travel-rule') || !req) {
            var respData: PreBuildTx = await this.httpService.getPrebuildTx({
                reqobj:
                {
                    nonce: 0,
                    output: output,
                    wallet: this.wallet,
                    type: type.toLowerCase(),
                }
            })
            this.logger.info("DOT prebuild tx response " + respData);
            await transport.close();
            const signData: any | error[] = await this.signDot(respData.txDataResponse);
            this.logger.info("DOT sign data " + respData);
            if (signData[0]?.error || signData.error_message.toLowerCase() != "No errors".toLowerCase()) {
                if (signData.error_message && signData.error_message.toLowerCase() != "No errors".toLowerCase()) {
                    return [{ "error": signData.error_message }];
                }
                return signData;
            }

            const signatureData = u8aToHex(signData.signature)
            this.logger.info("DOT sign convert to hex " + signatureData);
            let sequenceId = Guid.create().toString();
            if (req) {
                if ('sequenceId' in req) {
                  sequenceId = req['sequenceId'] as string;
                }
            }
            let tx = {
                destinationAddress: respData.txOutputs.destinationAddress,
                data: {
                    "chain": "DOT",
                    "tx_method": respData?.txJson?.raw_data.method,
                    "raw_data": respData?.txJson?.raw_data,
                    "signature": signatureData,
                },
                amount: respData.txOutputs.destinationAmount,
            }
            const signTransactionResponse: SignedTransaction = {
                txHex: signatureData,
                halfSigned: {
                    payload: JSON.stringify(tx),
                    txBase64: "",
                    txHex: signatureData,
                    sequenceId: sequenceId
                },
                comment: output.comment
            };
            this.logger.info("DOT signTransactionResponse with half sign obj " + signTransactionResponse);
            var sendresult = await this.httpService.SendTransaction(this.wallet.id, signTransactionResponse);
            sendresult = (sendresult)
            this.logger.info("DOT send tx response " + sendresult);
            if (sendresult.status !== 200) {
                return [{ "error": sendresult }]
            }
            return "success";
        } else {
            let objwalletkey = this.wallet.walletKeys.filter(item => item.ismine === true);
            txid = req.id;
            const getRespApprove: any = await this.httpService.getApproveTx(this.wallet.id, txid);
            this.logger.info("DOT get approve response " + getRespApprove)

            const signData: any | error[] = await this.signDot(getRespApprove.data.data.txDataResponse);
            this.logger.info("DOT sign data " + signData)
            if (signData[0]?.error || signData.error_message.toLowerCase() != "No errors".toLowerCase()) {
                if (signData.error_message && signData.error_message.toLowerCase() != "No errors".toLowerCase()) {
                    return [{ "error": signData.error_message }];
                }
                return signData;
            }
            const approveSignatureData = u8aToHex(signData.signature);
            this.logger.info("DOT sign convert to hex " + approveSignatureData)
            let data = {
                "chain": "DOT",
                "tx_method": getRespApprove?.data?.data?.txJson?.raw_data.method,
                "raw_data": getRespApprove?.data?.data?.txJson?.raw_data,
                "signature": approveSignatureData,
            }
            console.log('data approve', data);
            let bodySave = {
                "sig": approveSignatureData,
                "rawTx": data,
                "pubkey": objwalletkey[0].xpub
            }
            var respApprove = await this.httpService.ApproveTx(JSON.stringify(bodySave), this.wallet.id, txid);
            this.logger.info("DOT approve tx response " + respApprove);
            if (respApprove.status !== 200) {
                return [{ "error": respApprove }]
            }
            return "success";
        }
    }
    catch (err) {
        if (err.message == "Ledger device: UNKNOWN_ERROR (0x6a8d)") {
            ErrorPrompt.fire({
                // position: 'top-end',
                icon: 'error',
                title: 'Failed to sign transaction!',
                text: "Allow unverified contracts from Ledger app setting.",
                showConfirmButton: false,
                // timer: 1500
            })
           
        }
        else if (err.name == "TransportOpenUserCancelled") {

            ErrorPrompt.fire({
                // position: 'top-end',
                icon: 'error',
                title: 'Failed to sign transaction!',
                text: "It seems you have not connected your ledger device or please recheck the connection and try again.",
                showConfirmButton: false,
                // timer: 1500
            })
            
          
        } else {
            let errorMessage: string = ErrorMessage(err);
            console.log('errorMessage', errorMessage);
            ErrorPrompt.fire({
                // position: 'top-end',
                icon: 'error',
                title: 'Failed to sign transaction!',
                text: this.walletService.setCustomErrorMsg(errorMessage),
                showConfirmButton: false,
                // timer: 1500
            })
            
        }
        this.segment.track("send-asset-transaction-sign-failed", {user: this.authService.getUser, error: err})
        .then(() => console.log("Send asset transaction sign failed")).catch((err)=>{});
    }
}
