import TrezorConnect from 'trezor-connect';
import { environment } from 'src/environments/environment';

export type Coins = "btc" | "test" | "ltc" | "bch" | "doge";

const CoinNames = {
    btc: {
        coinName: "btc",
        fullname: "bitcoin",
        coinPath: function () {
            if (environment.env == "dev") {
                this.coinName = 'test';
                return 1;
            }
            return 0;
        },
    },
    doge: {
        coinName: "doge",
        fullname: "doge",
        coinPath: () => 3,
    },
    ltc: {
        coinName: "ltc",
        fullname: "ltc",
        coinPath: () => 2,
    },
    bch: {
        coinName: "bch",
        fullname: "bch",
        coinPath: () => 145,
    }
}

export class Coin {
    coinName: Coins;
    fullname;
    coinPath;
    constructor(coinName) {
        console.info("creating coin object for", coinName);
        this.coinName = CoinNames[coinName].coinName;
        this.fullname = CoinNames[coinName].fullname;
        this.coinPath = CoinNames[coinName].coinPath.call(this);
    }
    capitalize() {
        const strParts = this.fullname.split[" "];
        const finalStr = [];
        if (strParts) {
            strParts.forEach((part) => {
                finalStr.push(part[0].toUpperCase() + part.substring(1))
            });
            return finalStr.join(" ");
        } else {
            console.info("No string parts", this);
        }
        return "";
    }
}

const Bundles = {
    bch: (coinPath, coin) => [{
        path: "m/44'/" + coinPath + "'/0'",
        coin: coin
    }],
    default: (coinPath, coin) => [{
        path: "m/49'/" + coinPath + "'/0'",
        coin: coin
    }, {
        path: "m/49'/" + coinPath + "'/1'",
        coin: coin
    }, {
        path: "m/49'/" + coinPath + "'/2'",
        coin: coin
    }, {
        path: "m/44'/" + coinPath + "'/0'",
        coin: coin
    }, {
        path: "m/48'/" + coinPath + "'/0'/1'",
        coin: coin
    }, {
        path: "m/48'/" + coinPath + "'/0'/2'",
        coin: coin
    }, {
        path: "m/44'/" + coinPath + "'/0'",
        coin: coin
    }, {
        path: "m/84'/" + coinPath + "'/0'",
        coin: coin
    }
    ]
}

export default function getPublicKey(coinPath: number, coin: Coins) {
    return TrezorConnect.getPublicKey({
        bundle: Bundles[coin] ? Bundles[coin](coinPath, coin) : Bundles.default(coinPath, coin),
    });
}