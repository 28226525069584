import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Wallet } from '../../entities';
import { WalletCategory, getWalletType, isV2MultisigWallet } from '../../helpers/WalletUtils';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-firewall-warning',
  templateUrl: './firewall-warning.component.html',
  styleUrls: ['./firewall-warning.component.scss']
})
export class FirewallWarningComponent implements OnInit, OnDestroy {

  DOC_URL = 'https://support.liminalcustody.com/en/support/solutions/articles/1060000079490-introducing-liminal-firewall-to-elevate-digital-asset-security-';

  @Input() wallet: Wallet;
  @Input() displayWarning: boolean = false;
  subscriber: Subscription;

  constructor(private data: DataService) { 
    this.subscriber = new Subscription();;
  }

  ngOnInit(): void {
    if (!this.displayWarning) {
      this.subscriber.add(this.data.getUserProfile.subscribe((data) => {
        const { organizations } = data;
        const org = organizations[0];
        const isFirewallEnabled = org.isFirewallEnabled;
        if (this.wallet && isFirewallEnabled) {
          const walletType = getWalletType(this.wallet.type);
          const isVersion2 = isV2MultisigWallet(this.wallet);
          this.displayWarning = walletType === WalletCategory.Multisig && !isVersion2;
        }
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
