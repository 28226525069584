export const SuccessPromptPayload = {
  INVITE_MEMBER: {
    title: "Success",
    text: "Awesome! You have successfully invited members to this organization 🎉",
    headerIconClass: "icon-add-member",
    header: "Invite Member",
    confirmButtonText: "Done",
  },
  SPEED_UP_TXN: (textMessage: string) => ({
    title: "Success",
    text: textMessage,
    confirmButtonText: "Done",
  }),
  USER_ROLE_CHANGE: {
    title: "Success",
    text: "Awesome! You have successfully changed the user type 🎉",
    confirmButtonText: "Done",
  },
  TEAM_ADDED: {
    title: "Success",
    text: "Awesome! You have successfully added the team 🎉",
    confirmButtonText: "Done",
  },
  LIVENESS_COMPLETED: {
    title: "Success",
    text: "We have received your transaction request. Transaction is still pending and will continue to Custodian Approval.",
    confirmButtonText: "Done",
  }
};

export const ErrorPromptPayload = {
  SAMPLE: {
    title: "Failed to archive wallet",
    text: "Some error ocurred while archiving wallet",
  },
  JWT_EXPIRED: {
    title: "Session Expired",
    text: "Your session has expired. Please log in again to continue",
  },
  SPEED_UP_TXN_FAILED: (textMessage: string) => ({
    title: "Failed to create speed up transaction",
    text: textMessage,
  }),
  TEAM_ADD_FAILED: {
    title: "Oops...",
    text: "Some error ocurred , please try again !",
    confirmButtonText: "Try again"
  }
};
