<div>
    <div class="modal-custom" *ngIf="status != 'success' && status != 'error'">
        <div class="modal-header px-4">
            <span class="modal-title font-size-16">
                Add Team
            </span>
            <button type="button" class="close" aria-label="Close" (click)="resetAndCloseModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body p-4">
            <form [formGroup]="addTeamForm">
                <div class="d-flex flex-column">
                    <div class="mb-4">
                        <span class="pb-3 mb-1 text-muted font-weight-500">Team</span>
                        <input class="form-control rounded-sm mt-2" formControlName="teamName"
                            placeholder="Enter team name" type="text"
                            [title]="(isControlInvalidAndTouched('teamName') && !isInvalidPattern() && 'Team name can not be empty') || (isInvalidPattern() && 'Invalid format. Only alphanumeric characters are allowed.')">

                        <!-- Display validation error message if the control is invalid and touched -->
                        <div *ngIf="isControlInvalidAndTouched('teamName') && !isInvalidPattern()"
                            class="text-danger mt-1">
                            Team name is required.
                        </div>
                        <div *ngIf="isInvalidPattern()" class="text-danger mt-1">
                            Please enter a valid team name.
                        </div>
                    </div>

                    <div class="mb-4" *ngIf="!isCustodyOrg()">
                        <span class="pb-2 mb-1 text-muted font-weight-500">Can This Team Sign Transactions?</span>
                        <div class="form-group mt-2 m-checkbox-inline mb-0 custom-radio-ml ml-2">
                            <div class="radio radio-primary">
                                <input formControlName="teamSigningType" type="radio" id="teamSignYes"
                                    name="signing-type" [value]="TeamSigningType.SIGNING">
                                <label class="mb-0" for="teamSignYes">Yes</label>
                            </div>
                            <div class="radio radio-primary">
                                <input formControlName="teamSigningType" type="radio" id="teamSignNo"
                                    name="signing-type" [value]="TeamSigningType.NON_SIGNING">
                                <label class="mb-0" for="teamSignNo">No</label>
                            </div>
                        </div>
                        <div *ngIf="isControlInvalidAndTouched('teamSigningType')" class="text-danger mt-1">
                            Please select the signing type.
                        </div>
                    </div>

                    <div class="mb-4" *ngIf="isSigningTypeSigning">
                        <span class="pb-2 mb-1 text-muted font-weight-500">Signing Device</span>
                        <div class="form-group mt-2 m-checkbox-inline mb-0 custom-radio-ml ml-2">
                            <div class="radio radio-primary">
                                <input formControlName="teamSigningSubType" type="radio" name="signing-sub-type"
                                    [value]="TeamSigningSubType.MPC" id="mobile">
                                <label class="mb-0" for="mobile">Mobile</label>
                            </div>
                            <div class="radio radio-primary">
                                <input formControlName="teamSigningSubType" type="radio" name="signing-sub-type"
                                    [value]="TeamSigningSubType.MULTISIG" id="hardware" disabled>
                                <label class="mb-0" for="hardware">Hardware Device</label>
                            </div>

                        </div>
                        <div *ngIf="isControlInvalidAndTouched('teamSigningSubType')" class="text-danger mt-1">
                            Please select the signing device.
                        </div>
                    </div>

                    <div class="mb-4 mt-1">
                        <div class="d-flex mb-2">
                            <span class="text-muted font-weight-500">Select Members</span>
                            <div class="d-inline float-right" style="margin-left: auto;">
                                <span class="text-muted font-weight-500" href="#">{{ getSelectedCount()?.length > 0 ?
                                    getSelectedCount()?.length +
                                    '
                                    Selected':
                                    ''}}</span>
                            </div>
                        </div>
                        <div class="mb-3">
                            <ng-multiselect-dropdown [settings]="searchDropdownSettings" name="Initiator"
                                placeholder="Select Members" [data]="filteredMembers" formControlName="selectedMembers">
                                <ng-template #optionsTemplate let-item let-option="option" let-id="id"
                                    let-isSelected="isSelected" class="d-flex ms-2">
                                    <div class="d-flex" style="padding:4px 10px !important;"
                                        [style]="isSelected?'display:none !important':''">
                                        <div class="d-flex align-items-center box_Circle_Qr p-1">
                                            <img class="img-fluid for-light rounded-circle user-image "
                                                [src]="getMembers[id].phptoURL" alt="">
                                        </div>
                                        <div class="details d-flex flex-column ml-1">
                                            <div class="text-left p-0 mb-auto mt-auto">
                                                <span #name class="m-0 truncate_Name">{{ getMembers[id].displayName}}</span>
                                            </div>
                                        </div>
                                    </div>

                                </ng-template>
                                <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                                    <div class="d-flex">
                                        <div class="d-flex align-items-center box_Circle_Qr">
                                            <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2"
                                                [src]="getMembers[id].phptoURL" alt="">
                                        </div>
                                        <div class="details d-flex align-items-center">
                                            <div class="col text-left p-0">
                                                <div #name class="m-0 truncate_Name" [ngbTooltip]="option">{{ option }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-multiselect-dropdown>
                            <div *ngIf="isControlInvalidAndTouched('selectedMembers')" class="text-danger mt-1">
                                Please select a minimum of one member.
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <ng-container>
            <div class="modal-footer border-0">
                <button type="submit" (click)="!isLoading && submitTeam()" [disabled]="isLoading"
                    class="btn btn-primary w-100">Add Team</button>
            </div>
        </ng-container>
    </div>

</div>