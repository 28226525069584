import * as bip32path from 'bip32-path'
import { Wallet } from '../../entities/wallet'
import { Coin } from '../../entities/Coins'
import TrezorConnect from 'trezor-connect';
import { Output } from '../../entities/Output';
import { TransactionRequest, txType } from '../../entities/TransactionRequest';
import { getOutputScriptType, getScriptType } from '../pathUtils'
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import Web3 from "web3";
import BN from 'bignumber.js'
import { LoggerService } from '../../services/logger.service';
import { environment } from 'src/environments/environment';
import { getParentChain } from '../WalletUtils'
import { AuthServiceJWT } from '../../services/auth.service';
import { error } from '../../entities/cosmosTransaction';
import { newPolkadotApp } from '@zondax/ledger-substrate';
import { LedgerService } from '../ledger/ledger';
import { signEthGnosisOfflineNew, signSolTransaction, signMultisigTransaction, signXRPTransaction, signTronTransaction, signCosmosTransaction, signCosmos, signDotTransaction, signXLMTransaction } from "./signers";
import { SegmentService } from 'ngx-segment-analytics';
import { WalletserviceService } from '../../services/walletservice.service';

export class ComposeTransaction {
  // public segment: SegmentService;
  // public authService: AuthServiceJWT;
  txPsbt: string = ''
  chainName: string = '';

  constructor(private wallet: Wallet, private data: DataService,
     private httpService: HttpService, private logger: LoggerService,
     private segment: SegmentService, private authService: AuthServiceJWT, private walletService: WalletserviceService) {
    //await TrezorConnect.renderWebUSBButton();
  }
  signCosmos = signCosmos;
  async sendTransaction(output: Output, coins: Coin[], txReq: TransactionRequest, type?: string, comment?: string): Promise<any> {
    // console.log("Parent Chain", getParentChain(this.wallet.chain));
    if (getParentChain(this.wallet.chain) == "EVM") {
      return await signEthGnosisOfflineNew.call(this, output, txReq, type);
    } else if (this.wallet.parentchain.toLowerCase() == "utxo") {
      return await signMultisigTransaction.call(this, output, txReq);
    } else if (getParentChain(this.wallet.chain) == "XRPDROP") {
      return await signXRPTransaction.call(this, output, txReq, type)
    }
    else if (getParentChain(this.wallet.chain) == "TRON") {
      return await signTronTransaction.call(this, output, txReq, type)
    }
    else if (getParentChain(this.wallet.chain) == "COSMOS") {
      return await signCosmosTransaction.call(this, output, txReq, type)
    }
    else if (getParentChain(this.wallet.chain) == "DOT") {
      return await signDotTransaction.call(this, output, txReq, type)
    }
    else if (getParentChain(this.wallet.chain) == "SOL") {
      return await signSolTransaction.call(this, output, txReq, type)
    }
    else if (getParentChain(this.wallet.chain) == "XLM") {
      return await signXLMTransaction.call(this, output, txReq, type)
    }
    return null;
  }

  async walletconnectsignTx(txs, req: TransactionRequest, payload_method: string, type: string = txType.Normal, payloadData) {
    try {
      let web13 = new Web3(new Web3.providers.HttpProvider(environment.evm_config[this.wallet.chain].web3http))
      let amtdecimal = new BN(txs.value).dividedBy(new BN(10).pow(18)).toFixed()
      let output: Output = {
        address: web13.utils.toChecksumAddress(txs.to),
        value: Number(amtdecimal),
        data: payloadData?.params[0]?.data
      }

      let skipTravelRule = true;

      return signEthGnosisOfflineNew.call(this, output, req, type, skipTravelRule)
    } catch (e) {
      console.error("e-----------", e);
      this.logger.error(e);
      return [{ error: e }]
    }
  }

  async signTransaction(coinShort: string, coins: Coin[], address: string, amount: number) {
    let inputs: any = []

    for (let i = 0; i < coins.length; i++) {
      let pathWallet = this.wallet.path;
      let coinPath = pathWallet + "" + coins[i].address_path.replace("m", "");
      coins[i].address_path = coinPath;
      inputs.push({
        address_n: bip32path.fromString(coinPath).toPathArray(),
        amount: coins[i].value + "",
        prev_index: coins[i].prevout_n,
        prev_hash: coins[i].prevout_hash,
        script_type: getScriptType(bip32path.fromString(this.wallet.path).toPathArray()),
      })

    }
    // console.log(inputs,bip32path.fromString("m/48'/0/0/0").toPathArray())

    let changeAmount: number = this.getChangeAmount(coins, amount);
    let txResult;
    txResult = await TrezorConnect.signTransaction({
      coin: coinShort,
      inputs: inputs,
      outputs: [
        {
          address: address,
          amount: amount + "",
          script_type: "PAYTOADDRESS"
        },
        {
          address_n: bip32path.fromString(this.wallet.path + "/1/0").toPathArray(),
          script_type: getOutputScriptType(bip32path.fromString(this.wallet.path).toPathArray()),
          //script_type: "PAYTOADDRESS",
          amount: changeAmount + ""
        }
      ],
      push: true
    })

    if (txResult.success) {
      setTimeout(() => {
        this.data.changeMessage(this.wallet.id)
        window.open('https://live.blockcypher.com/btc-testnet/tx/' + txResult.payload.txid, "_blank");
      }, 5000);

    } else {
      alert(JSON.stringify(txResult.payload))
    }

  }

  async signDot(tx_obj: ArrayLike<number> | { [s: string]: number; }): Promise<any | error[]> {
    let CoinName = "Polkadot";
    let transport = await LedgerService.getNewTransport();
    let currentapp = await LedgerService.getCurrentApp(transport);
    console.log('currentapp', currentapp);
    if (currentapp.name != CoinName) {
      return [{ "error": "please Open Polkadot App on Ledger and Try Again" }];
    }
    const polkaapp = newPolkadotApp(transport);
    let t01 = new Uint8Array(Object.values(tx_obj));
    const sign = await polkaapp.sign(environment.dot_config.DOT.LEDGER_DEFAULT_ACCOUNT, environment.dot_config.DOT.LEDGER_DEFAULT_CHANGE, environment.dot_config.DOT.LEDGER_DEFAULT_INDEX, Buffer.from(t01));
    this.logger.info("DOT sign " + sign)
    return sign;

  }

  getChangeAmount(coins: Coin[], amount: number, fee: number = 100000) {
    let coinTotal = 0
    for (let i = 0; i < coins.length; i++) {
      coinTotal = coins[i].value + coinTotal;
    }
    return coinTotal - amount - fee;
  }

  delay = ms => new Promise(res => setTimeout(res, ms));
}
