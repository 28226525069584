import { Directive, ElementRef, OnDestroy, OnInit, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[supportLink]',
  providers: []
})
export class SupportLinkDirective implements OnInit, OnDestroy {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { 
  }

  ngOnInit() {
    this.setSupportLink(environment.auth0SupportUrl)
  }

  ngOnDestroy() {
  }

  private setSupportLink(link: string) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'href', link);
  }
}
