import { Injectable } from "@angular/core";
import { WalletConnectTransaction } from "./wallet-connect.utils";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class WalletConnectStorageService {
  private supportLink = environment.auth0SupportUrl;
  private _pendingTxnsToBroadcast: BehaviorSubject<WalletConnectTransaction[]> =
    new BehaviorSubject<WalletConnectTransaction[]>([]);

  // Expose the observable part of the BehaviorSubject using `asObservable()`
  public pendingTxnsToBroadcast$ = this._pendingTxnsToBroadcast.asObservable();

  private storagekey = "walletConnectPendingTransactions";

  constructor() {
    // Retrieve data from local storage on service initialization and update the BehaviorSubject
    const storedData = this.getArrayFromLocalStorage();
    this._pendingTxnsToBroadcast.next(storedData);
  }

  // Get the array from local storage
  getArrayFromLocalStorage(): WalletConnectTransaction[] {
    const storedData = localStorage.getItem(this.storagekey);
    return storedData ? JSON.parse(storedData) : [];
  }

  // Set the array to local storage
  setArrayToLocalStorage(array: WalletConnectTransaction[]): void {
    localStorage.setItem(this.storagekey, JSON.stringify(array));
    this._pendingTxnsToBroadcast.next(array); // Update the BehaviorSubject with the new data
  }

  // Add an item to the array in local storage
  addItemToArray(item: WalletConnectTransaction): void {
    const storedArray = this.getArrayFromLocalStorage();
    storedArray.push(item);
    this.setArrayToLocalStorage(storedArray); // Save the updated array to local storage and update the BehaviorSubject
  }

  // Remove an item from the array in local storage
  removeItemFromArray(itemToRemove: WalletConnectTransaction): void {
    const storedArray = this.getArrayFromLocalStorage();
    const filteredArray = storedArray.filter(
      (item) => item.walletConnect.id !== itemToRemove.walletConnect.id
    );

    this.setArrayToLocalStorage(filteredArray); // Save the updated array to local storage and update the BehaviorSubject
  }

  // Clear the array from local storage
  clearArrayFromLocalStorage(): void {
    localStorage.removeItem(this.storagekey);
    this._pendingTxnsToBroadcast.next([]); // Update the BehaviorSubject with an empty array
  }
}
