import { Component, Input, OnInit } from '@angular/core';
import { isMultiSigCustody } from '../../shared/helpers/org.utils';

@Component({
  selector: 'app-wallet-create-selector',
  templateUrl: './wallet-create-selector.component.html',
  styleUrls: ['./wallet-create-selector.component.scss']
})
export class WalletCreateSelectorComponent implements OnInit {

  @Input() orgProfile: any; 
  @Input() component_for: any;

  isMultiSigCustody = isMultiSigCustody;
 
  constructor() { }

  ngOnInit(): void {
  }

}
