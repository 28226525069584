import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { signMessage } from "../helpers/trezor/messageSigner";
import { AuthServiceJWT } from './auth.service';
import { Guid } from "guid-typescript";
import { parseNumber } from '../helpers/general';
import { ErrorMsgs, NoShowError } from '@entities/Error';
@Injectable({
    providedIn: 'root'
})
//  check here for the coin lower case
export class SendTeamTxService {
    constructor(private HttpService: HttpService, private auth: AuthServiceJWT) { }

    async mpcTransactionSign(selectedAsset, selectedAddress, amount, memo, selectedWallet, comment, _data?, txReq?): Promise<any> {
        let data = ""
        let unixTimeStamp = new Date().getTime()
        let messageToSignApi = `${amount}|${selectedAddress}|${selectedAsset.coin}|${data}`;
        let messageSign = `Initiator|${messageToSignApi}|${unixTimeStamp}`;
        let jsonData = null;
        try {
            jsonData = JSON.parse(_data);
        } catch (e) { }
        try {
            if (_data) {
                data = _data;
            }
            if(memo) {
                data = memo;
            }
            let body: any = {
                amount: parseNumber(String(amount)),
                destinationAddress: selectedAddress,
                coin: selectedAsset.coin,
                comment: comment,
                data: data,
                messageToSign: messageToSignApi,
                signature: "0x",
                timestamp: unixTimeStamp,
                isInternal: jsonData ? jsonData.isInternal : false  // Used to bypass travelrule
            }
            console.log('body',body);
            // if (txReq?.actionType === 'travel-rule' && txReq.sequenceId) {
            //     body.sequenceId = txReq.sequenceId;
            // }
            let sequenceId = Guid.create().toString();
            body.sequenceId = sequenceId;
            if (txReq?.actionType === 'travel-rule' && txReq.sequenceId) {
                body.sequenceId = txReq.sequenceId;
            }
            return new Promise((resolve, reject) => {
                this.auth.writeAccess("wallet:tx-create").subscribe((accessToken) => {
                    this.HttpService.sendTeamsTxn(selectedWallet.id, body, accessToken)
                        .toPromise()
                        .then((result) => {
                            resolve(result);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }, (err) => {
                    if (err.message === ErrorMsgs.POPUP_CLOSED)
                        return reject(new NoShowError("User denied transaction signing", err));
                    reject(err);
                });
            });
        } catch (e) {
            console.error("errorr are----", e);
            return Promise.reject({ success: false, message: "message signing failed" });
        }

    }

    async initiatorApproveSign(trpId, messageSign, deviceName): Promise<any> {
        let unixTimeStamp = new Date().getTime()
        messageSign = `Initiator|${messageSign}|${unixTimeStamp}`
        try {

            let body = {
                trpId: trpId,
                signature: "0x",
                timestamp: unixTimeStamp
            }
            return new Promise((resolve, reject) => {
                this.auth.writeAccess("team:tx-approve").subscribe((accessToken) => {
                    this.HttpService.approveInitiatorTx(body, accessToken)
                        .toPromise()
                        .then((result) => {
                            resolve(result);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            });
        } catch (e) {
            return Promise.reject({ success: false, message: "message signing failed" });
        }

    }

    //  check here for the coin lower case

    async gasStationSign(selectedAsset, selectedAddress, amount, deviceName, selectedWallet, comment): Promise<any> {
        let data = ""
        let unixTimeStamp = new Date().getTime()
        let messageToSignApi = `${amount}|${selectedAddress}|${selectedAsset.coin}|${data}`
        let messageSign = `Gas-Station|${messageToSignApi}|${unixTimeStamp}`
        try {
            let body = {
                amount: amount,
                destinationAddress: selectedAddress,
                coin: selectedAsset.coin,
                comment: comment,
                data: data,
                messageToSign: messageToSignApi,
                signature: "0x",
                timestamp: unixTimeStamp
            }

            return new Promise((resolve, reject) => {
                this.auth.writeAccess("gasstation:tx-create").subscribe((accessToken) => {
                    this.HttpService.sendGasStationTxn(selectedWallet.id, body, accessToken)
                        .toPromise()
                        .then((result) => {
                            resolve(result);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            });
        } catch (e) {
            return Promise.reject({ success: false, message: "message signing failed" });
        }

    }

    //  check here for the coin lower case


    async signEthereumMessage(device, message) {
        console.log("device name -- message -->", device, message)
        return await signMessage(device, message);
    }

}