<button appDisableClick *ngIf="component_for === 'verify-liveness-check'" class="btn btn-primary-light" type="submit"
  (click)="showLivenessCheckModal(livenessKycCheck);launchWebSdk()">Verify Now</button>

<ng-template #livenessKycCheck let-modal>
  <div class="modal-header">
    <h2 class="modal-title"><i class="icon-key mr-2"></i>{{ headerTitle }}</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i
        class="icon-close"></i> </button>
  </div>
  <div class="modal-body" style="min-height: 300px;">
    <div id="sumsub-websdk-container"></div>
  </div>
</ng-template>