import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { LoggerService } from './logger.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthServiceJWT } from './auth.service';
import { Router } from '@angular/router';

const JWTMsgs = ["jwt expired", "jwt malformed", "secret or public key must be provided"];

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private logger: LoggerService,
    public auth: AuthServiceJWT,
    private router: Router,
    private zone: NgZone
  ) { }

  handleError(error: any) {
    console.error("logging from global error handler", error);
    error = error.rejection; // get the error object
    if (error instanceof HttpErrorResponse) {
      error = { message: error.error.message || error.error.code }
    }
    if (!error) {
      return;
    }
    if (error.message == "Organization is Inactive") {
      this.router.navigate(['/access-denied'], { queryParams: { errorMsg: error.message } });
      return;
    }
    if (JWTMsgs.includes(error.message)) {
      this.auth.SignOut();
      return;
    }

    // this.zone.run(() =>
    //   this.errorDialogService.openDialog(
    //     error?.message || 'Undefined client error',
    //     error?.status
    //   )
    // );
  }
}