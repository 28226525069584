import Eth from '@ledgerhq/hw-app-eth';
import { PubKeys } from '../../entities/Pubkey';
import { getNewWebUSBTransport as getNewTransport } from "./getTransports";
import { ledgerLinkErrorHandler } from "./linkError";
import { getCurrentApp } from "./getLedgerApp";

export async function linkETHLedger() {
  this.ng_eth = false;
  this.linking_Ledger_Error = true;
  this.linking_Ledger_Message = "Device link in progress..Please wait";
  let transport = await getNewTransport();
  try {
    const eth = new Eth(transport);
    const result = await eth.getAddress("44'/60'/0'/0/0");
    console.log("result######", result);

    let address = result.address;
    if (result) {
      this.linking_eth = false;
      this.linked_coin.push("eth");
      let wallet: PubKeys = { xpub: "", path: "", id: "", type: "", name: "", config: "" };
      wallet.xpub = address;
      wallet.path = "m/44'/60'/0'/0/0";
      wallet.id = address;
      wallet.coin = "ETH"
      wallet.type = "address"
      wallet.provider = "ledger"
      // console.log(wallet)
      await this.httpService.saveKey(wallet, 'ledger').toPromise()
    } else {
      this.coin_name = "ETH";
      this.error_message(this.coin_name);
    }
    this.segment.track("link-device-eth-success", this.authService.getUser)
      .then(() => console.log("Link Device ETH Success")).catch((err)=>{});
  
  }
  catch (err) {
    this.step1 = true;
    this.step2 = false;
    ledgerLinkErrorHandler.call(this, err, 'eth');
  }
  //this needs to be changed for the last coin to be linked
  let currentapp = await getCurrentApp(transport);
  if (currentapp.name != "BOLOS") {
    //Exit app
    await transport.send(0xb0, 0xa7, 0x00, 0x00);
    await this.delay(2000);
  }
  this.ledger_linking_eth = false;
  this.linking_Ledger_Error = false;
};