import { User } from "./User";

/**
 * @description Teams signing type enum
 * @enum {number}
 * NON_SIGNING = 1,
 * SIGNING = 2
 */
export enum TeamSigningType {
    NON_SIGNING = 1,
    SIGNING = 2,
}

/**
 * @description Teams signing sub type enum
 * @enum {number}
 * MPC = 1,
 * MULTISIG = 2
 */
export enum TeamSigningSubType {
    MPC = 1,
    MULTISIG = 2,
}

export interface TeamMember {
    id: number;
    teamId: number;
    userId: number;
    user: User
}

export interface TeamsV2 {
    id: number;
    name: string;
    type: TeamSigningType;
    subType?: TeamSigningSubType;
    orgId: number;
    teamMembers?: TeamMember;
    walletCount?: number;
}

export interface PaginationMeta {
    totalCount?: number;
    currentPage?: number;
    totalPages?: number;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    pageSize?: number;
}

export interface TeamsListResponse {
    teamListData : TeamsV2[];
    paginationMeta : PaginationMeta
}

export class TeamsV2Request {
    constructor(public name: string, public type: TeamSigningType,
        public members: number[], public subType?: TeamSigningSubType) { }
}

export enum FilterType {
    ALL = "All", SIGNED="Signed", NON_SIGNED="Non signed"
}