import { Component, OnInit, Input } from '@angular/core';
// import { toPretty } from 'src/app/shared/helpers/trezor/numberHelper'
import BN from "bignumber.js";
import { getIcon, getName } from '../../helpers/coins';

@Component({
  selector: 'app-wallet-card',
  inputs: ['option'],
  templateUrl: './wallet-card.component.html',
  styleUrls: ['./wallet-card.component.scss']
})
export class WalletCardComponent implements OnInit {
  walletAssets: any;
  @Input() option: any;
  @Input() assetsCard: string;
  @Input() dropdown_card: string;
  @Input() AllWallets: any;
  @Input() card_padding: any;
  @Input() component_from: any;
  constructor() { }

  ngOnInit(): void {
  }

  // pretty(number, decimal) {
  //   return toPretty(number, decimal)
  // }

  sumofwallets(walletId) {
    let total = new BN(0);
    this.walletAssets = this.AllWallets.filter(obj => obj.id == walletId);
    if (this.walletAssets && this.walletAssets.length > 0)
      for (let i = 0; i < this.walletAssets.length; i++) {
        total = total.plus(new BN(this.walletAssets[i].balanceUSD));
      }
    return total
  }

  getImageUrl(wallet) {
    if (!wallet.coin.toString().includes(" "))
      return this.assetsCard === 'assets_card' ? getIcon(wallet.coin) : this.assetsCard === 'wallet_card' ? '../../../assets/images/wallet.svg' : getIcon(wallet.chain);
    else
      return wallet.imageurl
  }
}
