import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import * as localforage from "localforage";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LocalForageService {
  private data = new BehaviorSubject({});
  store = this.data.asObservable();
  private localforageStore: LocalForage = null;
  async allData() {
    let wallets = {};
    localforage
      .iterate(function (value, key) {
        wallets[key] = value;
      })
      .then(() => {
        this.data.next(wallets);
      });
  }

  constructor() {
    this.localforageStore = localforage.createInstance({
      name: `liminal_${environment.env}`,
      storeName: "cache",
    });
  }

  async setItem(key: string, value: any) {
    try {
      await this.localforageStore.setItem(key, value);
    } catch (e) {
      console.log(e);
    }
  }
  async removeItem(key: string) {
    try {
      await this.localforageStore.removeItem(key);
    } catch (e) {
      console.log(e);
    }
  }
  async getItem(key: string) {
    try {
      const d = await this.localforageStore.getItem(key);
      return d;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async getAll(filterFunction?: (value: any, key: string) => boolean) {
    console.info("running iterator");
    return new Promise((resolve, reject) => {
      const result = {};
      this.localforageStore
        .iterate((value: any, key: string) => {
          if (filterFunction && filterFunction(key, value)) {
            result[key] = value;
          }
        })
        .then(() => {
          resolve(result);
        });
    });
  }
}
