import { Component, Input, OnInit } from '@angular/core';
import { TeamSigningSubType } from 'src/app/shared/entities';

@Component({
  selector: 'app-signing-notes',
  templateUrl: './signing-notes.component.html',
  styleUrls: ['./signing-notes.component.scss']
})
export class SigningNotesComponent implements OnInit {

  @Input() selectedSigningSubType : TeamSigningSubType;
  @Input() showNote : boolean = false;

  TeamSigningSubType = TeamSigningSubType

  constructor() { }

  ngOnInit(): void {
  }

}
