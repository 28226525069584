<div class="page-header">
  <div class="header-wrapper row m-0">
      <div class="brand-logo">
        <span class="hamburger-icon" (click)="toggleSidenav()">
          <svg xmlns="http://www.w3.org/2000/svg" height="22" width="20" viewBox="0 0 448 512">
            <path
              d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
          </svg>
        </span>
      
        <a href="javascript:void(0);" routerLink="/">
          <img src="../../../../assets/images/logo/logo.png" class="img-fluid" />
        </a>
    </div>
    <div class="nav-right pull-right right-header ml-auto p-0">
      <ul class="nav-menus" *ngIf="loading">
        <li class="p-0">
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </li>
        <!-- <li class="maximize">
          <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
            <i class="icon-fullscreen" style="vertical-align: middle;"></i>
          </a>
        </li> -->
        <li class="profile-nav onhover-dropdown p-0 ml-3">
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </li>
      </ul>
      <ul class="nav-menus" *ngIf="!loading">
        <li noDisplay class="p-0 app-wallet" *ngIf="orgProfile.orgType !== orgType.sub">
          <app-wallet-connect></app-wallet-connect>
        </li>
        <!-- <li class="maximize">
          <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
            <i class="icon-fullscreen" style="vertical-align: middle;"></i>
          </a>
        </li> -->
        <li class="profile-nav onhover-dropdown p-0 ml-3">
          <app-my-account [link_component_for]="'menu'"></app-my-account>
        </li>
      </ul>
     
    </div>
  </div>
</div>
<!-- <div class="header-wrapper row m-0 mb-20">
  <div class="col-sm-12 col-xl-12 xl-100">
    <div class="row2">
    </div>
  </div>
</div> -->

<app-trezor-webusb></app-trezor-webusb>