<div class="d-flex container p-3" [ngClass]="{'no-border': removeOuterBorder}" style="flex-wrap: wrap; ">
    <div class="d-flex flex-row ml-2 selected-item selectedSuccessfully" style="padding-right: 20px !important;"
        *ngFor="let member of members" [ngClass]="{'highlight': isMemberToBeHighlighted(member)}">
        <div class="d-flex align-items-center box_Circle_Qr">
            <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2" src="{{member?.user?.photoURL}}"
                alt="">
        </div>
        <div class="details d-flex align-items-center">
            <div class="col text-left p-0">
                <span #name class="m-0 truncate_Name ">{{member.user?.displayName}}</span>
            </div>
        </div>
    </div>
</div>