
export enum ErrorMsgs{
  POPUP_CLOSED = "Popup closed",
}

export class BaseError extends Error {
  code: string;
  data: any;
  constructor(code: string, name: string, description: string, data: any = {}) {
    super(description);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = name;
    this.data = data;
    this.code = code;
    Error.captureStackTrace(this);
  }
}

export class NoShowError extends BaseError {
  constructor(description: string, data: any = {}) {
    super("NO_SHOW", 'No Show Error', description, data);
  }
}
