// safe.pipe.ts
import { Pipe, PipeTransform } from "@angular/core";
import {
  DomSanitizer,
  SafeHtml,
  SafeStyle,
  SafeScript,
  SafeUrl,
  SafeResourceUrl,
} from "@angular/platform-browser";

/**
 * A custom Angular pipe for safely sanitizing different types of content.
 *
 * Transform the input based on the specified type for safe sanitization.
 *
 * @param value - The input string to be sanitized.
 * @param type - The type of content to sanitize ('html', 'style', 'script', 'url', 'resourceUrl').
 * @returns Sanitized content based on the specified type.
 * 
 * Usage:
 * ```
 * <div [innerHTML]="yourBase64EncodedSVG | safe: 'html'"></div>
 * <div [style.background]="someDynamicStyle | safe: 'style'"></div>
 * <script [innerHTML]="someDynamicScript | safe: 'script'"></script>
 * <a [href]="someDynamicUrl | safe: 'url'">Link</a>
 * <iframe [src]="someDynamicResourceUrl | safe: 'resourceUrl'"></iframe>
 * ```
 */
@Pipe({
  name: "safe",
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    value: string,
    type: string
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case "html":
        return this.sanitizer.bypassSecurityTrustHtml(value);

      case "style":
        return this.sanitizer.bypassSecurityTrustStyle(value);

      case "script":
        return this.sanitizer.bypassSecurityTrustScript(value);

      case "url":
        return this.sanitizer.bypassSecurityTrustUrl(value);

      case "resourceUrl":
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);

      default:
        return this.sanitizer.bypassSecurityTrustUrl(value);
    }
  }
}
