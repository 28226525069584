import { Component, OnInit } from "@angular/core";
import { HttpService } from "../../shared/services/http.service";
import { AuthServiceJWT } from "../../shared/services/auth.service";
import { AuthService } from "@auth0/auth0-angular";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataService } from "src/app/shared/services/data.service";
import { SegmentService } from 'ngx-segment-analytics';
import { ErrorPrompt } from "src/app/shared/components/custom-prompt/custom-prompt.service";
@Component({
  selector: "app-get-started",
  templateUrl: "./get-started.component.html",
  styleUrls: ["./get-started.component.scss"],
})
export class GetStartedComponent implements OnInit {
  success: boolean = false;
  resend = true;
  timeLeft = 10;
  authDetails = null;
  userPendingOrgReq: any;
  basicState: string;
  orgState: string;
  userDetails: any;
  keys: number;
  members: number;
  orgid: number;
  userType: number;
  loading: boolean = false;

  fullName;
  accountName;
  organization;

  constructor(
    public router: Router,
    private httpService: HttpService,
    public authService: AuthServiceJWT,
    public afAuth: AuthService,
    private data: DataService,
    private segment: SegmentService
  ) {
    this.afAuth.user$.subscribe(async (user) => {
      if (user) {
        const token = await this.afAuth.getIdTokenClaims().toPromise();
        this.authDetails = {
          email: user.email,
          displayName: user.name,
          uid: user.email,
          photoURL: user.picture || "assets/dashboeard/boy-2.png",
          emailVerified: user.email_verified,
          sub: user.sub,
          iss: token.iss,
        };
        this.segment.track("first-time-login");
        if (this.authDetails.emailVerified) {
          this.segment.track("email-verification-success").catch((err)=>{});
        } else {
          this.segment.track("email-verification-failed").catch((err)=>{});
        }
        if (this.authDetails.emailVerified) {
          this.getProfile();
          setInterval(() => this.getProfile(), 15000);
        } else {
          this.basicState = "unverified";
        }
      }
    });
    this.loopRefresh();
  }

  loopRefresh() {
    this.refreshPendingTx();
    setTimeout(() => {
      this.loopRefresh()
    }, 35000);
  }

  async refreshPendingTx() {
    let userDetailRequests = await this.httpService.getprofile().toPromise();
    // console.log("userDetails", userDetailRequests);
    this.data.changeUserProfileRequest(userDetailRequests);
  }

  ngOnInit(): void {}

  async resendEmail() {
    this.resend = false;
    this.timer();
    Swal.fire({
      text: "Email verification sent. Kindly check your email address.",
      icon: "success",
      timer: 2500,
      showConfirmButton: false,
    });
    await this.httpService
      .resendEmailVerification({
        email: this.authDetails.email,
        userid: this.authDetails.sub,
        iss: this.authDetails.iss,
      })
      .toPromise();
  }

  timer() {
    this.timeLeft = 10;
    let interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
        clearInterval(interval);
        this.resend = true;
      }
    }, 1000);
  }

  logout() {
    this.authService.SignOut();
  }

  async addOganization() {
    this.loading = true;
    let response = await this.httpService.createOrganization(this.accountName).toPromise();
    if (response.success == true) {
      await this.httpService.updateUserName(this.fullName).toPromise();
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 2000);
      this.getProfile();
    }
    this.loading = false;
  }

  async joinOganization() {
    this.loading = true;
    let data = await this.httpService.updateUserName(this.fullName).toPromise();
    this.joinDeclineOrg(this.organization, "1");
    // this.segment.track("invitation-accepted");
    this.segment.track("signup-success").catch((err)=>{});
    this.loading = false;
  }

  async joinDeclineOrg(id, status) {
    let response = await this.httpService.acceptDeclineOrg(id, status).toPromise();
    if (response.success == true) {
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 2000);
      window.location.href = "/";
    } else {
      ErrorPrompt.fire({
        icon: "error",
        title: "Oops...",
        text: response.message,
      });
    }
    this.loading = false;
  }

  async getPendingRequests() {
    this.userPendingOrgReq = await this.httpService
      .getAllPendingUserReq()
      .toPromise();
    if (this.userPendingOrgReq.length > 0) {
      this.orgState = "join_organization";
    } else {
      this.orgState = "create_organization";
    }
  }

  getProfile() {
    this.httpService.getprofile().subscribe((user_detail) => {
      this.userDetails = user_detail;
      this.keys = Object.values(user_detail["keys"]).flat().length;
      this.basicState = this.userDetails.organizations.length
        ? "onboarding"
        : "new";
      if (this.basicState == "new") this.getPendingRequests();
      if (this.basicState == "onboarding")
        this.getMembers(this.userDetails.organizations[0].id);
    });
  }

  async getMembers(orgid) {
    let _members = await this.httpService.getOrgMembers(orgid);
    this.members = Object.values(_members.members)
      .flat()
      .filter((member) => member["status"] == 1).length;
    Object.values(_members.members).forEach((member) => {
      if (member["email"] === this.userDetails["email"])
        this.userType = member["type"];
    });
  }
}
