// sidenav.service.ts

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private closeSubject = new Subject<void>();
  private isSidenavOpenSubject = new Subject<boolean>();
  private currentToggledStatus;
  close() {
    this.currentToggledStatus = false;
    this.closeSubject.next();
    this.isSidenavOpenSubject.next(false);
  }

  onClose() {
    return this.closeSubject.asObservable();
  }

  setSidenavOpenStatus(isOpen: boolean) {
    this.currentToggledStatus = isOpen;
    this.isSidenavOpenSubject.next(isOpen);
  }

  onSidenavOpenStatus() {
    return this.isSidenavOpenSubject.asObservable();
  }

  getCurrentToggleStatus() {
    return this.currentToggledStatus;
  }
}
