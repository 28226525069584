export function checkDeviceCompatibleWithChain(coin, deviceName ) {
  coin = coin.toUpperCase()
  if (!deviceName || deviceName == undefined)
    return false
  switch (deviceName) {
    case "Ledger":
      switch (coin) {
        case "XRP":
          return true
        case "DOGE":
          return true
        case "ETH":
          return true
        case "BTC":
          return true
        case "TRX":
          return true
        case "BCH":
          return true
        case "SOL":
          return true
        case "XLM":
          return true

        default:
          return false
      }
      break;
    case "Trezor":
      switch (coin) {
        case "XRP":
          return false
        case "DOGE":
          return true
        case "ETH":
          return true
        case "BTC":
          return true
        case "BCH":
          return true
        case "TRX":
          return false
        case "SOL":
          return false
        case "XLM":
          return false

        case "LTC":
          return true
        default:
          return false
      }
      break;
  }
}