import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() iconUrl: string;

  @Input() showBackButton: boolean = false;

  @Output() onClose = new EventEmitter();
  @Output() onBackPressed = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onModalCloseClick() {
    this.onClose.emit()
  }

  backClick(){
    this.onBackPressed.emit();
  }
}
