<ng-template #errorModal let-modal>
    <div class="err-modal-custom">
        <div class="modal-body d-flex align-items-center justify-content-center">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3 mt-5 position-relative">
                        <div class="text-center"> <img class="w-70" src="../../../assets/images/error-face.svg"> </div>
                    </div>
                    <div class="mb-3 text-center">
                        <div class="err-title" [innerHTML]="errorMessage?.title"></div>
                        <div class="err-text" [innerHTML]="errorMessage?.text"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0 mb-2">
            <button type="submit" class="btn btn-outline-primary w-100" (click)="modal.dismiss('Cross click');">
                {{errorMessage.confirmButtonText || "Okay"}}
            </button>
        </div>
    </div>
</ng-template>


<ng-template #successModal let-modal>
    <div class="success-modal-custom">
        <div class="modal-header border-0">
            <h2 class="modal-title">
                <i class="{{successMessage?.headerIconClass}} mr-2"></i>
                {{successMessage?.header}}
            </h2>
            <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i
                    class="icon-close"></i> </button> -->
        </div>
        <div class="modal-body d-flex align-items-center">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3 position-relative">
                        <div class="background_success text-center"> <img class="w-50"
                                src="../../../assets/images/check-outline-success.gif"> </div>
                    </div>
                    <div class="mb-5 text-center">
                        <div class="h5" [innerHTML]="successMessage?.title"></div>
                        <div class="text-muted" [innerHTML]="successMessage?.text"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0 mb-2">
            <button type="submit" class="btn btn-outline-primary w-100" (click)="modal.dismiss('Cross click');">
                {{successMessage.confirmButtonText || "Ok"}}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #customPromptModal let-modal>
    <div class="custom-prompt-modal">
        <div class="modal-body d-flex align-items-center justify-content-center">
            <div class="row">
                <div class="col-12">
                    <div class="position-relative">
                        <div class="text-center"> 
                            <img *ngIf="customMessage?.icon;else commonIcon" class="w-70 img-align" src="../../../assets/images/{{customMessage?.icon}}"> 
                            <ng-template #commonIcon>
                                <img class="w-70" src="../../../assets/images/delete-icon.svg"> 
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="mb-3 text-center custom-txt-box">
                    <div class="custom-title" [innerHTML]="customMessage?.title"></div>
                    <div class="custom-text mt-2" [innerHTML]="customMessage?.text"></div>
                </div>
            </div>
        </div>
       
        <div class="modal-footer border-0">
                    <button type="button" class="btn btn-outline-secondary btn-bg" (click)="modal.dismiss('Cross click');">
                        {{customMessage?.cancelButtonText || 'Cancel'}}
                    </button>
                    <button type="button" class="btn btn-outline-primary" (click)="submit();">
                       {{customMessage?.confirmButtonText || 'Ok'}}
                    </button>
        </div>
        
    </div>
</ng-template>