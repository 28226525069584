
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from '../../shared/entities/User';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpService } from '../../shared/services/http.service';
import { Wallet } from '../../shared/entities/wallet';
import { WalletserviceService } from '../../shared/services/walletservice.service'
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { WalletService } from '../../shared/services/wallet.service';

@Component({
  selector: 'app-multisigjourney',
  templateUrl: './multisigjourney.component.html',
  styleUrls: ['./multisigjourney.component.scss']
})
export class MultisigjourneyComponent implements OnInit {


  myControl = new FormControl();
  addedSigners: User[] = [

  ]
  options: User[] = [

  ];
  @ViewChild("signer1") signer1: ElementRef;
  @ViewChild("walletName") walletName: ElementRef;
  @ViewChild("config") config: ElementRef;
  @ViewChild("wallettype") wallettype: ElementRef;
  @ViewChild("chain") chain: ElementRef;

  filteredOptions: Observable<User[]>;
  constructor(private httpService: HttpService, private walletService: WalletserviceService, public ngZone: NgZone, public router: Router, private logger: LoggerService, private walletSvc: WalletService) {
    this.httpService.getAllAvailableSigners().subscribe((dataApi: string) => {
      this.options = JSON.parse(dataApi);
    })
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.email),
        map(email => email ? this._filter(email) : this.options.slice())
      );
  }
  displayFn(user: User): string {
    return user && user.email ? user.photoURL : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.email.toLowerCase().indexOf(filterValue) === 0);
  }

  addSignertoWallet() {
    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i].email == this.signer1.nativeElement.value) {
        this.addedSigners.push(this.options[i]);
        this.signer1.nativeElement.value = ""
        return;
      }
    }
  }

  createWallet() {
    let cosignerids: string[] = [];
    this.addedSigners.forEach(element => cosignerids.push(element.email + ""));
    // console.log( this.walletName.nativeElement.value + this.config.nativeElement.value + cosignerids
    //   + this.wallettype.nativeElement.value + this.chain.nativeElement.value);
    this.walletSvc.createNewMultisigWalletWith2FA(this.walletName.nativeElement.value, this.config.nativeElement.value, cosignerids
      , this.wallettype.nativeElement.value, this.chain.nativeElement.value).subscribe((dataApi: string) => {
        let multiSigWallet: Wallet = JSON.parse(dataApi);
        this.walletService.setWallet(multiSigWallet);
        this.ngZone.run(() => {
          this.router.navigate(['/home']);
        });
      });
  }
}