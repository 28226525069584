import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MPCTeamConfig, TeamSigningSubType, TeamSigningType, TeamsV2 } from 'src/app/shared/entities';
import { AddTeamV2Component } from '../../add-team-v2/add-team-v2.component';


export enum MembersType {
  INITIATORS = 1,
  SIGNERS = 2
}


@Component({
  selector: 'app-mobile-teams',
  templateUrl: './mobile-teams.component.html',
  styleUrls: ['./mobile-teams.component.scss']
})
export class MobileTeamsComponent implements OnInit {

  @Input() teamsListData: TeamsV2[];
  @Input() orgProfile: any;
  @Input() teamConfig: MPCTeamConfig;

  @Input() type: MembersType;

  @Output() onTeamConfigUpdated = new EventEmitter<MPCTeamConfig>();

  searchTerm: string;
  focusTotInit: boolean = false;

  isInitiators: boolean = false;
  isSigners: boolean = false;

  teamName: string;
  teamMembers: any;

  constructor(public modalService: NgbModal) { }

  ngOnInit(): void {
    if (!this.teamConfig) {
      this.teamConfig = new MPCTeamConfig();
    }
    else {
      this.populateValues()
    }

    if (MembersType.INITIATORS === this.type) {
      this.isInitiators = true;
      this.teamsListData = this.teamsListData.filter((team) => TeamSigningType.NON_SIGNING === team.type);
    }
    else if (MembersType.SIGNERS === this.type) {
      this.isSigners = true;
      this.teamsListData = this.teamsListData.filter((team) => TeamSigningType.SIGNING === team.type && TeamSigningSubType.MPC === team.subType);
      this.teamConfig.minMembersRequired = 1;
    }
  }


  onDropDownBtnClick(selectedTeam: any) {
    // Reset minmembers input
    if (this.teamConfig?.team?.id !== selectedTeam.id) {
      this.teamConfig.minMembersRequired = null;
    }
    // Default to 1 for signers
    if (this.isSigners) this.teamConfig.minMembersRequired = 1;

    this.teamConfig.team = selectedTeam;

    this.teamName = selectedTeam.name;
    this.teamMembers = selectedTeam.teamMembers;

    this.onTeamConfigUpdated.emit(this.teamConfig);
  }

  populateValues() {
    let teamName = this.teamConfig.team?.name
    this.teamName = teamName;
    this.teamMembers = this.teamConfig?.team?.teamMembers;
  }

  onMinMembersRequiredChange() {
    let { minMembersRequired } = this.teamConfig;

    if (minMembersRequired && minMembersRequired >= 1 && minMembersRequired <= this.teamMembers.length) {
      this.onTeamConfigUpdated.emit(this.teamConfig);
    }
  }

  validateNumberRange(value: number): boolean {
    const min = 1;
    const max = this.teamMembers.length;
    return Number.isInteger(value) && value >= min && value <= max;
  }

  openAddTeamModal() {
    this.modalService.dismissAll();
    const addTeamModalRef = this.modalService
      .open(AddTeamV2Component, {
        windowClass: "add-team modal-custom-background",
        centered: true,
      })
    addTeamModalRef.componentInstance.orgProfile = this.orgProfile;
  }

}
