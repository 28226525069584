<ng-template #TrezorConnect let-modal>
    <div class="container-fluid">
        <div class="modal-header">
            <h2 class="modal-title"><i class="icon-send text-primary mr-2"></i>Sign on device</h2>
            <button type="button" class="close" aria-label="Close" (click)="close_trezor_web_usb()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body white_background" *ngIf="stage=='connect'">
            <div class="row">
                <div class="col-12">
                    <div class="mb-4 text-center">
                        <img class="img-fluid" src="../../../../assets/images/trezor-browzer-popup---GIF.gif"
                            alt="img" />
                    </div>
                    <div class="mb-4 text-center">
                        <label class="mb-0">Browser approval is required to connect your device.</label>
                        <label>Please approve and tap to connect.</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body white_background mt-3" *ngIf="stage=='trezor_Example'">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex flex-column align-items-center pb-2 mb-5">
                        <img class="img-fluid" src="../../../../assets/images/mobile-view/trezor-icon.svg" alt="img" />
                        <span class="d-block font-size-16 font-weight-bold mt-2 mb-3">Trezor</span>
                        <p class="m-0 mb-text-light-grey font-size-14 text-center text-dark">The layout of the numbers will be
                            displayed on your <br>Trezor as below</p>
                    </div>
                    <div class="mb-0 text-center">
                        <img class="img-fluid w-100" src="../../../../assets/images/Trezor_example.svg" alt="img" />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-outline-primary w-100 trezor-webusb-button position-relative" (click)="pairconnect(); stage = 'trezor_Example'">
                <span *ngIf="stage=='connect'">Detect Device</span>
                <span *ngIf="stage=='trezor_Example'">Next</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #TrezorPin let-modal>
    <div class="container-fluid" *ngIf="stage=='pin'">
        <div class="modal-header">
            <h2 class="modal-title"><i class="icon-send text-primary mr-2"></i>Sign on device</h2>
            <button type="button" class="close" aria-label="Close" (click)="close_trezor_web_usb()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body white_background mt-3">
            <div class="d-flex flex-column align-items-center pb-2">
                <img class="img-fluid" src="../../../../assets/images/mobile-view/trezor-icon.svg" alt="img" />
                <span class="d-block font-size-16 font-weight-bold mt-2 mb-3">Trezor</span>
                <p class="m-0 mb-text-light-grey font-size-14 text-center text-dark">Check your Trezor screen for the keypad
                    layout</p>
            </div>
            <div class="mt-4 mb-4">
                <div class="position-relative">
                    <input class="form-control-pin" type="password" [(ngModel)]="pin" />
                    <i (click)="removeNumber()" class="icon-close input-close-icon"></i>
                </div>
            </div>
            <div class="mb-auto pt-3">
                <div class="d-flex justify-content-between">
                    <button class="pin-btn" type="button" (click)="setappend('7')">•</button>
                    <button class="pin-btn" type="button" (click)="setappend('8')">•</button>
                    <button class="pin-btn" type="button" (click)="setappend('9')">•</button>
                </div>
                <div class="d-flex justify-content-between">
                    <button class="pin-btn" type="button" (click)="setappend('4')">•</button>
                    <button class="pin-btn" type="button" (click)="setappend('5')">•</button>
                    <button class="pin-btn" type="button" (click)="setappend('6')">•</button>
                </div>
                <div class="d-flex justify-content-between">
                    <button class="pin-btn" type="button" (click)="setappend('1')">•</button>
                    <button class="pin-btn" type="button" (click)="setappend('2')">•</button>
                    <button class="pin-btn" type="button" (click)="setappend('3')">•</button>
                </div>
            </div>
        </div>
        
        <div *ngIf="state == 'verifyPin'" class="modal-footer border-0">
            <button class="d-flex align-items-center justify-content-center btn btn-outline-primary font-size-16 w-100" disabled type="submit">
                <div class="loader-box mr-1">
                    <div class="loader-15"></div>
                </div>
                Verifying
            </button>
        </div>
        <div *ngIf="state != 'verifyPin'" class="modal-footer border-0">
            <button class="d-flex align-items-center justify-content-center btn btn-outline-primary font-size-16 w-100"
                (click)="pinsubmit()" type="submit">
                <img class="mr-2 img-fluid" src="../../../../assets/images/mobile-view/Change_password.svg" alt="img" />
                Enter PIN
            </button>
        </div>
    </div>

    <div class="container-fluid" *ngIf="stage=='trezor_wallets'">
        <div class="modal-header">
            <h2 class="modal-title"><i class="icon-send text-primary mr-2"></i>Sign on device</h2>
            <button type="button" class="close" aria-label="Close" (click)="close_trezor_web_usb()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body white_background mt-3">
            <div class="mx-auto">
                <div class="col-12">
                    <div class="text-center mb-5">
                        <img class="img-fluid" src="../../../../assets/images/mobile-view/trezor-icon.svg" alt="img" />
                        <span class="d-block font-size-16 font-weight-bold mt-2 mb-3">Trezor</span>
                    </div>
                    <div class="mb-4 text-center">
                        <div class=" col p-0 text-center ">
                            <button [ngClass]="{'active': selected_Wallet == 'standard_wallet'}"
                                (click)="select_Wallet('standard_wallet')"
                                class="btn mb-4 card_button w-100 p-4 font-size-12" type="button"
                                >
                                <div class="d-flex align-items-center w-100">
                                    <div class="rounded-circle-grey">
                                        <img *ngIf="selected_Wallet == 'standard_wallet'"
                                            class="img-fluid rounded-circle-img"
                                            src="../../../../assets/images/standard_wallet.svg" alt="standard_wallet" />
                                        <img *ngIf="selected_Wallet != 'standard_wallet'"
                                            class="img-fluid rounded-circle-img"
                                            src="../../../../assets/images/standard_wallet_grey.svg"
                                            alt="standard_wallet" />
                                    </div>
                                    <div class="ml-3 text-left">
                                        <h2 class="mb-1">Standard wallet</h2>
                                        <span class="text-dark">
                                            Continue without Passphrase
                                        </span>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div [ngClass]="{'active': selected_Wallet == 'hidden_wallet'}"  class="card_button w-100">
                            <div class="p-0"> 
                            <button 
                                (click)="select_Wallet('hidden_wallet')"
                                class="btn btn-outline-grey w-100 p-4 font-size-12"
                                type="button" data-original-title="btn btn-outline-grey" >
                                <div class="d-flex align-items-center w-100">
                                    <div class="rounded-circle-grey">
                                        <img *ngIf="selected_Wallet != 'hidden_wallet'"
                                            class="img-fluid rounded-circle-img"
                                            src="../../../../assets/images/hidden_wallet.svg" alt="hidden_wallet" />
                                        <img *ngIf="selected_Wallet == 'hidden_wallet'"
                                            class="img-fluid rounded-circle-img"
                                            src="../../../../assets/images/hidden_wallet_blue.svg"
                                            alt="hidden_wallet" />
                                    </div>
                                    <div class="ml-3 text-left">
                                        <h2 class="mb-1">Hidden wallet</h2>
                                        <span class="text-dark">
                                            Passphrase is required
                                        </span>
                                    </div>
                                    <div class="media-body text-right">
                                        <img *ngIf="selected_Wallet != 'hidden_wallet'" class="img-fluid" src="../../../../assets/images/down_arrow.svg"
                                            alt="down_arrow" />
                                        <img *ngIf="selected_Wallet == 'hidden_wallet'" class="img-fluid" src="../../../../assets/images/up_arrow.svg"
                                            alt="down_arrow" />
                                    </div>
                                </div>
                            </button>
                            <div class="px-3 pb-3" *ngIf="selected_Wallet == 'hidden_wallet'">
                                <div class="position-relative">
                                    <input class="form-control-pin" [type]="show ? 'text' : 'password'"
                                        placeholder="Enter Passphrase" [(ngModel)]="passphrase" />
                                    <img class="img-fluid position-absolute cursor-pointer view_password" (click)="showPassword('passphrase')" src="../../../../assets/images/view_password.svg"
                                        alt="down_arrow" />
                                </div>
                                <div class="position-relative mt-3">
                                    <input class="form-control-pin" [type]="showconf ? 'text' : 'password'"
                                        placeholder="Confirm Passphrase" [(ngModel)]="confirmPassphrase" />
                                    <img class="img-fluid position-absolute cursor-pointer view_password"  (click)="showPassword('confirmPassphrase')"  src="../../../../assets/images/view_password.svg"
                                        alt="down_arrow" />
                                </div>
                                <div *ngIf="passError">
                                    <span class="d-block text-danger mt-1">Password Not Match</span>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button *ngIf="selected_Wallet == 'standard_wallet'" type="submit" class="btn btn-outline-primary w-100" (click)="detect_Wallet()">
                Continue
            </button>
            <button *ngIf="selected_Wallet == 'hidden_wallet'" class="btn btn-outline-primary w-100" (click)="passphrasesubmit()"
            type="submit">Continue</button>
        </div>
    </div>

    <div class="container-fluid" *ngIf="stage=='exportingKeys'">
        <div class="modal-header">
            <h2 class="modal-title"><i class="icon-key text-primary mr-2"></i>Linking Assets</h2>
        </div>
        <div class="modal-body white_background  d-flex align-items-center">
            <div class="mx-auto">
                <div class="col-12">
                    <div class="mb-4 text-center">
                        <img class="img-fluid" src="../../../../assets/images/99833-edupia-loading.gif" alt="img" />
                    </div>
                    <div class="mb-4 text-center">
                        <h2 class="">Linking Trezor Accounts</h2>
                        <label class="text-dark">Linking your digital assets accounts</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid" *ngIf="stage=='trezor_connect'">
        <div class="modal-header">
            <h2 class="modal-title"><i class="icon-send text-primary mr-2"></i>Sign on device</h2>
            <button type="button" class="close" aria-label="Close" (click)="close_trezor_web_usb()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body white_background  d-flex align-items-center">
            <div class="mx-auto">
                <div class="col-12">
                    <div class="mb-4 text-center">
                        <img class="img-fluid" src="../../../../assets/images/Trezor_confirm.svg" alt="img" />
                    </div>
                    <div class="mb-4 text-center">
                        <h2 class="">Trezor</h2>
                        <label class="text-dark">Please follow instructions on your device to sign transaction.</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid" *ngIf="stage=='ledger_connect'">
        <div class="modal-header">
            <h2 class="modal-title"><i class="icon-send text-primary mr-2"></i>Sign on device</h2>
            <button type="button" class="close" aria-label="Close" (click)="close_trezor_web_usb()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body white_background  d-flex align-items-center">
            <div class="mx-auto">
                <div class="col-12">
                    <div class="mb-4 text-center">
                        <img class="img-fluid" src="../../../../assets/images/Ledger_confirm.svg" alt="img" />
                    </div>
                    <div class="mb-4 text-center">
                        <h2 class="">Ledger</h2>
                        <label class="text-dark">Please follow instructions on your device to sign transaction.</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>