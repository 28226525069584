<a appDisableClick (click)="open(content1)" class="text-underline cursor-pointer" *ngIf="link_component_for == 'profile_component'">
  Link Device
</a>
<a appDisableClick *ngIf="link_component_for == 'link_onbaoard'" class="btn btn-outline-green btn-md w-100" (click)="open(content1)"
  role="button">
  <h6 class="mb-0 my-1 mx-4">
    <img class="pr-1" src="../../../../assets/images/onboarding/key.svg">
    <span class="onboarding_btn">Link Device</span>
  </h6>
</a>
<button appDisableClick (click)="open(content1)" *ngIf="link_component_for == 'link_deshboard'" type="submit"
  class="btn btn-link text-primary float-right  p-0">Link device</button>

<button appDisableClick *ngIf="link_component_for == 'link_new'" class="btn btn-link-device btn-md w-100 " 
 (click)="open(content1)" type="submit">
  Link Device </button>
  <button class="btn btn-primary btn-round ml-2" *ngIf="link_component_for == 'dev_onbording'" (click)="open(content1)">Link device</button>
<div *ngIf="link_component_for == 'menu'">
  <div class="media profile-media align-items-center">
    <img class="w-44 rounded-circle"[attr.src]="UserProfilePic" alt="" referrerpolicy="no-referrer">
    <div class="media-body"><span>{{UserName}}</span>
      <p class="mb-0 text-dark">{{ (this.isOwner==1) ? 'Owner' : (userType==2?'Admin':'Member')}}
        <svg fill="#6F757A" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style="width: 10px;">
          <path
            d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z">
          </path>
        </svg>
      </p>
    </div>
  </div>
  <ul class="profile-dropdown onhover-show-div">
    <li routerLink="/profile" routerLinkActive="active"><i class="icon-member w3 mr-2"></i><span>Profile</span></li>
    <li appDisableClick (click)="open(content1)" *ngIf="showLinkDevice(userProfile)"><i class="icon-key mr-2"></i><span>Link Device</span></li>

    <!-- Wallet create -->
    <app-wallet-create-selector [orgProfile]="userProfile" [component_for]="component_for"></app-wallet-create-selector>

    <li (click)="logout()"><i class="icon-logout mr-2"></i><span>Log out</span></li>
  </ul>
</div>
<ng-template #content1 let-modal>
  <div class="modal-custom" *ngIf="step1">
    <div class="modal-header">
      <h2 class="modal-title">
        <i class="icon-key mr-2 text-dark"></i>
        Link Your Device
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div>
            <label>Device</label>
            <span class="clearLabel cursor-pointer text-muted text-underline float-right" *ngIf="deviceName"
              (click)="resetLinkDeviceModel()">
              Clear
            </span>
            <div *ngIf="!deviceName" class="mb-4" ngbDropdown #myDrop="ngbDropdown">
              <input autocomplete="off" class="form-control search-custom" placeholder="Select hardware device"
                id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()" type="text">
              <ul ngbDropdownMenu  class="whitelistscroll w-100 py-0">
                <div>
                  <button class="dropdown-content card w-100 p-0 brd_Box m-0"
                    (click)="selectDevice('Trezor','icon-trezor')">
                    <li ngbDropdownItem class="p-3">
                      <div class="d-flex align-items-center">
                        <div class="device-icon h3 mb-0">
                          <!-- <img class="w-42" src="../../../../assets/images/trezor.svg" /> -->
                          <i class="icon-trezor text-primary icon-round-border"></i>
                        </div>
                        <div class="text-left ml-2">
                          <span class="d-block truncate_Name">Trezor</span>
                          <span class="d-block t_Id">Hardware Device</span>
                        </div>
                      </div>
                    </li>
                  </button>
                </div>
                <div>
                  <button class="dropdown-content card w-100 p-0 brd_Box m-0"
                    (click)="selectDevice('Ledger','icon-ledger')">
                    <li ngbDropdownItem class="p-3">
                      <div class="d-flex align-items-center">
                        <div class="device-icon h3 mb-0">
                          <!-- <img class="w-42" src="../../../../assets/images/ledger.svg" /> -->
                          <i class="icon-ledger text-primary icon-round-border"></i>
                        </div>
                        <div class="text-left ml-2">
                          <span class="d-block truncate_Name">Ledger</span>
                          <span class="d-block t_Id">Hardware Device</span>
                        </div>
                      </div>
                    </li>
                  </button>
                </div>
              </ul>


            </div>
            <div *ngIf="deviceName" class="selected-card">
              <div class="card card-border mb-3">
                <div class="card-body card-body-device p-3">
                  <div class="d-flex align-items-center">
                    <div class="d-flex h3 mb-0">
                      <!-- <img class="w-42" src={{deviceImg}} /> -->
                      <i class="text-primary icon-round-border" [ngClass]="deviceImg"></i>
                    </div>
                    <div class="text-left ml-3">
                      <span class="d-block truncate_Name">{{deviceName}}</span>
                      <span class="d-block t_Id">Hardware Device</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 mb-3"  *ngIf="deviceName">
              <label>Protocols</label>
              <ng-multiselect-dropdown
              [placeholder]="'Select'"
              [settings]="dropdownSettings"
              [data]="dropdownList"
              [(ngModel)]="selectedItems"
              (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)">
              <ng-template #optionsTemplate let-item let-option="option" let-id="id"
              let-isSelected="isSelected" class="d-flex ms-2">
              <div class="d-flex align-items-center" [ngClass]="isSelected?'selected':''">
                  <div class="d-flex align-items-center box_Circle_Qr px-2 pl-4">
                    <span  class=" position-relative">
                      <img class="img-fluid for-light img-round-border "
                          [src]="option.toLowerCase() | getIcon" alt="">
                        </span>
                  </div>
                  <div class="details d-flex flex-column align-items-center">
                      <div class="text-left p-0">
                          <span #name class="m-0 truncate_Name">{{ option }}</span>
                      </div>
                  </div>
              </div>
            </ng-template>
              <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                <div class="d-flex">
                    <div class="d-flex align-items-center box_Circle_Qr">
                      <span  class="mr-2 position-relative" placement="top"
                          ngbTooltip="{{option}}" [ngClass]="{'link_Circle_Checked': linked_coin.includes(option.toLowerCase()) }">
                          <!-- this.enableStakeWalletData.assets.includes(ele.coin.toLowerCase()) -->
                          <img class="img-fluid for-light img-round-border" [src]="option.toLowerCase() | getIcon" alt="">
                          <img class="checked" src="../../../../assets/images/dashboard/signed.svg">
                        </span>
                    </div>
                    <div class="details d-flex align-items-center">
                        <div class="col text-left p-0">
                            <span #name class="m-0 truncate_Name ">{{ option }}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
            </ng-multiselect-dropdown>
            
            </div>
            <div class="search-div mb-3 pt-2">
              <div class="mb-4">
                <div class="d-table-cell">
                  <input [(ngModel)]="readyToUse" class="checkbox_animated" id="chk-ani1" type="checkbox">
                </div>
                <div class="d-table-cell">
                  <label for="chk-ani1" class="d-inline m-0 cursor-pointer"> My device is setup successfully and is ready to use.
                  </label>
                </div>
              </div>
              <div class="mb-4">
                <div class="d-table-cell">
                  <input [(ngModel)]="backUp" class="checkbox_animated" id="chk-ani2" type="checkbox">
                </div>
                <div class="d-table-cell">
                  <label for="chk-ani2" class="d-inline m-0  cursor-pointer"> I have backed up the seed phrase and stored it in secured
                    location. </label>
                </div>
              </div>
              <div class="mb-4">
                <div class="d-table-cell">
                  <input [(ngModel)]="liminalUnderstand" class="checkbox_animated" id="chk-ani3" type="checkbox">
                </div>
                <div class="d-table-cell">
                  <label for="chk-ani3" class="d-inline m-0  cursor-pointer"> I understand that Liminal does not have access to my seed
                    phrase or my private keys, hence, won't be able to retrieve it if I lose access.</label>
                </div>
              </div>

            </div>
            <div *ngIf="!deviceName" class="mb-4 text-muted ml-4 ">
              Connect your hardware device to the computer, and click on the below button to link your digital assets
              accounts.
            </div>
            <div *ngIf="deviceName" class="mb-4 text-muted ml-4">
              Connect your <b class="text-primary">{{deviceName}}</b> device to the computer, and click on the below
              button to link your digital assets accounts.
            </div>
          </div>
        </div>
        <div class="mb-2 col-12">

          <!-- <div class="d-flex flex-wrap align-items-center justify-content-center mb-4 ml-4">

            <span *ngIf="checkDeviceCompatibleWithChain('BTC')" class="mr-2 position-relative" placement="top"
              ngbTooltip="BTC" [ngClass]="{'link_Circle_Checked': linked_btc == true }">
              <img class="img-fluid for-light img-round-border" [src]="'btc' | getIcon" alt="">
              <img class="checked" src="../../../../assets/images/dashboard/signed.svg">
            </span>
            <span *ngIf="checkDeviceCompatibleWithChain('ETH')" class="mr-2 position-relative" placement="top"
              ngbTooltip="ETH" [ngClass]="{'link_Circle_Checked': linked_eth == true }">
              <img class="img-fluid for-light img-round-border" [src]="'eth' | getIcon" alt="">
              <img class="checked" src="../../../../assets/images/dashboard/signed.svg">
            </span>
            <span *ngIf="checkDeviceCompatibleWithChain('LTC')" class="mr-2 position-relative" placement="top"
              ngbTooltip="LTC" [ngClass]="{'link_Circle_Checked': linked_ltc == true }">
              <img class="img-fluid for-light img-round-border" [src]="'ltc' | getIcon" alt="">
              <img class="checked" src="../../../../assets/images/dashboard/signed.svg">
            </span>
            <span *ngIf="checkDeviceCompatibleWithChain('DOGE')" class="mr-2 position-relative" placement="top"
              ngbTooltip="DOGE" [ngClass]="{'link_Circle_Checked': linked_doge == true }">
              <img class="img-fluid for-light img-round-border" [src]="'doge' | getIcon" alt="">
              <img class="checked" src="../../../../assets/images/dashboard/signed.svg">
            </span>
            <span *ngIf="checkDeviceCompatibleWithChain('XRP')" class="mr-2 position-relative" placement="top"
              ngbTooltip="XRP" [ngClass]="{'link_Circle_Checked': linked_xrp == true }">
              <img class="img-fluid for-light img-round-border" [src]="'xrp' | getIcon" alt="">
              <img class="checked" src="../../../../assets/images/dashboard/signed.svg">
            </span>
            <span *ngIf="checkDeviceCompatibleWithChain('BCH')" class="mr-2 position-relative" placement="top"
              ngbTooltip="BCH" [ngClass]="{'link_Circle_Checked': linked_bch == true }">
              <img class="img-fluid for-light img-round-border" [src]="'bch' | getIcon" alt="">
              <img class="checked" src="../../../../assets/images/dashboard/signed.svg">
            </span>
            <span *ngIf="checkDeviceCompatibleWithChain('TRX')" class="mr-2 position-relative" placement="top"
              ngbTooltip="TRON" [ngClass]="{'link_Circle_Checked': linked_trx == true }">
              <img class="img-fluid for-light img-round-border" src="https://assets.lmnl.app/icons/trx.svg" alt="">
              <img class="checked" src="../../../../assets/images/dashboard/signed.svg">
            </span>

          </div> -->
          <div class="d-flex flex-column align-items-center justify-content-center ml-4">
            <div *ngIf="linking_btc" class="mb-3">{{ "Linking Bitcoin accounts.." }}</div>
            <div *ngIf="linking_eth" class="mb-3">{{ "Linking Ethereum accounts.." }}</div>
            <div *ngIf="linking_doge" class="mb-3">{{ "Linking Doge accounts.." }}</div>
            <div *ngIf="linking_xrp" class="mb-3">{{ "Linking XRP accounts.." }}</div>
            <div *ngIf="linking_cosmos" class="mb-3">{{ "Linking Cosmos accounts.." }}</div>
            <div *ngIf="linking_bch" class="mb-3">{{ "Linking BCH accounts.." }}</div>
            <div *ngIf="linking_dot" class="mb-3">{{ "Linking DOT accounts.." }}</div>
            <div *ngIf="linking_sol" class="mb-3">{{ "Linking Solana accounts.." }}</div>
            <div *ngIf="linking_xlm" class="mb-3">{{ "Linking XLM accounts.." }}</div>
            <div *ngIf="linking_Ledger_Error" class="mb-3 text-danger">{{ linking_Ledger_Message}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button (click)="linkETHLedgerDevice()" *ngIf="ng_eth" ngshow class="btn btn-outline-primary w-100" role="button">
        <span>Continue to ETH</span>
      </button>

      <button (click)="linkXRPLedgerDevice()" *ngIf="ng_xrp" ngshow class="btn btn-outline-primary w-100" role="button">
        <span>Continue to XRP</span>
      </button>

      <button (click)="linkBCHLedgerDevice()" *ngIf="ng_bch" ngshow class="btn btn-outline-primary w-100" role="button">
        <span>Continue to BCH</span>
      </button>
      
      <button (click)="linkLTCLedgerDevice()" *ngIf="ng_ltc" ngshow class="btn btn-outline-primary w-100" role="button">
        <span>Continue to LTC</span>
      </button>

      <button (click)="linkDOGELedgerDevice()" *ngIf="ng_doge" ngshow class="btn btn-outline-primary w-100" role="button">
        <span>Continue to DOGE</span>
      </button>

      <button (click)="linkCosmosLedgerDevice()" *ngIf="ng_cosmos" ngshow class="btn btn-outline-primary w-100" role="button">
        <span>Continue to Cosmos</span>
      </button>

      <button (click)="linkDotLedgerDevice()" *ngIf="ng_dot" ngshow class="btn btn-outline-primary w-100" role="button">
        <span>Continue to DOT</span>
      </button>

      <button (click)="linkTRXLedgerDevice()" *ngIf="ng_trx" ngshow class="btn btn-outline-primary w-100" role="button">
        <!-- <div *ngIf="isLinking" class="loader-box"><div class="loader-15"></div></div> -->
        <span>Continue to Tron</span>
      </button>

      <button (click)="linkSolanaLedgerDevice()" *ngIf="ng_sol" ngshow class="btn btn-outline-primary w-100" role="button">
        <span>Continue to Solana</span>
      </button>
      <button (click)="linkXLMLedgerDevice()" *ngIf="ng_xlm" ngshow class="btn btn-outline-primary w-100" role="button">
        <span>Continue to XLM</span>
      </button>

      <button (click)="linkDevice()" *ngIf="!ng_trx  && !ng_xrp && !ng_eth && !ng_bch && !ng_ltc &&   !ng_doge && !ng_cosmos && !ng_dot && !ng_sol && !ng_xlm && (deviceName == 'Ledger' || (deviceName == 'Trezor' && state != 'initial'))"
        [ngClass]="{'disabled': !readyToUse || !liminalUnderstand || !backUp || isLinking || !deviceName || !selectedItems.length }"
        [disabled]="!readyToUse || !liminalUnderstand || !backUp || isLinking || !selectedItems.length" class="btn btn-outline-primary w-100"
        role="button">
        <div *ngIf="isLinking" class="loader-box">
          <div class="loader-15"></div>
        </div>
        <i *ngIf="!isLinking" class="icon-key align_icn_patch mr-2"></i>
        <span *ngIf="!isLinking">Link Device</span>
      </button>
      
      <button id="btnpair1" *ngIf="deviceName == 'Trezor' && state == 'initial'"
        [ngClass]="{'disabled': !readyToUse || !liminalUnderstand || !backUp || isLinking || !deviceName || !selectedItems.length }"
        [disabled]="!readyToUse || !liminalUnderstand || !backUp || isLinking || !selectedItems.length" class="btn btn-outline-primary trezor-webusb-button w-100"
        role="button" style="position: relative;">
        <span *ngIf="!isFindTrezor" (click)="linkDevice()" class="w-100">Connect Trezor </span>
      </button>
    </div>
  </div>
  <div class="modal-custom" *ngIf="step2">
    <div class="modal-header border-0">
      <h2 class="modal-title">
        <i class="icon-key text-primary mr-2"></i>
        Success
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i
          class="icon-close"></i> </button>
    </div>
    <div class="modal-body d-flex align-items-center">
      <div class="row">
        <div class="col-12">
          <div class="mb-3 position-relative">
            <div class="background_success text-center"> <img class="w-50"
                src="../../../assets/images/check-outline-success.gif"> </div>
          </div>
          <div class="mb-5 text-center">
            <div class="h5">Success</div>
            <div class="text-muted">Awesome! You have successfully linked your digital assets accounts 🎉</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button type="submit" class="btn btn-outline-primary w-100" (click)="modal.dismiss('Cross click');"> Done
      </button>
    </div>
  </div>
</ng-template>

<ng-template #successModal let-modal>
  <div class="modal-custom">
      <div class="modal-header border-0">
          <h2 class="modal-title"><i class="icon-key mr-2"></i> Device Linked</h2>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i class="icon-close"></i> </button>
      </div>
      <div class="modal-body d-flex align-items-center">
          <div class="row">
              <div class="col-12">
                  <div class="mb-3 position-relative">
                      <div class="background_success text-center"> <img class="w-50"
                              src="../../../assets/images/check-outline-success.gif"> </div>
                  </div>
                  <div class="mb-5 text-center">
                      <div class="h5">Success</div>
                      <div class="text-muted">Awesome! You have successfully linked your digital assets accounts 🎉
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer border-0">
          <button type="submit" class="btn btn-outline-primary w-100"
              (click)="modal.dismiss('Cross click');"> Done
          </button>
      </div>
  </div>
</ng-template>

<ng-template #errorModal let-modal>
  <div class="modal-custom">
      <!-- <div class="modal-header border-0">
          <h2 class="modal-title"><i class="icon-key mr-2"></i> Failed to link device</h2>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i class="icon-close"></i> </button>
      </div> -->
      <div class="modal-body d-flex align-items-center  justify-content-center" style="background-color: #fff;">
          <div class="row">
              <div class="col-12">
                <div class="position-relative  text-center mb-4">
                  <img src="../../../assets/images/error.svg">
                  
              </div>
                  <div class="mb-5 text-center">
                      <div class="font-size-20" style="font-weight: 500; margin-bottom: 15px;">Failed to link device</div>
                      <div class="px-4" style="color: #6F757A;">Oops, we could not link your device. You can try again later to link the same.</div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer border-0">
        <button type="submit" class="btn btn-outline-primary w-100"
          (click)="modal.dismiss('Cross click');">Close
        </button>
        <div class="w-100 text-center mt-3 mb-2">
          <img src="../../../../assets/images/contact-support.svg" class="mr-2 inverse-icon"/>
            <a class='support_email' href='mailto:support@lmnl.app' supportLink target="_top">Contact Support</a>
        </div>
      </div>
  </div>
</ng-template>

<button (click)="getNewTransport()" style="display: none;" class="btn btn-primary w-100" id="ethlink" role="button">
  <!-- <div *ngIf="isLinking" class="loader-box"><div class="loader-15"></div></div> -->
  <span>Next</span>
</button>