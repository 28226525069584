import { Output, TransactionRequest, PreBuildTx, SignedTransaction } from '../../../entities';
import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import Trx from "@ledgerhq/hw-app-trx";
import { LedgerService } from '../../ledger/ledger';
import { Guid } from "guid-typescript";
import { HttpErrorResponse } from '@angular/common/http';


/**
 * Handles errors that occur during the signing of a Tron transaction.
 * @param err - The error object.
 */
function errorHandler(err: any) {
    let errorMessage: string;

    // Determine error message based on error type
    switch (true) {
        case (err.message === "Ledger device: UNKNOWN_ERROR (0x6a8d)"):
            errorMessage = "Allow unverified contracts from Ledger app setting.";
            break;

        case (err.name === "TransportOpenUserCancelled"):
            errorMessage = "It seems you have not connected your ledger device or please recheck the connection and try again.";
            break;

        case (err.response?.data?.message):
            errorMessage = this.walletService.setCustomErrorMsg(err.response.data.message);
            break;

        case (err instanceof HttpErrorResponse):
            errorMessage = this.walletService.setCustomErrorMsg(err.error.message);
            break;

        default:
            errorMessage = this.walletService.setCustomErrorMsg(err.message);
            break;
    }

    // Track error
    this.segment.track("send-asset-transaction-sign-failed", { user: this.authService.getUser, error: err })
        .then(() => console.log("Send asset transaction sign failed"))
        .catch((err) => {});

    return [{ error: errorMessage }];
}

export async function signTronTransaction(output: Output, req: TransactionRequest, type: string) {
    let transport;
    try {
        transport = await TransportWebHID.create();
        
        let txParam: any = {}
        var txJSON;
        var txid;
        let CoinName = "Tron";
        if (req && 'raw' in req) {
            let objwalletkey = this.wallet.walletKeys.filter(item => item.ismine === true);
            txid = req.id;
            txParam = JSON.parse(req.raw);
            txJSON = JSON.stringify(txParam)
            console.log(JSON.parse(txParam.halfSigned.payload));
            var JSONPayload = JSON.parse(txParam.halfSigned.payload);
            var jsnpayloaddata = typeof JSONPayload.data === 'object' && JSONPayload.data !== null ? JSONPayload.data : JSON.parse(JSONPayload.data);
            var raw_tx = jsnpayloaddata.raw_data_hex;
            // transport = await TransportWebHID.create();
            let currentapp = await LedgerService.getCurrentApp(transport);
            if (currentapp.name != CoinName) {
                return [{ "error": "Please Open Tron App on Ledger and Try Again" }]
            }
            const trx = new Trx(transport);
            const signature = await trx.signTransaction("44'/195'/0'/0/0", raw_tx, []);
            await transport.close();

            if (req.trpId) {
                const unixTimeStamp = new Date().getTime()
                const body = {
                    trpId: req.trpId,
                    signature: signature,
                    timestamp: unixTimeStamp
                }
                const apiResult = await this.httpService.approveTeamsTx(body).toPromise();

                return apiResult;
            }

            var data =
            {
                "sig": signature,
                "pubkey": objwalletkey[0].xpub
            }
            var respApprove = await this.httpService.ApproveTx(JSON.stringify(data), this.wallet.id, txid);

            if (respApprove.status !== 200) {
                return [{ "error": respApprove }]
            }
            return "success";
        }
        else {
            var respData: PreBuildTx = await this.httpService.getPrebuildTx({
                reqobj:
                {
                    nonce: 0,
                    output: output,
                    wallet: this.wallet,
                    type: type.toLowerCase(),
                }
            })
            var raw_tx = respData.txJson.raw_data_hex;
            let currentapp = await LedgerService.getCurrentApp(transport);
            if (currentapp.name != CoinName) {
                return [{ "error": "Please Open Tron App on Ledger and Try Again" }]
            }
            const trx = new Trx(transport);
            const signature = await trx.signTransaction("44'/195'/0'/0/0", raw_tx, []);
            await transport.close();
            let sequenceId = Guid.create().toString();
            if (req) {
                if ('sequenceId' in req) {
                    sequenceId = req['sequenceId'] as string;
                }
            }
            let strinifyraw_data = JSON.stringify(respData.txJson.raw_data);
            let finalTx =
            {
                visible: respData.txJson.visible,
                txID: respData.txJson.txID,
                raw_data: JSON.parse(strinifyraw_data),
                raw_data_hex: respData.txJson.raw_data_hex,
                signature: [signature]
            }
            let tx = {
                destinationAddress: respData.txOutputs.destinationAddress,
                data: (finalTx),
                value: respData?.txOutputs.destinationAmount.toString(),
                amount: respData?.txOutputs.destinationAmount.toString(),
                coinSymbol: respData?.txOutputs.coinSymbol.toString()
            }
            const signTransactionResponse: SignedTransaction = {
                txHex: JSON.stringify(signature),
                halfSigned: {
                    payload: JSON.stringify(tx),
                    txBase64: "",
                    txHex: respData.txJson.raw_data_hex,
                    sequenceId: sequenceId
                },
                comment: output.comment
            };
            var sendresult = await this.httpService.SendTransaction(this.wallet.id, signTransactionResponse);
            sendresult = (sendresult)
            console.log(sendresult);
            if (sendresult.status !== 200) {
                return [{ "error": sendresult }]
            }
            return "success";
        }
    }
    catch (err) {
       return errorHandler.call(this, err);
    }finally{
        if(transport)
            await transport.close();
    }
}