import { Directive, ElementRef, OnDestroy, OnInit, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[noDisplay]',
  providers: [NgbTooltip]
})
export class NoDisplayDirective implements OnInit, OnDestroy {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { 
  }

  ngOnInit() {
    if (environment.disableTennetBuildFts) {
      this.setDisabledAttribute(true);
    }
  }

  ngOnDestroy() {
  }

  private setDisabledAttribute(disable: boolean) {
    
    if (disable) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none', RendererStyleFlags2.Important);
    }
  }
}
