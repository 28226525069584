import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
const buffer = require("../node_modules/buffer/index").Buffer;

Buffer.prototype.readBigUInt64LE = buffer.prototype.readBigUInt64LE;
Buffer.prototype.writeBigUInt64LE = buffer.prototype.writeBigUInt64LE;

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
