import Transportwebusb from "@ledgerhq/hw-transport-webusb";
import { environment } from "src/environments/environment";

const delay = ms => new Promise(res => setTimeout(res, ms));

export class LedgerService {
  constructor() { }
  static async getCurrentApp(transports) {
    try {
      let r = await transports.send(0xb0, 0x01, 0x00, 0x00);
      let i = 0;
      const format = r[i++];

      if (format !== 1) {
        return {
          name: "unknown",
          version: "version",
          flags: "flags",
        };
      }

      const nameLength = r[i++];
      const name = r.slice(i, (i += nameLength)).toString("ascii");
      const versionLength = r[i++];
      const version = r.slice(i, (i += versionLength)).toString("ascii");
      const flagLength = r[i++];
      const flags = r.slice(i, (i += flagLength));
      return {
        name,
        version,
        flags,
      };
    } catch (error) {
      return {
        name: "unknown",
        version: "version",
        flags: "flags",
      };
    }
  }

  static async getNewTransport() {

    while (true) {
      try {
        return await Transportwebusb.create();
      } catch (error) {
        await delay(2000);
      }

    }
  }

  static async openApp(CoinName, transports) {
    let currentapp = await LedgerService.getCurrentApp(transports);
    if (CoinName == "Bitcoin") {
      if (environment.env == "dev") {
        CoinName = "Bitcoin Test"
      }
      if (currentapp.name == CoinName + " Legacy") {
        return
      }
      if (currentapp.name == CoinName) {
        return;
      } else if (currentapp.name != "BOLOS") {
        //Exit app
        await transports.send(0xb0, 0xa7, 0x00, 0x00);
        await delay(5000);
      }
      transports = await LedgerService.getNewTransport();
      try {
        await transports.send(
          0xe0,
          0xd8,
          0x00,
          0x00,
          Buffer.from(CoinName + " Legacy", "ascii")
        );
      } catch (err) {
        await transports.send(
          0xe0,
          0xd8,
          0x00,
          0x00,
          Buffer.from(CoinName, "ascii")
        );
      }
      await delay(2000);
    } else {
      if (currentapp.name == CoinName) {
        return;
      } else if (currentapp.name != "BOLOS") {
        //Exit app
        await transports.send(0xb0, 0xa7, 0x00, 0x00);
        await delay(5000);
      }
      transports = await LedgerService.getNewTransport();
      await transports.send(
        0xe0,
        0xd8,
        0x00,
        0x00,
        Buffer.from(CoinName, "ascii")
      );
      await delay(2000);
    }
  }
}
