<div class="card">

  <div class="align-self-center media-body">

    <div class="media border-after-xs">
      <img class="align-self-center mr-3 img-fluid img-30" src="{{user.photoURL}}">

      <li ngbDropdownItem>
        <div class="col">
          <p>{{ user.displayName }}</p>
        </div>
        <div class="col">
          <p>{{ user.id}}</p>
        </div>
        <div class="col">
          <p>{{ user.email}}</p>
        </div>
      </li>

    </div>
  </div>
</div>