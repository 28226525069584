import { getNewWebUSBTransport as getNewTransport } from "./getTransports";
import TransportWebUSB from '@ledgerhq/hw-transport-webusb';
import { LedgerSigner } from '@cosmjs/ledger-amino';
import { makeCosmoshubPath } from '@cosmjs/amino';
import { toBase64 } from '@cosmjs/encoding';
import { ledgerLinkErrorHandler } from "./linkError";
import { PubKeys } from '../../entities/Pubkey';
import { getCurrentApp } from "./getLedgerApp";
import { environment } from "src/environments/environment";

export async function linkCosmosLedger() {
  this.ng_cosmos = false;

  this.linking_Ledger_Error = true;
  this.linking_Ledger_Message = "Device link in progress..Please wait";
  let transport = await getNewTransport();
  try {
    // const xrp = new Xrp(transport);
    // let result = await xrp.getAddress(environment.xrp_config.XRP.path || "44'/144'/0'/0/0", false);
    // Prepare ledger
    const ledgerTransport = await TransportWebUSB.create(120_000, 120_000);
    // Setup signer
    const offlineSigner = new LedgerSigner(ledgerTransport, {
      hdPaths: [makeCosmoshubPath(0), makeCosmoshubPath(1), makeCosmoshubPath(10)],
      testModeAllowed: true,
      //@ts-ignore
      prefix: (environment.env == 'prod') ? environment.cosmos_config['COSMOSHUB-4'].prefix : environment.cosmos_config['THETA-TESTNET-001'].prefix,
    });
    const result = (await offlineSigner.getAccounts())[0]
    console.log(`ress ${result}`)
    let pubkey = toBase64(result.pubkey);
    let address = result.address;
    // console.log('pubkey',pubkey);
    // console.log('address',address);
    // console.log('pubkey11',toBase64((await offlineSigner.getAccounts())[0].pubkey));
    if (result) {
      this.linking_cosmos = false;
      //@ts-ignore
      this.linked_coin.push("atom");
      // console.log("hekeke",this.linked_coin);
      let wallet: PubKeys = { xpub: "", path: "", id: "", type: "", name: "", config: "" };
      wallet.xpub = address;
      //@ts-ignore
      wallet.path = (environment.env == 'prod') ? environment.cosmos_config['COSMOSHUB-4'].path : environment.cosmos_config['THETA-TESTNET-001'].path || "m/" + "44'/118'/0'/0/0";
      wallet.id = address;
      //@ts-ignore
      wallet.coin = (environment.env == 'prod') ? environment.cosmos_config['COSMOSHUB-4'].nativecoin : environment.cosmos_config['THETA-TESTNET-001'].nativecoin;
      wallet.type = "address"
      wallet.provider = "ledger"
      console.log('cosmos data', wallet)

      await this.httpService.saveKey(wallet, 'ledger').toPromise()
      wallet.xpub = pubkey;
      wallet.id = pubkey;
      wallet.type = "pubkey"
      wallet.coin = "COSMOS"
      await this.httpService.saveKey(wallet, 'ledger').toPromise()
    } else {
      //@ts-ignore
      this.coin_name = (environment.env == 'prod') ? environment.cosmos_config['COSMOSHUB-4'].nativecoin : environment.cosmos_config['THETA-TESTNET-001'].nativecoin;
      this.error_message(this.coin_name);
    }

    this.segment.track("link-device-cosmos-success", this.authService.getUser)
      .then(() => console.log("Link Device Cosmos Success")).catch((err)=>{});
  
  }
  catch (err) {
    this.step1 = true;
    this.step2 = false;
    ledgerLinkErrorHandler.call(this, err, 'cosmos');
  }
  let currentapp = await getCurrentApp(transport);
  if (currentapp.name != "BOLOS") {
    //Exit app
    await transport.send(0xb0, 0xa7, 0x00, 0x00);
    await this.delay(2000);
  }
  this.ledger_linking_cosmos = false;
  this.linking_cosmos = false;
  this.linking_Ledger_Error = false;

  //this needs to be changed for the last coin to be linked

};//end