import Swal from 'sweetalert2';
import { ErrorPrompt } from '../../components/custom-prompt/custom-prompt.service';

export function ledgerLinkErrorHandler(err: Error, coin: string) {
  this.logger.error(err);
  this.segment.track(`link-device-${coin}-fail`, this.authService.getUser)
    .then(() => console.log(`Link Device ${coin} Fail`)).catch((err)=>{});
  
  if (err.name == "TransportStatusError") {
    ErrorPrompt.fire({
      title: 'Fail!',
      text: 'Error while exporting Pubkeys!!',
    })
  }
  else {
    //alert(err.message);
  }
}