<div class="d-inline" *ngIf="mmiFlag">
  <a class="btn btn-border mr-3 px-3" type="submit" (click)="registerRequest()" href="https://metamask-institutional.io/onboarding" target="_blank" *ngIf="featureMMI != null && featureMMI == false">
    <span><img style="width: 22px;" src="https://images.ctfassets.net/9sy2a0egs6zh/2XUXAYxxFFVjPlZABUoiLg/d0ff82237d3e5d9bd1097a98e0754453/MMI-icon.svg"/></span> 
    <span class="d-flex flex-column align-items-start ml-2">
      <span class="body-font">MetaMask</span>
      <span class="text-dark font-size-12 line-height-12"> Request</span>
    </span>
  </a>
  <a class="btn btn-border mr-3 px-3" type="submit" href="https://chrome.google.com/webstore/detail/metamask-institutional/ikkihjamdhfiojpdbnfllpjigpneipbc?hl=en" target="_blank" *ngIf="featureMMI && !mmi">
    <span><img style="width: 22px;" src="https://images.ctfassets.net/9sy2a0egs6zh/2XUXAYxxFFVjPlZABUoiLg/d0ff82237d3e5d9bd1097a98e0754453/MMI-icon.svg"/></span> 
    <span class="d-flex flex-column align-items-start ml-2">
      <span class="body-font">MetaMask</span>
      <span class="text-dark font-size-12 line-height-12"> Install</span>
    </span>
  </a>
  <button class="btn btn-border mr-3 px-3" type="submit" (click)="connectMMI()" *ngIf="featureMMI && mmi">
    <span><img style="width: 22px;" src="https://images.ctfassets.net/9sy2a0egs6zh/2XUXAYxxFFVjPlZABUoiLg/d0ff82237d3e5d9bd1097a98e0754453/MMI-icon.svg"/></span> 
    <span class="d-flex flex-column align-items-start ml-2">
      <span class="body-font">MetaMask</span>
      <span class="text-dark font-size-12 line-height-12"> Connect</span>
    </span>
  </button>
</div>

<button class="btn btn-border px-3" type="submit" *ngIf="!isConnected" (click)="open(content3)">
  <span><i class="icon-wallet-connect align-middle text-blue"></i></span> 
  <span class="d-flex flex-column align-items-start ml-2">
    <span class="body-font">WalletConnect</span>
    <span class="text-dark font-size-12 line-height-12"> Connect</span>
  </span>
</button>

<button class="btn btn-border px-3" type="submit" *ngIf="isConnected" (click)="WalletConnectDetailsPopup(WalletConnectDetails)">
  <span><i class="icon-wallet-connect align-middle text-blue"></i></span> 
  <span class="d-flex flex-column align-items-start ml-2">
    <span class="body-font">{{selectedWallet?.name}}</span>
    <span class="text-dark font-size-12 line-height-12"> Connected</span>
  </span>
</button>

<!-- <button class="btn btn border border-1 p-0" type="submit" *ngIf="isConnected" (click)="WalletConnectDetailsPopup(WalletConnectDetails)">
  <span class="p-2"><i class="icon-wallet-connect align-middle text-blue"></i> <svg class="ml-1" width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2828 6.28284C16.4391 6.12663 16.4391 5.87337 16.2828 5.71716L13.7373 3.17157C13.581 3.01536 13.3278 3.01536 13.1716 3.17157C13.0154 3.32778 13.0154 3.58105 13.1716 3.73726L15.4343 6L13.1716 8.26274C13.0154 8.41895 13.0154 8.67222 13.1716 8.82843C13.3278 8.98464 13.581 8.98464 13.7373 8.82843L16.2828 6.28284ZM0 6.4H1.33333V5.6H0V6.4ZM4 6.4H6.66667V5.6H4V6.4ZM9.33333 6.4H12V5.6H9.33333V6.4ZM14.6667 6.4H16V5.6H14.6667V6.4ZM16.5657 6.56569C16.8781 6.25327 16.8781 5.74673 16.5657 5.43431L11.4745 0.343146C11.1621 0.0307264 10.6556 0.0307264 10.3431 0.343146C10.0307 0.655565 10.0307 1.1621 10.3431 1.47452L14.8686 6L10.3431 10.5255C10.0307 10.8379 10.0307 11.3444 10.3431 11.6569C10.6556 11.9693 11.1621 11.9693 11.4745 11.6569L16.5657 6.56569ZM0 6.8H1.33333V5.2H0V6.8ZM4 6.8H6.66667V5.2H4V6.8ZM9.33333 6.8H12V5.2H9.33333V6.8ZM14.6667 6.8H16V5.2H14.6667V6.8Z" fill="#7CAFFF"/>
    </svg></span> <span class="p-2 pr-3" style="background: rgba(59, 153, 252, 0.08);">{{selectedWallet.name}}</span>
</button> -->

<ng-template #content3 let-modal>
  <div *ngIf="step == 0">
    <div class="modal-header border-0">
      <h2 class="modal-title">
        <i class="icon-wallet-connect text-blue mr-2" style="color: #3B99FC;"></i> WalletConnect
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-4" ngbDropdown #myDrop="ngbDropdown" *ngIf="!selectedWallet">
        <label class="text-dark">Wallet <i style="font-size: 0.75rem!important;" class="icon-info" placement="right" ngbTooltip="Only EVM wallets can be selected"></i></label>
        <input autocomplete="off" class="form-control search-custom" placeholder="Search wallet" id="dropdownManual"
          [(ngModel)]="searchWalletInput" ngbDropdownAnchor (focus)="myDrop.open()" type="text">
        <ul ngbDropdownMenu  class="whitelistscroll w-100 py-0">
          <div class="d-flex position-relative" *ngFor="let walletdata of _wallets | wallet_search: searchWalletInput"
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);">
            <div class="w-100" [ngbTooltip]="((walletdata.access  == IWalletUserType.READ || walletdata.access  == IWalletUserType.VIEWER) && 'Send is disabled for view only members')" tooltipClass="tooltip-view-wallet">
              <button class="dropdown-content w-100 p-0 m-0 border-0" [ngClass]="{'disabled': walletdata.access  == IWalletUserType.READ || walletdata.access  == IWalletUserType.VIEWER }" 
              [disabled]="walletdata.access  == IWalletUserType.READ || walletdata.access  == IWalletUserType.VIEWER" (click)="selectWallet(walletdata)">
                <app-wallet-card [option]=walletdata [AllWallets]="wallets" [dropdown_card]="'dropdown-card'"
                  [card_padding]="'p-3'"></app-wallet-card>
              </button>
            </div>
          </div>
        </ul>
      </div>
      <div *ngIf="selectedWallet">
        <label class="text-dark">Wallet <i style="font-size: 0.75rem!important;" class="icon-info" placement="right" ngbTooltip="Only EVM wallets can be selected"></i></label>
        <span class="cursor-pointer text-muted text-underline float-right" *ngIf="selectedWallet"
          (click)="clearSelection()">
          Clear
        </span>
        <app-wallet-card [option]="selectedWallet" [AllWallets]="wallets" [card_padding]="'p-3'"></app-wallet-card>
      </div>
      <div class="qr-code-container">
        <img *ngIf="!isShowingScanner" class="qr-code-image mb-2" alt="qr-code-icon"
          src="../../../../assets/images/qr-icon.svg" />
        <button [disabled]="!selectedWallet" *ngIf="!isShowingScanner" type="button" class="btn btn-primary w-60"
          (click)="showScanner()">
          Scan Qr Code
        </button>
        <video #video [hidden]="!isShowingScanner" width="100%" height="300px"></video>
      </div>
      <div class="text-center">Or</div>
      <div>
        <label class="text-dark">WalletConnect URI
          <i style="font-size: 0.75rem !important" class="icon-info" placement="right"
            ngbTooltip="Visit any dApp and select WalletConnect. Click 'Copy to Clipboard' beneath the QR code, and paste it here."></i></label>
        <textarea class="form-control" name="" [(ngModel)]="uri" rows="2" cols="50"></textarea>
      </div>

      <div class="text-center mt-2">
        You are connecting your wallet to a Web3 App using WalletConnect.
      </div>
   </div>
    <div class="modal-footer border-0">
      <button [disabled]="!(selectedWallet && uri)" (click)="link_Wallet()" class="btn btn-primary active w-100"
        type="submit">
        <span *ngIf="!isConnectingToWalletConnect" class="d-flex align-items-center"><i
            class="icon-check mr-1"></i>Connect</span>
        <div *ngIf="isConnectingToWalletConnect" class="loader-box">
          <div class="loader-15 bg-white"></div>
        </div>
      </button>
   </div>
  </div>

  <div class="modal-custom" *ngIf="step == 1">
    <div class="modal-header border-0">
      <h2 class="modal-title">
        <i class="icon-wallet-connect mr-2" style="color: #3B99FC;"></i>
        Success
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i
          class="icon-close"></i> </button>
    </div>
    <div class="modal-body d-flex align-items-center">
      <div class="row">
        <div class="col-12">
          <div class="mb-3 position-relative">
            <div class="background_success text-center"> <img class="w-50"
                src="../../../assets/images/check-outline-success.gif"> </div>
          </div>
          <div class="mb-5 text-center">
            <div class="h5">Success</div>
            <div class="text-muted">Awesome! You have successfully connected your wallet using WalletConnect 👍</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button type="submit" class="btn btn-outline-primary w-100" (click)="modal.dismiss('Cross click');"> Done
      </button>
    </div>
  </div>
</ng-template>

<ng-template #WalletConnectSend let-modal>
  <div *ngIf="walletconnectstep == 0">
    <div class="modal-header">
      <h2 class="modal-title">
        <i class="icon-wallet-connect mr-2 text-dark"></i> WalletConnect
        <span *ngIf="meta" class="ml-2" style="font-size: inherit;">- {{meta.tx_category_type}}</span>
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <i class="icon-close text-dark"></i>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="selectedWallet">
        <label class="text-dark">Wallet</label>
        <app-wallet-card [option]="selectedWallet" [AllWallets]="wallets" [card_padding]="'p-3'"></app-wallet-card>
      </div>  
      <div class="d-flex flex-column align-items-center mt-5">
        <div class="row align-items-start w-75">
          <div class="col-5 text-center">
            <img class="img-fluid img-round-border-big mb-1 bg-white p-3" [src]="selectedWallet.coin | getIcon" alt="img" >
            <span class="d-block">{{selectedWallet.coin}}</span>
          </div>
          <div class="col-2 text-center mt-4 pt-3 text-dark">
            <i class="icon-arrow-right"></i>
          </div>
          <div class="col-5 text-center">
            <img class="img-fluid img-round-border-big mb-1 bg-white p-3" src="{{ peerMeta.icons[0] }}" alt="img" />
            <span *ngIf="!meta" class="d-block">{{ peerMeta.name }}</span>
            <span *ngIf="meta" class="d-block">{{
              meta.destination_title
              }}</span>
         </div>
        </div>
      </div>
      <!-- <div class="d-flex align-items-center justify-content-center mt-5">
        <span class="font-size-20">${{decimalAmt | amountToPretty : 2}}</span>
        <span class="text-dark font-size-16 d-inline-block mx-2">|</span>
        <span class="text-dark font-size-16">{{decimalAmt | amountToPretty : 5}} {{selectedWallet.coin}}</span>
      </div> -->
      <div>
        <div class="d-flex align-items-center justify-content-center mt-4">
          <input class="form-control text-right amount-input" type="number" value="{{decimalAmt}}" placeholder="0.00" autofocus [style.width]="inputWidth"> 
          <span class="h3 m-0 pl-2 text-dark">{{selectedWallet.coin}}</span>
        </div>
        <div class="text-center">
          <label class="text-dark m-0">$0.00</label>
        </div>
      </div>
      <div class="mt-3 mb-3 text-center">
        <span *ngIf="!meta" class="d-block text-dark">{{payload?.to}}</span>
          <span *ngIf="meta" class="d-block text-dark">{{meta.destination_address}}</span>
      </div>
      <div *ngIf="(walletConnectExplainTxn)" style="height: 100px;overflow: auto;width: 90%;margin-left: auto;margin-right: auto;">
        <div *ngFor="let item of walletConnectExplainTxn" class="d-flex text-white align-items-center justify-content-center" style="text-align: center;">
          <p style="color: black;margin: 0px;font-style: italic;font-weight: 400;font-size: 13px;line-height: 1.5;">{{item}}</p>
          <!-- {{meta.tx_detail_description}} -->
        </div>
      </div>
      <ul class="circ d-flex justify-content-center mt-1" *ngIf="(walletConnectExplainTxn)">
        <li><span class="d-block " style="font-style: italic;color: #262C35 !important;">Wallet Connect</span></li>
      </ul>
      
      <!-- <div class="w-75 m-auto text-center">
        <span class="text-dark">It is a long established fact that a reader will be distracted by the readable</span>
      </div> -->
      <div *ngIf="deviceName?.toLowerCase() == 'ledger' && selectedWallet?.version != 2" class="d-flex align-items-center justify-content-center text-dark mt-5">
        <i class="icon-ledger mr-2"></i>
        <span>Open Ether app in your Ledger device and click Sign.</span>
      </div>
    </div>
    <div class="modal-footer border-0">
      <div *ngIf="browser == 'Firefox' && device == 'ledger' && selectedWallet?.version != 2; else not_fox" class="text-dark text-center w-100 row py-2">Firefox does not support signing with Ledger. Please use Google Chrome or other supported browser.</div>
      <ng-template #not_fox>
        <button [ngClass]="{'disabled': isSigning }"
        [disabled]="isSigning" class="btn btn-primary active w-100"
        type="submit" (click)="(selectedWallet?.version == 2)?signMPCTX():signTX()">
        <i *ngIf="!isSigning" class="icon-check mr-1"></i>
        <div *ngIf="isSigning" class="loader-box">
          <div class="loader-15 bg-white"></div>
        </div>
        <span *ngIf="!isSigning"> {{selectedWallet?.version == 2?'Approve':'Sign'}} </span>
      </button>
      </ng-template>
    </div>
    
  </div>
  <div class="modal-custom" *ngIf="walletconnectstep == 1">
    <div class="modal-header border-0">
      <h2 class="modal-title">
        <i class="icon-wallet-connect mr-2" style="color: #3B99FC;"></i>
        Success
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i
          class="icon-close"></i> </button>
    </div>
    <div class="modal-body d-flex align-items-center">
      <div class="row">
        <div class="col-12">
          <div class="mb-3 position-relative">
            <div class="background_success text-center"> <img class="w-50"
                src="../../../assets/images/check-outline-success.gif"> </div>
          </div>
          <div class="mb-5 text-center">
            <div class="h5">Success</div>
            <div class="text-muted">Awesome! You have successfully {{selectedWallet.version == 2 ? 'approved': 'signed'}} the wallet connect transaction!</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button type="submit" class="btn btn-outline-primary w-100" (click)="modal.dismiss('Cross click');"> Done
      </button>
    </div>
  </div>
</ng-template>


<!-- wallet connect personal --- -->
<ng-template #WalletConnectPersonal let-modal>
  <div *ngIf="walletconnectstep == 0">
    <div class="modal-header">
      <h2 class="modal-title">
        <i class="icon-wallet-connect mr-2 text-dark"></i> Send Asset
        <!-- <span *ngIf="meta" class="ml-2" style="font-size: inherit;">- {{meta.tx_category_type}}</span> -->
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <i class="icon-close text-dark"></i>
      </button>
    </div>
    <div class="modal-body" style="display: flex; flex-direction: column;padding-left: 1.5rem !important; padding-right: 1.5rem !important;">
      <div *ngIf="selectedWallet">
        <label class="text-dark">Wallet</label>
        <app-wallet-card [option]="selectedWallet" [AllWallets]="wallets" [card_padding]="'p-3'" [component_from]="'walletConnect_personal'"></app-wallet-card>
      </div> 
      
      <div class="d-flex flex-column align-items-center" [class]="typeData==1?'mt-0':'mt-3'" *ngIf="typeData">
        <div class="row align-items-start w-75">
          <div class="col-5 text-center">
            <img class="img-fluid img-round-border-big mb-1 bg-white p-3" style="height: 77px; width: 77px;" src="../../../../assets/images/universal-wallet-icon.svg" alt="img" >
            <span class="d-block">
              All Assets
            </span>
          </div>
          <div class="col-2 text-center mt-4 pt-3 text-dark">
            <i class="icon-arrow-right"></i>
          </div>
          <div class="col-5 text-center">
            <i class="icon-address text-secondary icon-round-border-big mb-1 bg-white"></i>
            <span class="d-block">{{swapAssetName}}</span>
          </div>
        </div>
      </div>
      <div class="mt-3 mb-3 text-center" *ngIf="typeData==1">
        <span class="d-block text-dark text-break px-2">{{typeData2Address}}</span>
      </div>
      
      
      <div class="d-flex flex-column align-items-center mt-2" *ngIf="!typeData">
        <div class="row align-items-center w-75 justify-content-center">
          <div class="col-5 text-center">
            <img class="img-fluid img-round-border-big mb-1 bg-white p-3" style="height: 90px; width: 90px;" src="../../../../assets/images/universal-wallet-icon.svg" alt="img" >
            <!-- <span class="d-block">{{selectedWallet.coin}}</span> -->
            <span class="d-block" style="color: #262C35;">All Assets</span>
          </div>
         
        </div>
      </div>

      <div class="mt-5 mb-3 mx-5 text-center text-break" *ngIf="typeData == 0">
        <span class="d-block" style="font-style: italic; color: #262c35 !important"
          *ngIf="!approveMessageRequest">URL:<span style="font-weight: 500">{{ peerMeta.url }}</span></span>
        <span class="d-block" style="font-style: italic; color: #262c35 !important"
          *ngIf="approveMessageRequest && url">URL:<span style="font-weight: 500">{{ url }}</span></span>
       <!-- <span  class="d-block " style="font-style: italic;color: #262C35 !important;" *ngIf="!approveMessageRequest">{{payload_data?.params[0].length<=80? payload_data?.params[0] :payload_data?.params[0].substring(0,80)+'...'}}</span>
        <span  class="d-block " style="font-style: italic;color: #262C35 !important;" *ngIf="approveMessageRequest">{{payload_data?.message.length<=80? payload_data?.message : payload_data?.message?.substring(0,80)+'...'}}</span> -->
        <span  class="d-block " style="font-style: italic;color: #262C35 !important; height:'42px';overflow: auto;" *ngIf="!approveMessageRequest">{{ hexToText(payload_data?.params[0])}}</span>
        <span  class="d-block " style="font-style: italic;color: #262C35 !important; height:'42px';overflow: auto;" *ngIf="approveMessageRequest">{{payload_data?.message}}</span>
        <ul class="circ d-flex justify-content-center mt-1" *ngIf="SourceFrom">
          <li><span class="d-block " style="font-style: italic;color: #262C35 !important;">{{SourceFrom}}</span></li>
        </ul>
        
      </div>

      <div class="mt-2 mb-1 mx-5 text-center text-break" *ngIf="typeData==1">
        
        <!-- <span  class="d-block " style="font-style: italic;color: #262C35 !important;" *ngIf="!approveMessageRequest">{{payload_data?.params[0].length<=80? payload_data?.params[0] :payload_data?.params[0].substring(0,80)+'...'}}</span>
        <span  class="d-block " style="font-style: italic;color: #262C35 !important;" *ngIf="approveMessageRequest">{{payload_data?.message.length<=80? payload_data?.message : payload_data?.message?.substring(0,80)+'...'}}</span> -->
        <div class="d-block" style="height: 65px; overflow: auto; border-radius: 3px;" >
          <div class="d-flex mb-1" *ngFor="let key of getObjectKeys(typeDataPayloadData)" >
            <span class="d-block" style="font-style: italic;color: #262C35 !important; width: 130px; text-align: left; margin-right: 20px;" *ngIf="!approveMessageRequest">{{key}}: </span>
            <span class="d-block" style="font-style: italic;color: #262C35 !important; text-align: left;" *ngIf="!approveMessageRequest">{{typeDataPayloadData[key]}}</span>
            <span class="d-block" style="font-style: italic;color: #262C35 !important; width: 130px; text-align: left; margin-right: 20px;" *ngIf="approveMessageRequest">{{key}}: </span>
            <span class="d-block" style="font-style: italic;color: #262C35 !important; text-align: left;" *ngIf="approveMessageRequest">{{typeDataPayloadData[key]}}</span>
          </div>
        </div>
        <span  class="d-block " style="font-style: italic;color: #262C35 !important;margin-top: 17px;"  *ngIf="!approveMessageRequest && peerMeta?.url">URL:<span style="font-weight: 500;">{{peerMeta.url}}</span></span>
        <span  class="d-block " style="font-style: italic;color: #262C35 !important;margin-top: 17px;" *ngIf="approveMessageRequest && url">URL:<span style="font-weight: 500;">{{url}}</span></span>
        <!-- <span  class="d-block " style="font-style: italic;color: #262C35 !important; height:'42px';overflow: auto;" *ngIf="!approveMessageRequest">{{ hexToText(payload_data?.params[0])}}</span>
        <span  class="d-block " style="font-style: italic;color: #262C35 !important; height:'42px';overflow: auto;" *ngIf="approveMessageRequest">{{ hexToText(payload_data?.message)}}</span> -->
        <ul class="circ d-flex justify-content-center mt-1" *ngIf="SourceFrom">
          <li><span class="d-block " style="font-style: italic;color: #262C35 !important;">{{SourceFrom}}</span></li>
        </ul>
        
      </div>


      <div class="mt-auto text-center d-flex" style="height: 92px; background-color: #FAF1D3; border-radius: 12px;padding: 1rem;">
        <i class="icon-info mr-2 d-block" style="color: #746021; padding-top: 3px;"></i>
        <span style="color: #746021;text-align: justify;">Signing this message can have dangerous side effects. Only sign messages from sites you fully trust with your entire account</span>
      </div>
    </div>
    <div class="modal-footer border-0">
        <button [ngClass]="{'disabled': isSigning }"
        [disabled]="isSigning" class="btn btn-primary active w-100"
        type="submit" (click)="approveMessageRequest?approveSignTx():signTX()">
        <i *ngIf="!isSigning" class="icon-check mr-1"></i>
        <div *ngIf="isSigning" class="loader-box">
          <div class="loader-15 bg-white"></div>
        </div>
        <span *ngIf="!isSigning"> Approve </span>
      </button>
    </div>
    
  </div>
  <div class="modal-custom" *ngIf="walletconnectstep == 1">
    <div class="modal-header border-0">
      <h2 class="modal-title">
        <i class="icon-wallet-connect mr-2" style="color: #3B99FC;"></i>
        Success
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i
          class="icon-close"></i> </button>
    </div>
    <div class="modal-body d-flex align-items-center">
      <div class="row">
        <div class="col-12">
          <div class="mb-3 position-relative">
            <div class="background_success text-center"> <img class="w-50"
                src="../../../assets/images/check-outline-success.gif"> </div>
          </div>
          <div class="mb-5 text-center">
            <div class="h5">Success</div>
            <div class="text-muted" *ngIf="!mpcTypeTxn">Awesome! You have successfully signed the wallet connect transaction!</div>
            <div class="text-muted" *ngIf="mpcTypeTxn">Awesome! You have successfully Approve the wallet connect transaction!</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-0">
      <button type="submit" class="btn btn-outline-primary w-100" (click)="modal.dismiss('Cross click');"> Done
      </button>
    </div>
  </div>
  
</ng-template>

<ng-template #errorModal let-modal>
  <div class="modal-custom">
      <!-- <div class="modal-header border-0"> -->
          <!-- <h2 class="modal-title"><i class="icon-shield mr-2"></i> Failed to add policy</h2> -->
          <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i class="icon-close"></i> </button> -->
      <!-- </div> -->
      <div class="modal-body d-flex align-items-center  justify-content-center" style="background-color: #fff;">
          <div class="row">
              <div class="col-12">
                  <div class="position-relative text-center mb-4">
                      <img src="../../../assets/images/error.svg"> 
                      
                  </div>
                  <div class="mb-5 text-center">
                      <div class="font-size-20" style="font-weight: 500; margin-bottom: 15px;">Failed to Approve the wallet connect transaction</div>
                      <div class="px-4" style="color: #6F757A;"><span class="">{{errorMsg?.charAt(0)?.toUpperCase() + errorMsg?.slice(1)?.toLowerCase()}}.</span></div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer border-0">
          <button type="submit" class="btn btn-outline-primary w-100"
              (click)="modal.dismiss('Cross click');">Close
          </button>
          <div class="w-100 text-center mt-3 mb-2">
              <img src="../../../../assets/images/contact-support.svg" class="mr-2"/>
              <a class='support_email my_custom_link' [href]='supportLink' supportLink target="_top">Contact Support</a>
          </div>
      </div>
  </div>
</ng-template>


<ng-template #WalletConnectDetails let-modal>
  <div>
    <div class="modal-header border-0">
      <h2 class="modal-title">
        <i class="icon-wallet-connect text-blue mr-2" style="color: #3B99FC;"></i> WalletConnect Details
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="media border-after-xs">
        <img class="align-self-center mr-2 img-fluid img-round-border" [src]="selectedWallet.coin|getIcon">
        <div class="media-body details align-self-center">
          <div class="d-flex flex-column">
            <span>{{selectedWallet?.name}}</span>
            <span class="coin_type network {{selectedWallet.coin.toLowerCase()}}">{{selectedWallet.coin|getName}}</span>
          </div>
        </div>
        <div class="media-body text-right">
          <h6 class="card-balance">${{ selectedWallet.balanceUSD | number : '1.2-2'}}</h6>
          <span class="card-balance-coin">{{ selectedWallet.balance | number : '1.5-5'}} {{ selectedWallet.coin }}</span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 d-flex align-items-center">
          <span class="dottedtext">{{selectedWallet.address}}</span>
          <i class="icon-copy" ngxClipboard [cbContent]="selectedWallet.address" placement="top" triggers="click:blur" ngbTooltip="Copied" style="font-size: 14px!important;"></i>
        </div>
        <div class="col-6 text-right">
          <button class="btn btn-sm btn-outline-danger" (click)="disconnect()">Disconnect</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #banDialog let-modal>

  <div class="modal-body">
      <div style="height: 250px;" class="mb-3 d-flex justify-content-center flex-column align-items-center w-100">
          <img class="w-50" src="../../../assets/images/error.svg">
      </div>
      <div class="mb-5 text-center">
          <h5>Transaction Blocked: Blacklisted Address</h5>
          <div class="px-4 my-4">
              Transaction cannot be processed because the address is blacklisted. Please contact the wallet owner.
              For assistance or concern, please contact our support team.
          </div>
      </div>
  </div>
  <!-- Modal footer -->
  <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.dismiss()">I understand</button>
  </div>
</ng-template>

<app-add-policy [addpolicy_component_for]="'wallet-connect'"></app-add-policy>
