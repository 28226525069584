import { Signer, Team, Wallet } from "./mpcTxRequest";

export interface MessageSigningRequest {
    success: boolean;
    data:    MessageSigningData[];
}

export interface MessageSigningData {
    id: number;
    destinationAddress: string;
    amount: string;
    walletId: number;
    userId: number;
    asset: string;
    data: string;
    comment: string;
    msgToSign: string;
    txn_req_id: null;
    status: number;
    createdAt: Date;
    addressLabel: string;
    usdAmount: number;
    walletinfor?:any;
}

export interface CreatedMessageRequest{
    data:CreatedMessageRequestData,
    success:boolean
}

export interface CreatedMessageRequestData{
    created_at: Date,
    id: number,
    identifier: string,
    message: string,
    messageData: string,
    message_data: string
    source: string,
    status: number
    updated_at: Date
    wallet_id: number
    typedData:any
    typed_data:any
    team:Team
    wallet:Wallet
}

export interface MessageRequestStatusAPI{
    success:boolean,
    data:MessageRequestStatusAPIData
}

export interface MessageRequestStatusAPIData{
    comment: string
    created_at: Date
    id: number
    identifier: string
    message: string
    messageData: string
    message_data: string
    signature: string
    signers: Signer[]
    source: string
    status: number
    team: Team
    typedData:any
    typed_data:any
    updated_at: Date
    wallet: Wallet
    wallet_id: Date
}

export enum MessageRequestStatus{
    Pending = 1,
    Initiated = 2,
    Completed = 3,
    Cancelled = 4,
}