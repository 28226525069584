import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

import { MyAccountComponent } from '../shared/components/my-account/my-account.component';
import { WalletConnectComponent } from '../shared/components/wallet-connect/wallet-connect.component';
// Header Elements Components
// Services
import { LayoutService } from './services/layout.service';
import { LedgerService } from './helpers/ledger/ledger';
import { NavService } from './services/nav.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { WalletCardComponent } from './components/wallet-card/wallet-card.component';
import { PendingTransactionsComponent } from './components/pending-transactions/pending-transactions.component';
import { AddressCardComponent } from './components/address-card/address-card.component';
import { DateRangeSelectionComponent } from './components/date-range-selection/date-range-selection.component';
import { UserInformationComponent } from './components/user-information/user-information.component';
import { RefillComponent } from './components/refill/refill.component';
import { PoliciesListComponent } from './components/policies-list/policies-list.component';
import { AddPolicyComponent } from './components/add-policy/add-policy.component';
import { TagifyModule } from 'ngx-tagify';

import { SignerUserListComponent } from './components/signer-user-list/signer-user-list.component';
import { WalletKeyCardPageComponent } from './components/wallet-key-card-page/wallet-key-card-page.component';
import { DatePipe } from '@angular/common';
import { InviteMembersComponent } from './components/invite-members/invite-members.component';
import { ReceiveAssetsComponent } from './components/receive-assets/receive-assets.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ClipboardModule } from 'ngx-clipboard';
import { SendAssetsComponent } from './components/send-assets/send-assets.component';
import { HotWalletComponent } from './components/hot-wallet/hot-wallet.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AmlComponent } from './components/aml/aml.component';
import { StakingApprovalPopupComponent } from './components/staking-approval-popup/staking-approval-popup.component'
import { StakingClamingRewardsComponent } from './components/staking-claming-rewards/staking-claming-rewards.component';
import { CopyAddressComponent } from './components/copy-address/copy-address.component';
import { TrezorWebUSBComponent } from './components/trezor-webusb/trezor-webusb.component';
import { AppDetailsComponent } from './components/app-details/app-details.component';
import { ConsolidationRuleViewComponent } from './components/consolidation-rule-view/consolidation-rule-view.component';
import { ConsolidationRuleAddComponent } from './components/consolidation-rule-add/consolidation-rule-add.component';
import { GenerateApiKeyComponent } from './components/generate-api-key/generate-api-key.component';
import { NotificationEmailComponent } from './components/notification-email/notification-email.component';
import { AddTeam } from './components/add-team/add-team.component';
import { AddViewPolicyManagerComponent } from './components/add-view-policy-manager/add-view-policy-manager.component';
import { AddMemberComponent } from './components/add-member/add-member.component';
import { EnableMobileComponent } from './components/enable-mobile/enable-mobile.component';
import { StakingV2PopupComponent } from './components/staking-v2-popup/staking-v2-popup.component';
import { ValidatorCardComponent } from './components/validator-card/validator-card.component';
import { WalletAssetCardComponent } from './components/wallet-asset-card/wallet-asset-card.component';

import { DisableClickDirective } from './directives/disable-click.directive';
import { EthValidatorsListComponent } from './components/eth-validators-list/eth-validators-list.component';
import { AddTeamV2Component } from './components/add-team-v2/add-team-v2.component';
import { AddTeamListV2Component } from './components/add-team-list-v2/add-team-list-v2.component';
import { MembersListComponent } from './components/add-team-list-v2/members-list/members-list.component';
import { SigningNotesComponent } from './components/new-group-wallet/signing-notes/signing-notes.component';
import { ModalHeaderComponent } from './components/new-group-wallet/modal-header/modal-header.component';
import { MobileTeamsComponent } from './components/new-group-wallet/mobile-teams/mobile-teams.component';
import { MemberListComponent } from './components/new-group-wallet/member-list/member-list.component';
import { LivenessKycComponent } from './components/liveness-kyc/liveness-kyc.component';
import { GasstationAutofillComponent } from './components/gasstation-autofill/gasstation-autofill.component';
import { NoDisplayDirective } from './directives/no-display.directive';
import { SupportLinkDirective } from './directives/support-link.directive';
import { PipesModule } from './components/pipes/pipes.module';
import { NewGroupWalletComponent } from './components/new-group-wallet/new-group-wallet.component';
import { NftWalletComponent } from '../components/nft-wallet/nft-wallet.component';
import { AddTeamCustodyComponent } from '../components/custody-multisig/add-team-custody/add-team-custody.component';
import { CreateWalletCustodyComponent } from '../components/custody-multisig/create-wallet-custody/create-wallet-custody.component';
import { WalletCreateSelectorComponent } from '../components/wallet-create-selector/wallet-create-selector.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { OverlayModule } from '@angular/cdk/overlay';
import { FrozenPendingBalanceComponent } from './components/frozen-pending-balance/frozen-pending-balance.component';
import {CustomPromptComponent} from '../shared/components/custom-prompt/custom-prompt.component';
import { LoadersComponent } from './components/loaders/loaders.component';
import { LogeventPipe } from './components/pipes/logevent.pipe';
import { TransactionsComponent } from '../components/transactions/transactions.component';
import { SpeedUpTransactionComponent } from './components/speed-up-transaction/speed-up-transaction.component';
import { MultisigWalletTableDataComponent } from './components/pending-transactions/multisig-wallet-table-data/multisig-wallet-table-data.component';
import { MpcWalletTableDataComponent } from './components/pending-transactions/mpc-wallet-table-data/mpc-wallet-table-data.component';
import { SpeedUpConfirmationPopupComponent } from './components/speed-up-transaction/speed-up-confirmation-popup/speed-up-confirmation-popup.component';
import { IpAddressRestrictionComponent } from './components/ip-address-restriction/ip-address-restriction.component';
import { RotateKeysComponent } from './components/generate-api-key/rotate-keys/rotate-keys.component';
import { ApiKeyStatusComponent } from './components/generate-api-key/api-key-status/api-key-status.component';
import { DisablestakingassetClickDirective } from './directives/disablestakingasset-click.directive';
import { FirewallComponent } from '../components/firewall/firewall.component';
import { ContentTitlePipe, TitleCaseAfterCommaPipe } from './components/pipes/title-case-after-comma.pipe';
import { AssetListingComponent } from '../components/asset-listing/asset-listing.component';
import { TxnContentComponent } from './components/pending-transactions/pending-txn-info/txn-content/txn-content.component';
import { ApprovalDetailsComponent } from './components/pending-transactions/pending-txn-info/approval-details/approval-details.component';
import { RiskdisclosureComponent } from '../components/dashboard/riskdisclosure/riskdisclosure.component';
import { PendingTxnInfoComponent } from './components/pending-transactions/pending-txn-info/pending-txn-info.component';
import { FirewallWarningComponent } from './components/firewall-warning/firewall-warning.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
};

@NgModule({
  declarations: [
    MyAccountComponent,
    WalletConnectComponent,
    WalletCardComponent,
    NftWalletComponent,
    PendingTransactionsComponent,
    AddressCardComponent,
    DateRangeSelectionComponent,
    UserInformationComponent,
    RefillComponent,
    PoliciesListComponent,
    AddPolicyComponent,
    SignerUserListComponent,
    WalletKeyCardPageComponent,
    InviteMembersComponent,
    ReceiveAssetsComponent,
    TrezorWebUSBComponent,
    SendAssetsComponent,
    HotWalletComponent,
    AmlComponent,
    StakingApprovalPopupComponent,
    StakingClamingRewardsComponent,
    CopyAddressComponent,
    ConsolidationRuleViewComponent,
    ConsolidationRuleAddComponent,
    GenerateApiKeyComponent,
    NotificationEmailComponent,
    AddTeam,
    AddViewPolicyManagerComponent,
    AddMemberComponent,
    EnableMobileComponent,
    StakingV2PopupComponent,
    ValidatorCardComponent,
    WalletAssetCardComponent,
    NewGroupWalletComponent,
    EthValidatorsListComponent,
    AddTeamV2Component,
    AddTeamListV2Component,
    MembersListComponent,
    SigningNotesComponent,
    ModalHeaderComponent,
    MobileTeamsComponent,
    MemberListComponent,
    DisableClickDirective,
    SupportLinkDirective,
    LivenessKycComponent,
    GasstationAutofillComponent,
    NoDisplayDirective,
    SupportLinkDirective,
    AddTeamCustodyComponent,
    CreateWalletCustodyComponent,
    FrozenPendingBalanceComponent,
    CustomPromptComponent,
    LoadersComponent,
    LogeventPipe,
    TransactionsComponent,
    SpeedUpTransactionComponent,
    MultisigWalletTableDataComponent,
    MpcWalletTableDataComponent,
    SpeedUpConfirmationPopupComponent,
    LoadersComponent,
    LogeventPipe,
    TransactionsComponent,
    IpAddressRestrictionComponent,
    DisablestakingassetClickDirective,
    WalletCreateSelectorComponent,
    RotateKeysComponent,
    ApiKeyStatusComponent,
    FirewallComponent,
    TitleCaseAfterCommaPipe,
    AssetListingComponent,
    PendingTxnInfoComponent,
    TxnContentComponent,
    ApprovalDetailsComponent,
    ContentTitlePipe,
    RiskdisclosureComponent,
    FirewallWarningComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    Ng2SearchPipeModule,
    MatAutocompleteModule,
    PerfectScrollbarModule,
    TagifyModule.forRoot(),
    ClipboardModule,
    NgMultiSelectDropDownModule.forRoot(),
    PipesModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        animation: 'progress-dark',
      }
    }),
    MatSidenavModule,
    OverlayModule
  ],
  providers: [
    NavService,
    LayoutService,
    LedgerService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    DatePipe,
    NgbActiveModal
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    Ng2SearchPipeModule,
    MatAutocompleteModule,
    PerfectScrollbarModule,
    TagifyModule,
    ClipboardModule,
    NgMultiSelectDropDownModule,
    NgxSkeletonLoaderModule,
    PipesModule,
    MyAccountComponent,
    WalletConnectComponent,
    WalletCardComponent,
    NftWalletComponent,
    PendingTransactionsComponent,
    AddressCardComponent,
    DateRangeSelectionComponent,
    UserInformationComponent,
    RefillComponent,
    PoliciesListComponent,
    AddPolicyComponent,
    SignerUserListComponent,
    WalletKeyCardPageComponent,
    InviteMembersComponent,
    ReceiveAssetsComponent,
    TrezorWebUSBComponent,
    SendAssetsComponent,
    HotWalletComponent,
    AmlComponent,
    StakingApprovalPopupComponent,
    StakingClamingRewardsComponent,
    CopyAddressComponent,
    ConsolidationRuleViewComponent,
    ConsolidationRuleAddComponent,
    GenerateApiKeyComponent,
    NotificationEmailComponent,
    AddTeam,
    AddViewPolicyManagerComponent,
    AddMemberComponent,
    EnableMobileComponent,
    StakingV2PopupComponent,
    ValidatorCardComponent,
    WalletAssetCardComponent,
    NewGroupWalletComponent,
    EthValidatorsListComponent,
    AddTeamV2Component,
    AddTeamListV2Component,
    DisableClickDirective,
    GasstationAutofillComponent,
    NoDisplayDirective,
    SupportLinkDirective,
    AddTeamCustodyComponent,
    CreateWalletCustodyComponent,
    SpeedUpTransactionComponent,
    MultisigWalletTableDataComponent,
    FrozenPendingBalanceComponent,
    CustomPromptComponent,
    LogeventPipe,
    LoadersComponent,
    TransactionsComponent,
    LivenessKycComponent,
    IpAddressRestrictionComponent,
    WalletCreateSelectorComponent,
    FirewallComponent,
    TitleCaseAfterCommaPipe,
    AssetListingComponent,
    ContentTitlePipe,
    RiskdisclosureComponent,
    FirewallWarningComponent
  ],
})
export class SharedModule { }