import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../services/layout.service';
import { NavService } from '../../../services/nav.service';
import { fadeInAnimation } from '../../../data/router-animation/router-animation';
import { DataService } from 'src/app/shared/services/data.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { AuthServiceJWT } from 'src/app/shared/services/auth.service';
import { SidebarService } from '../../sidebar/sidebar.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { CUSTODY_TYPE, orgType } from 'src/app/shared/entities/members';
import { KYC_Status } from 'src/app/shared/entities/KycStatus';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation]
})
export class ContentComponent implements OnInit, AfterViewInit {
  isLoading = true;
  isSidenavOpen: boolean;
  isBackdropVisible:boolean;
  userDetails: any;
  disableFlow: boolean = false;
  isSumsubEnabled: boolean = false;
  kycRejected: boolean = false;
  kycInReview: boolean = false;
  @ViewChild('verifyModal') verifyModalRef: ElementRef;
  showKycModal: boolean = false;

  constructor(public navServices: NavService,
    public layout: LayoutService, private data: DataService, private httpService: HttpService, private modalService: NgbModal, private authService: AuthServiceJWT, private sidenavService: SidebarService) {
  }
  ngAfterViewInit() {
  }
  public getRouterOutletState(outlet) {
    return outlet?.isActivated ? outlet?.activatedRoute : '';
  }
  ngOnInit() {
    this.httpService.getprofile().subscribe((userDetailRequests) => {
      this.userDetails = userDetailRequests;
      this.data.changeUserProfileRequest(userDetailRequests);
      this.verifyKycFlow();
      this.isLoading = false;
    });

    this.sidenavService.onSidenavOpenStatus().subscribe(isOpen => {
      this.isSidenavOpen = isOpen;
      this.isBackdropVisible = isOpen;
    });

    this.sidenavService.onClose().subscribe(() => {
      this.isSidenavOpen = false;
      this.isBackdropVisible = false;
    });
  }

  onBackdropClick(){
    this.sidenavService.close();
  }

  verifyKycFlow() {
    const org = this.userDetails.organizations[0];
    const isCustody = org?.orgType === orgType.sub && org?.custodyType === CUSTODY_TYPE.CUSTODY;
    const isKycEnabled = (org?.features || []).includes('KYC');
    this.isSumsubEnabled = (org?.features || []).includes('sumsub');
    console.info('this.isSumsubEnabled', this.isSumsubEnabled)
    const isKycDone = org.kycStatus === KYC_Status.Approved;
    this.kycRejected = org.kycStatus === KYC_Status.Rejected;
    this.kycInReview = org.kycStatus === KYC_Status.InReview;

    if (isCustody && isKycEnabled) {
      this.disableFlow = !isKycDone;
    } else {
      this.disableFlow = false;
    }
    this.data.changeKycUpdateStatus(!this.disableFlow);
  }

  async onVerifyHandler() {
    if (this.isSumsubEnabled) {
      try {
        const response = await this.httpService.verifySumsub().toPromise();
        if (response.success) {
          this.showKycModal = true
        }
        console.info('response', response);
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: "Failed to get sumsub url",
          text: e.error.message,
          showConfirmButton: false,
        })
        console.error(e);
      }
    } else {
      this.modalService.open(this.verifyModalRef, { size: 'sm' })
    }
  }

  async refreshKycStatus(){
    this.userDetails = await this.httpService.getprofile().toPromise(); 
    this.data.changeUserProfileRequest(this.userDetails);
    this.verifyKycFlow();
  }
}
