import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EventService {
  baseUrl: string = environment.apiUrl;
  constructor(
    private httpClient: HttpClient,
  ) { }
  postEvent(event: string) {
    this.httpClient.post(this.baseUrl + "/users/event", {
      event: event
    }).pipe(catchError((error: HttpErrorResponse) => {
      console.error("event post error", error);
      return of(null);
    })).subscribe(() => {
      console.info("event posted", event);
    })
  }
}