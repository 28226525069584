import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '../../services/http.service';
import {Clipboard} from '@angular/cdk/clipboard';
@Component({
  selector: 'app-aml',
  templateUrl: './aml.component.html',
  styleUrls: ['./aml.component.scss']
})
export class AmlComponent implements OnInit {
  @Input() component_for: string;
  @Input() risk_factor: string;
  @Input() address: string;
  @Input() chain: string;
  @Input() walletID: any;

  @Output() amlStatus = new EventEmitter<{ status: string }>();

  _chain: string;
  _address: string;
  _walletID: any;
  aml_data: any;
  trm_data:any;
  risk_factor_type: string;
  loading = false;
  total = 0;
  totalAmount = 0;

  txAML = [];

  constructor(private modalService: NgbModal, private httpService: HttpService, private clipboard: Clipboard) { }

  ngOnInit(): void {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.component_for != 'aml-popup') {
      if (changes.chain) this._chain = changes.chain.currentValue;
      if (changes.address) this._address = changes.address.currentValue;
      if (changes.walletID) this._walletID = changes.walletID.currentValue;
      this.risk_factor_type = '';
      this.total = 0;
      if (!(this._chain && this._address && this._walletID)) {
        this.risk_factor_type = 'invalid';
        this.amlStatus.emit({ status: '' });
        return;
      }
      this.loading = true;
      let data;
      try {
        data = await this.httpService.checkAml(this._address, this._chain, this._walletID).toPromise();
      }
      catch(err) {
        this.loading = false;
        this.risk_factor_type = 'invalid';
        return;
      }
      this.loading = false;
      if (data.data.trmsScreeningResponse.code == 400) {
        this.risk_factor_type = 'invalid';
        this.amlStatus.emit({ status: 'invalid' });
        return;
      }
      if (data.data.trmsScreeningResponse.code == 500) {
        this.loading = false;
        this.risk_factor_type = 'invalid';
        return;
      }
      this.aml_data = data.data.trmsScreeningResponse[0]?.addressRiskIndicators;
      this.total = this.aml_data?.reduce((sum,item) => sum + parseFloat(item.totalVolumeUsd), 0);
      if (this.aml_data?.filter(obj => obj.categoryRiskScoreLevelLabel == 'High').length > 0) {
        this.risk_factor_type = "risk";
      }
      else if (this.aml_data?.filter(obj => obj.categoryRiskScoreLevelLabel == 'Medium').length > 0) {
        this.risk_factor_type = "warning";
      }
      else {
        this.risk_factor_type = "pass";
      }
      this.amlStatus.emit({ status: this.risk_factor_type });
    }
  }

  open(content) {
    this.modalService.open(content, { windowClass: 'modal-custom-background', centered: true });
    if (this.component_for == 'aml-popup') {
      this.processAML(this.risk_factor);
    }
  }

  processAML(aml) {
    this.total = 0;
    this.total = aml.trmResponse.reduce((sum,item) => sum + parseFloat(item.fiatValue), 0);
    this.totalAmount = aml.trmResponse.reduce((sum,item) => sum + parseFloat(item.assetAmount), 0);
    this.trm_data = aml;
    let arr = [];
    let _arr = [];
    console.log('trm',this.trm_data);
    for(const ele of this.trm_data.trmResponse)
    {
      for(const item of ele.alerts)
      {
        arr.push(item); 
      }
      for(const item of ele.counterParties)
      {
        _arr.push(item); 
      }
    }
    this.trm_data._alearts = arr;
    this.trm_data._counterParties = _arr;
    console.log('TRM data',this.trm_data);
  }

  copy_Address(address_Value,copy)
  {
  this.clipboard.copy(address_Value);
  setTimeout(()=>{
    copy.close();
  }, 1000);
  }
}