import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  errorMsg:string = ''
  constructor(private route: ActivatedRoute,) {
    this.errorMsg = this.route.snapshot.queryParamMap.get('errorMsg')
   }

  ngOnInit(): void {
  }

}
