import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent {

  @Input() members: any;
  @Input() removeOuterBorder: boolean = false;

  @Input("commonMembers") membersToHighlight: any[] = [];

  constructor() { }

  isMemberToBeHighlighted(member: any): boolean {
    return this.membersToHighlight.find(memberToHighlight => memberToHighlight.user?.id === member.user?.id) ?? false;
  }

}
