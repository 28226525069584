import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { AuthServiceJWT } from '../../services/auth.service';
import { HttpService } from '../../services/http.service';
import { WalletserviceService } from '../../services/walletservice.service';
import { CUSTODY_TYPE, orgType } from '../../entities/members';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { environment } from 'src/environments/environment';
import { SidebarService } from './sidebar.service';
import { isCustodyOrg, isSdkEnabledForCustodyOrg } from '@helpers/org.utils';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  profile:any = [];
  active:boolean =  false;
  orgType = orgType
  custodyType = CUSTODY_TYPE
  loading = true
  devDefaultRouting = '/dev/configure'
  isCustodyOrganization: boolean = false;
  constructor(public authService: AuthServiceJWT,private data: DataService, public appService: AppService,public httpService: HttpService, public walletService: WalletserviceService,private router: Router, private sidenavService: SidebarService) {
    this.getProfile();
    // this.walletService.currentViewWallet.subscribe(view => {
    //   console.log('view',view);
    //    this.active=view;
    // })
  }
  isSidenavOpen: boolean = false;

  ngOnInit() {
    if (environment.disableTennetBuildFts) {
      this.devDefaultRouting = '/dev/gas-station';
    }
  }
  // logout() {
  //   this.authService.SignOut();
  // }

  async getProfile()
  {
    try{
      let profileData = this.data.getUserProfile.getValue();
      this.profile = profileData.organizations[0];
      this.isCustodyOrganization = isCustodyOrg(this.profile);
    if(profileData){
      this.loading = false
    }
    }catch(err){
      this.loading = false
    }
    
  }


  manipulateView(){
    this.appService.changeView(true)
    this.appService.changeTokenView(true)
  }

  isWalletActive(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.startsWith('/wallet') && !currentUrl.includes('/staking') ;
  }

  isStakingActive(): boolean {
    const currentUrl = this.router.url;
    return currentUrl.startsWith('/wallet') && currentUrl.includes('/staking') ;
  }

  onClickNavItem(){
    this.sidenavService.close();
  }

  showDevTab(){
    return !this.isCustodyOrganization || (this.isCustodyOrganization && isSdkEnabledForCustodyOrg(this.profile))
  }
}