import { getNewTransportTRON } from "./getTransports";
import Solana from "@ledgerhq/hw-app-solana";
import { environment } from '../../../../environments/environment';
import { binary_to_base58 } from 'base58-js';
import { PubKeys } from '../../entities/Pubkey';
import { ledgerLinkErrorHandler } from './linkError';

export async function linkSolanaLedger() {
  this.ng_sol = false;
  this.linking_eth = false;
  this.linking_doge = false;
  this.linking_btc = false;
  this.linking_xrp = false;
  this.linking_cosmos = false;
  this.linking_trx = false;
  this.linking_sol = true;
  this.isLinking = true;
  let transport: any = null;
  try {
    this.segment.track("link-device-sol-attempted", this.authService.getUser)
      .then(() => console.log("Link Device SOL attempted")).catch((err)=>{});
  

    this.linking_Ledger_Error = true;
    this.linking_Ledger_Message = "Device link in progress..Please wait";
    transport = await getNewTransportTRON();
    const solana = new Solana(transport);
    var solpath = environment.sol_config.SOL.path || "44'/501'/0'";
    let Address = await solana.getAddress(solpath);
    let address = binary_to_base58(Address.address);
    if (Address) {
      this.linking_sol = false;
      this.linked_coin.push("SOL");
      let wallet: PubKeys = { xpub: "", path: "", id: "", type: "", name: "", config: "" };
      wallet.xpub = address;
      wallet.path = solpath;
      wallet.id = address;
      wallet.coin = environment.sol_config.SOL.nativecoin
      wallet.type = "address"
      wallet.provider = "ledger"
      // console.log("waller========== ", wallet)
      await this.httpService.saveKey(wallet, 'ledger').toPromise();

    } else {
      this.coin_name = "SOL";
      this.error_message(this.coin_name);
    }

    try {
      this.segment.track("link-device-sol-success", this.authService.getUser)
        .then(() => console.log("Link Device SOL Success")).catch((err)=>{});
      this.linked_coin.push("SOL");

    } catch (err) {
      this.logger.error(err, err);
      console.log(err);
    }
  }
  catch (err) {
    this.step1 = true;
    this.step2 = false;
    ledgerLinkErrorHandler.call(this, err, 'sol');
  }
  this.ledger_linking_sol = false;
  if (transport) {
    await transport.send(0xb0, 0xa7, 0x00, 0x00);
    await this.delay(2000);
    await transport.close();
  }
  //this needs to be changed for the last coin to be linked

};//end