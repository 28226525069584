export enum OrgFeatureType {
    MULTISIG_DISABLE = 0,
    MULTISIG_FULL = 1,
    MULTISIG_HYBRID = 2,
}

export enum OrgFeatureName{
    MULTISIG = 'multisig',
    SDK = 'sdk',
    MMI = 'mmi',
    STAKING = 'staking'
}