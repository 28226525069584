import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { TeamsListResponse, TeamsV2Request } from '../entities';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(private apiService: ApiService) { }

  createTeam(team: TeamsV2Request): Observable<any> {
    return this.apiService.post(`/teams/v2/create`, team).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getTeamsByPage(pageNumber: number = 1, pageSize: number = 10, type?: number, searchString?: string): Observable<TeamsListResponse> {
    const url = this.generateTeamsListUrl(pageNumber, pageSize, type, searchString);
    return this.apiService.get<TeamsListResponse>(url)
      .pipe(map((response: any) => response.data));
  }

  getAllTeams(): Observable<TeamsListResponse> {
    return this.apiService.get<TeamsListResponse>(`/teams/v2/teamList?skipPagination=true`)
      .pipe(map((response: any) => response.data));
  }

  private generateTeamsListUrl(pageNumber: number, pageSize: number, type?: number, searchString?: string): string {
    let queryParams = `?page=${pageNumber}&pageSize=${pageSize}`;

    if (type !== undefined) {
      queryParams += `&type=${type}`;
    }

    if (searchString) {
      queryParams += `&searchTerm=${searchString}`;
    }

    return `/teams/v2/teamList${queryParams}`;
  }

}
