export const MEMO_SUPPORTED_CHAINS = ["BNB", "XRPDROP", "XLM", "COSMOS"];

export const errorMessages = {
  BNB: 'Input length should be less than 1024 characters',
  XRPDROP: 'Input value should be between O and 4294967295',
  XLM: 'Input should have maximum length of 28 bytes',
  COSMOS: 'Input length should be less than 512 characters',
}

export function memoValidationHandler(memo: string | number, parentChain: string): {
  isValid: boolean,
  message: string
} {
  let validationCheck = false;
  let validationMessage = '';
  const parentChainUpperCase = parentChain.toUpperCase();
  if (!MEMO_SUPPORTED_CHAINS.includes(parentChainUpperCase)) {
    return {
      isValid: false,
      message: 'Chain not supported'
    };
  }
  if (parentChainUpperCase === 'XRPDROP') {
    validationCheck = isUint32(Number(memo));
  } else if (parentChainUpperCase === 'XLM') {
    validationCheck = isWithin28Bytes(memo as string);
  } else if (parentChainUpperCase === 'BNB') {
    validationCheck = isValidBinanceMemo(memo.toString());
  } else if (parentChainUpperCase ==='COSMOS') {
    validationCheck = isValidCosmosMemo(memo.toString());
  }
  if (!validationCheck) {
    validationMessage = errorMessages[parentChainUpperCase];
  }
  return {
    isValid: validationCheck,
    message: validationMessage
  };
}

/**
 * Checks if the provided Stellar memo is valid.
 * @param memo - The Stellar memo to validate.
 * @returns A boolean indicating whether the memo is valid or not.
 */
function isWithin28Bytes(str: string) {
  const bytes = Buffer.from(str, "utf-8");
  return bytes.length <= 28;
}

/**
 * Checks if the provided XRP memo is valid.
 * @param memo - The XRP memo to validate.
 * @returns A boolean indicating whether the memo is valid or not.
 */
function isUint32(num: number) {
  if (!Number.isInteger(num)) {
    return false;
  }
  if (num < 0) {
    return false;
  }
  return num <= 0xffffffff;
}

/**
 * Checks if the provided Cosmos memo is valid.
 * @param memo - The Cosmos memo to validate.
 * @returns A boolean indicating whether the memo is valid or not.
 */
function isValidCosmosMemo(memo: string) {
  // Check if the memo is a string
  if (typeof memo !== "string") {
    return false;
  }
  if (memo.length > 512) {
    return false; // Memo too long
  }
  return true;
}

/**
 * Checks if the provided Binance memo is valid.
 * @param memo - The Binance memo to validate.
 * @returns A boolean indicating whether the memo is valid or not.
 */
function isValidBinanceMemo(memo: string) {
  // Check if the memo is a string
  if (typeof memo !== "string") {
    return false;
  }

  // Check if the length of the memo is within the allowed limit
  return memo.length <= 1024;
}
