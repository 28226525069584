import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { WalletserviceService } from './walletservice.service';
// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	walletId: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router, public walletService: WalletserviceService) {
		// let wallets = walletService.getAllWallets();

		// for (let i = 0; i < wallets.length; i++) {
		// 	if (wallets[i])
		// 		this.MENUITEMS.push({ title: wallets[i].name == "" ? 'Single Sig ' + i : wallets[i].name, icon: 'home', type: 'sub', badgeType: 'success', badgeValue: '', active: i == 0 ? true : false, walletId: wallets[i].id })
		// }
		// //this.MENUITEMS = 
		// this.setScreenWidth(window.innerWidth);
		// fromEvent(window, 'resize').pipe(
		// 	debounceTime(1000),
		// 	takeUntil(this.unsubscriber)
		// ).subscribe((evt: any) => {
		// 	this.setScreenWidth(evt.target.innerWidth);
		// 	if (evt.target.innerWidth < 991) {
		// 		this.collapseSidebar = true;
		// 		this.megaMenu = false;
		// 		this.levelMenu = false;
		// 	}
		// 	if (evt.target.innerWidth < 1199) {
		// 		this.megaMenuColapse = true;
		// 	}
		// });
		// if (window.innerWidth < 991) { // Detect Route change sidebar close
		// 	this.router.events.subscribe(event => {
		// 		this.collapseSidebar = true;
		// 		this.megaMenu = false;
		// 		this.levelMenu = false;
		// 	});
		// }
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
		// {
		// 	headTitle1: 'My Wallet', headTitle2: 'Liminal Wallet.', walletId : "1"
		// },{
		// 	headTitle1: 'My Wallet', headTitle2: 'Liminal Wallet.', walletId : "1" , type: 'sub'
		// },
		// {
		// 	title: 'Single Sig 1', icon: 'home', type: 'sub', badgeType: 'success', badgeValue: '', active: true, walletId : "3DnR4Wn5Khg4w1ddjTxFroN2YJ3SHYE5vx"
		// },{
		// 	title: 'Sngle Sig 2', icon: 'home', type: 'sub', badgeType: 'success', badgeValue: '', active: false, walletId : "ypub6Y6DWTFs2tnAdoUB7ZRxY1zAs7RCEBxcaHN1Ss24DYvuqDsnbmzFf4JTCCQYBXNLQhntowf4FewiaYW7xASi51shY1gyXgi6Xp9VPTSD1uZ"
		// },
	];


	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
