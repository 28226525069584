import { Injectable } from '@angular/core';
import { Members, New_Wallet } from '../entities/members'
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MembersService {
  members: Members[] = [];
  new_wallet_data: New_Wallet[] = [];
  type: string;

  addMember(member, phptoURL, email, user_id) {
    var a: any = { name: member, phptoURL: phptoURL, email: email, userid: user_id };
    this.members.push(a);
  }
  removeMember(member) {
    this.members = this.members.filter((memx: any) => memx.userid != member.id);
  }
  getMember_Filter() {
    this.HttpService.getAllMembers().pipe().subscribe(allmember => {
      return this.HttpService;
    });
  }
  get_Wallet() {
    return this.new_wallet_data;
  }
  getMember() {
    return this.members;
  }
  resetMember() {
    this.members.length = 0;
    this.members = [];
  }
  constructor(private HttpService: HttpService) { }
}