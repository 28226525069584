import { Pipe, PipeTransform } from '@angular/core';
import { getIcon, getName, getFeesUnit } from '../../helpers/coins';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'getName'
})
export class GetNamePipe implements PipeTransform {
  transform(id: any): any {
    return getName(id);
  }
}

@Pipe({
  name: 'getIcon'
})
export class GetIconPipe implements PipeTransform {
  constructor(protected _sanitizer: DomSanitizer) {}
  transform(id: any): any {
    return this._sanitizer.bypassSecurityTrustUrl(getIcon(id));
  }
}

@Pipe({
  name: 'getFeesUnit'
})
export class GetFeesUnitPipe implements PipeTransform {
  transform(id: any): any {
    return getFeesUnit(id);
  }
}