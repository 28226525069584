import { getNewWebUSBTransport as getNewTransport } from "./getTransports";
import Btc from '@ledgerhq/hw-app-btc';
import { PubKeys } from '../../entities/Pubkey';
import { getWalletType } from '../pathUtils';
import * as bip32path from 'bip32-path';
import { getCurrentApp } from "./getLedgerApp";
const b58 = require('bs58check');
import { environment } from "src/environments/environment";

export async function linkBTCLedger(transport, coinname){
  try {
    let CoinName = coinname;
    this.linking_doge = false;
    this.linking_eth = false;
    this.linking_xrp = false;
    this.linking_cosmos = false;
    this.linking_trx = false;
    this.linking_btc = true;
    this.isLinking = true;

    this.segment.track("link-device-btc-attempted", this.authService.getUser)
      .then(() => console.log("Link Device BTC Success")).catch((err)=>{});
  
    let coinPath = 0;
    let coin = 'btc'
    let xpubConstp2sh = "049d7cb2" // Mainnet ypub
    let xpubsegwitp2wsh = "04b24746" // Mainnet zpub
    let xpubVersion = 76067358; // Mainnet
    //@ts-ignore
    if (environment.env == "dev") {
      coinPath = 1;
      coin = 'test';
      xpubConstp2sh = "044a5262" // Testnet upub
      xpubsegwitp2wsh = "045f1cf6" // Testnet vpub
      xpubVersion = 70617039 // Testnet
      //CoinName = "Bitcoin Test"
    }
    this.linking_Ledger_Error = true;
    this.linking_Ledger_Message = "Allow Open App On Ledger";
    //transport = await this.getNewTransport();
    await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, "ascii"));
    this.linking_Ledger_Error = false;
    await this.delay(2000);
    transport = await getNewTransport();
    const btc = new Btc(transport);
    const Arrpath = [{
      path: "m/49'/" + coinPath + "'/0'"
    }, {
      path: "m/49'/" + coinPath + "'/1'"
    }, {
      path: "m/49'/" + coinPath + "'/2'"
    }, {
      path: "m/44'/" + coinPath + "'/0'"
    }, {
      path: "m/48'/" + coinPath + "'/0'/1'"
    }, {
      path: "m/48'/" + coinPath + "'/0'/2'"
    }, {
      path: "m/44'/" + coinPath + "'/0'"
    }, {
      path: "m/84'/" + coinPath + "'/0'"
    }]
    this.linking_Ledger_Error = true;
    this.linking_Ledger_Message = "Device link in progress..Please wait";
    for (let i = 0; i < Arrpath.length; i++) {
      try {
        //await this.delay(1000);
        let resXpub;
        resXpub = await btc.getWalletXpub({ path: Arrpath[i].path, xpubVersion: xpubVersion });
        if (resXpub) {
          let wallet: PubKeys = { xpub: "", path: "", id: "", type: "", name: "", config: "" };
          if (Arrpath[i].path.includes("m/84") || Arrpath[i].path.includes("m/49")) {
            let data = b58.decode(resXpub)
            data = data.slice(4)
            if (Arrpath[i].path.includes("m/49")) {
              data = Buffer.concat([Buffer.from(xpubConstp2sh, 'hex'), data])
            }
            else {
              data = Buffer.concat([Buffer.from(xpubsegwitp2wsh, 'hex'), data])
            }

            const segwitxpub = b58.encode(data);
            console.log(segwitxpub);
            wallet.xpub = segwitxpub;
          }
          else {
            wallet.xpub = resXpub;
          }
          wallet.path = Arrpath[i].path;
          wallet.config = '1of1';
          wallet.type = getWalletType(bip32path.fromString(Arrpath[i].path).toPathArray())
          wallet.id = wallet.xpub;
          wallet.coin = "BTC"
          await this.httpService.saveKey(wallet, 'ledger').toPromise()
        }
        else {
          this.coin_name = "BTC";
          this.error_message(this.coin_name)
        }

        
        this.segment.track("link-device-btc-success", this.authService.getUser)
          .then(() => console.log("Link Device BTC Success")).catch((err)=>{});
       
      } catch (error) {
        console.error(error);
        this.segment.track("link-device-btc-failed", this.authService.getUser)
          .then(() => console.log("Link Device BTC Failed")).catch((err)=>{});
       
        if (error.name == "TransportStatusError") {
          this.linking_Ledger_Error = true;
          this.linking_Ledger_Message = "Error while exporting Pubkeys!!";
          // Swal.fire({
          //   title: 'Fail!',
          //   text: 'Error while exporting Pubkeys!!',
          // })
        }
        else {
          //   alert(error.message);
        }
      }
      //this needs to be changed for the last coin to be linked

    }
    this.linking_btc = false;
    this.linked_coin.push("btc");
  } catch (error) {

  }
  let currentapp = await getCurrentApp(transport);
  if (currentapp.name != "BOLOS") {
    //Exit app
    await transport.send(0xb0, 0xa7, 0x00, 0x00);
    await this.delay(2000);
  }
  this.linking_Ledger_Error = false;
}