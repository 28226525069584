import TrezorConnect, { UI, UI_EVENT, DEVICE_EVENT, Device, RESPONSE_EVENT } from 'trezor-connect';
import Transportwebusb from "@ledgerhq/hw-transport-webusb";
import Xrp from "@ledgerhq/hw-app-xrp";
import { environment } from 'src/environments/environment';
import Eth from "@ledgerhq/hw-app-eth";
import { LedgerService } from '../ledger/ledger';

function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const signMessage = async (device: string, text: string): Promise<any> => {
    return new Promise(async function (resolve, reject) {
        if (device === "trezor") {
            // console.log('Check pub Key');
            TrezorConnect.ethereumSignMessage({
                path: "m/44'/60'/0'/0/0",
                message: text,
                hex: true
            }).then(function (signData) {
                console.log('trezor response', signData);
                if (signData.success === true) {
                    resolve({
                        signature: signData.payload.signature,
                        pubKey: signData.payload.address,
                        message: text
                    });
                    return;
                } else {
                    reject({
                        error: signData.payload.error
                    });
                    return;
                }
            });
        }
        else if (device === "ledger") {
            // console.log('ledger');
            // console.log('message ledger',text);
            // const transport = await Transportwebusb.create();
            // const xrp = new Xrp(transport);
            // const signData = await xrp.getAddress(environment.xrp_config.XRP.path || "m/44'/60'/0'/0/0", true);
            // // var signData = await this.retrieveSignerData(environment.xrp_config.XRP.path || "44'/144'/0'/0/0",text);
            // console.log('Ledger sign response',signData);
            try {
                let CoinName = "Ethereum";
                let transport = await LedgerService.getNewTransport();
                let currentapp = await LedgerService.getCurrentApp(transport);
                if (currentapp.name != CoinName) {
                    if (currentapp.name != "BOLOS") {
                        // Exit  Other app
                        await transport.send(0xb0, 0xa7, 0x00, 0x00);
                        await delay(3000);
                        transport = await LedgerService.getNewTransport();
                    }
                    // open app
                    await transport.send(0xe0, 0xd8, 0x00, 0x00, Buffer.from(CoinName, "ascii"));
                    await delay(2000);
                    transport = await LedgerService.getNewTransport();
                }
                try {
                    const eth = new Eth(transport);
                    // let mypath = "m/44'/60'/0'/0/0";
                    const sig1 = await eth.signPersonalMessage("m/44'/60'/0'/0/0", Buffer.from(text).toString("hex"));
                    let sig = sig1['r'] + sig1['s'] + (sig1['v'].toString(16));
                    var address = await eth.getAddress("m/44'/60'/0'/0/0").then(o => o.address);
                    console.info('sig1', sig1);
                    console.info(sig);
                    console.info(address);
                    if (sig1) {
                        resolve({
                            signature: sig,
                            pubKey: address,
                            message: text
                        });
                        return;
                    } else {
                        reject({
                            error: 'error'
                        });
                        return;
                    }
                }
                catch (err) {
                    if (err.name == "TransportStatusError") {
                        reject({
                            error: err.message
                        });
                        return;
                    }
                    else {
                        console.error(err);
                        this.logger.error(err);
                        reject({
                            error: err.message
                        });
                        return;
                    }
                }
            }
            catch (err) {
                if (err.message == "Ledger device: UNKNOWN_ERROR (0x6807)")
                    reject({
                        error: "Please Install Ethereum App on Ledger"
                    });
                return;
            }

            // const transport = await Transportwebusb.create();
            // const eth = new Eth(transport);
            // let mypath = "m/44'/60'/0'/0/0"
            // const sig1 = await eth.signPersonalMessage(mypath || "m/44'/60'/0'/0/0", text);
            // let sig = sig1['r'] + sig1['s'] + (sig1['v'].toString(16));
            // var address = await eth.getAddress(mypath || "m/44'/60'/0'/0/0").then(o => o.address);
            // txParam.contractTransactionHash = safeTxHash;
            // txParam.sender = address;
            // trezorResonse =
            // {
            //     "signature": sig,
            //     "address": address
            // }
            // sig = processSig("0x" + sig);
            // txParam.signature = sig;
            // if (sig1) {
            //         resolve({
            //             signature: sig,
            //             pubKey: address,
            //             message: text
            //         });
            //         return;
            //     } else {
            //         reject({
            //             error: sig1
            //         });
            //         return;
            //     }
        } else {
            reject({
                error: "invalid device"
            });
            return;
        }
    });
}